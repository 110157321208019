import React from 'react';
import './OrderProductItem.scss';
import QuestionMarkCircleIcon from '../../assets/icons/QuestionMarkCircleIcon.svg';

import { useI18NText } from '~/i18n/i18n';
import { Tooltip } from '@chakra-ui/react';
import MarkedHtml from '../markedhtml/MarkedHtml';


const OrderProductItem: React.FC<OrderProductItemProps> = (props) => {
  // i18n国际化纯文本
  const { getI18NText } = useI18NText({ prefix: 'component.' })

  const {
    orderNo,
    title,
    subtitle,
    quantity,
    date,
    price,
    cancellationType,
    cancellationPolicy
  } = props;

  return <div className="order-product-item">
    {!orderNo ? null :
      <div className="order-product-item-no">
        <div className="label">{getI18NText('订单号')}</div>
        <div className="value">{orderNo}</div>
      </div>}
    <div className="order-product-item-title">
      {title}
    </div>
    <div className="order-product-item-subtitle">
      {subtitle}
    </div>
    <div className="order-product-item-notice-list">
      <div className="order-product-item-notice-item">
        <div className="label">{getI18NText('数量')}</div>
        <div className="value">{quantity}</div>
      </div>
      <div className="order-product-item-notice-item">
        <div className="label">{getI18NText('出游日期')}</div>
        <div className="value">{date}</div>
      </div>
    </div>
    {cancellationType && (
      <div className="order-product-item-cancel-policy">
        <Tooltip 
          label={
            <>
              <MarkedHtml html={cancellationPolicy || ""} />
            </>
          }
          hasArrow 
          arrowSize={16} 
          placement='left' 
          bg="#002C5E" 
          borderRadius="4px"
          padding="12px"
        >
          <div className='order-product-item-cancel-policy-item'>
            <span className="icon">
              <img src={QuestionMarkCircleIcon}/>
            </span>
            <span className="text">{cancellationType}</span>
          </div>
        </Tooltip>
      </div>
    )}
    <div className="order-product-item-price">
      <span className="unit">
        ¥
      </span>
      <span className="value">
        {(price || 0).toLocaleString()}
      </span>
    </div>
  </div>;
};

export default OrderProductItem;
