import { chakra } from "@chakra-ui/react";

export default function IconTicket(props: { size: number }) {
  return (
    <chakra.svg
      w={props.size}
      h={props.size}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 0C19.2652 0 19.5196 0.105357 19.7071 0.292893C19.8946 0.48043 20 0.734784 20 1V6.5C19.337 6.5 18.7011 6.76339 18.2322 7.23223C17.7634 7.70107 17.5 8.33696 17.5 9C17.5 9.66304 17.7634 10.2989 18.2322 10.7678C18.7011 11.2366 19.337 11.5 20 11.5V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V11.5C0.663041 11.5 1.29893 11.2366 1.76777 10.7678C2.23661 10.2989 2.5 9.66304 2.5 9C2.5 8.33696 2.23661 7.70107 1.76777 7.23223C1.29893 6.76339 0.663041 6.5 0 6.5V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H19ZM18 2H2V4.968L2.156 5.049C2.83432 5.41905 3.40593 5.95752 3.81577 6.61257C4.22561 7.26761 4.45987 8.01715 4.496 8.789L4.5 9C4.50013 9.80824 4.28252 10.6016 3.87005 11.2967C3.45758 11.9917 2.86549 12.5629 2.156 12.95L2 13.032V16H18V13.031L17.844 12.951C17.1657 12.581 16.5941 12.0425 16.1842 11.3874C15.7744 10.7324 15.5401 9.98285 15.504 9.211L15.5 9C15.5 7.296 16.447 5.813 17.844 5.05L18 4.967V2Z"
        fill="currentColor"
      />
    </chakra.svg>
  );
}
