import { WarningIcon } from "@chakra-ui/icons"
import { Button, Modal, ModalBody, ModalContent, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { addSubscribe } from "~/utils/subscribe"


const ErrorModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [options, setOptions] = useState({
    title: '',
    desc: '',
    btnText: ''
  })

  useEffect(() => {
    const un = addSubscribe('showErrorModal', (options: any) => {
      setOptions(options)
      onOpen()
    })
    return () => {
      un()
    }
  }, [])

  return (
    <Modal
      id="sidebar-error-modal"
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody paddingTop="8" textAlign="center">
          <WarningIcon textColor="#788DA5" height="9" width="9" />
          <Text fontSize="21px" textColor="#002C5E" marginTop="2" fontWeight="700">{options.title}</Text>
          <Text fontSize="14px" textColor="#333333" marginTop="4">{options.desc}</Text>
          <Button className="warn-btn" onClick={onClose}>{options.btnText}</Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}


export default ErrorModal
