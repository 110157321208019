import React from 'react';
import './ProductThumbnail.scss';
import exampleImage from '../../assets/images/default-product.png';
import TicketIcon from '../../assets/icons/TicketIcon.svg';
import {useNavigate} from 'react-router-dom';

import { useI18NText } from '~/i18n/i18n';

const ProductThumbnail: React.FC<ProductThumbnailProps> = (props) => {
  // i18n国际化纯文本
  const { getI18NText } = useI18NText({ prefix: 'component.' })

  const navigate = useNavigate();

  const {
    title,
    category,
    price,
    style,
    thumbnail,
    id,
  } = props;

  const onClick = () => {
    navigate(`/products/${id}`);
  };

  return <div className="product-thumbnail" style={style} onClick={onClick}>
    <img className="product-thumbnail-image" src={thumbnail || exampleImage} alt=""/>
    <div className="product-thumbnail-content">
      <div className="product-thumbnail-content-top">
        <div className="product-thumbnail-category">
          <img className="product-thumbnail-category-icon" src={TicketIcon} alt=""/>
          <span className="product-thumbnail-category-text">{category}</span>
        </div>
        <div className="product-thumbnail-title">
          {title}
        </div>
      </div>
      <div className="product-thumbnail-content-bottom">
        <div className="product-thumbnail-tip-wrapper">
          <div className="product-thumbnail-tip">
            {getI18NText('立即确认')}
          </div>
        </div>
        <div className="product-thumbnail-price">
          <span className="price-unit">¥</span>
          <span className="price-value">
            {price?.toLocaleString()}
          </span>
          <span className="price-suffix">{getI18NText('起')}</span>
        </div>
      </div>
    </div>
  </div>;
};

export default ProductThumbnail;
