import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { map, sum, keyBy, pick, merge } from 'lodash';
import dayjs from 'dayjs';

import shoppingCart from '~/recoil/shoppingCart';
import { getI18NTextOnce, useI18NText } from '~/i18n/i18n';
import { TourBaseUrl } from '~/constants';
import { get, post } from '~/utils/request';
import { hidePageLoading, showPageLoading } from '~/components/pageloading/PageLoading';

import NavBack from '../../components/nav/NavBack';
import Container from '../../layouts/components/Container';
import NavStepList from '../../components/nav/NavStepList';
import orderState from '../../recoil/orderState';
import OrderTitle from '../../components/order/OrderTitle';
import OrderTravelInfoBox from '../../components/order/OrderTravelInfoBox';
import OrderContactInfoBox from '../../components/order/OrderContactInfoBox';
import OrderProductItem from '../../components/order/OrderProductItem';
import OrderPayActionBox from '../../components/order/OrderPayActionBox';

import './OrderSubmit.scss';
import '../../components/select/select.scss';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { WarningIcon } from '@chakra-ui/icons';
import { Helmet } from 'react-helmet';

const sampleProductItems: OrderProductItemProps[] = [
  {
    title: '香港迪士尼乐园门票',
    subtitle: '普通日子1日门票',
    quantity: '成人 x 1 / 儿童 ( 3-11岁) x 2',
    date: '2021年12月18日',
    price: 1304,
  },
  {
    title: '香港迪士尼乐园门票',
    subtitle: '普通日子1日门票',
    quantity: '成人 x 1 / 儿童 ( 3-11岁) x 2',
    date: '2021年12月18日',
    price: 1304,
  },
];

const getContactValueKey = (contact: Contact) => [contact.phone, contact.email].join('___<%>___');

const OrderSubmit = () => {
  // i18n国际化纯文本
  const { getI18NText } = useI18NText({ prefix: 'orders.submit.' });
  const navigate = useNavigate();

  const [_orderState, _setOrderState] = useRecoilState(orderState);
  const [_shoppingCart, setShoppingCart] = useRecoilState<ShoppingCart>(shoppingCart);
  const { steps } = _orderState;

  const activeKey = 'info';
  console.log('===========shoppingCart', _shoppingCart);

  const emptyItems: OrderProductItemProps[] = [];
  const [productItems, setProductItems] = useState(emptyItems);
  const emptyOriginItems: any[] = [];
  const [originItems, setOriginItems] = useState(emptyOriginItems);
  let orderItems: any[] = [];
  const handleTotalAmount = (items: any[]) => sum(map(items, 'total'));

  const [amount, setAmount] = useState(0);
  const [updateUserInfo, setUpdateUserInfo] = useState(true);
  const [discount, setDiscount] = useState(0);
  const [payAmount, setPayAmount] = useState(0);
  const emptyTravellers: { label: string; value: string }[] = [];
  const [travellers, setTravellers] = useState(emptyTravellers);

  const [traveller, setTraveller] = useState<Contact>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    nationality: '',
  });
  const emptyOriginTravellers: any[] = [];
  const [originTravellers, setOriginTravellers] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [contact, setContact] = useState<Contact>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });
  const onFrequentTravellerChange = (idx: number) => (value: string) => {
    const travellerMap = keyBy(originTravellers, (row: Contact) => getContactValueKey(row));
    const clonedOriginItems: any[] = [...originItems];
    const selectedTraveller = travellerMap[value] || { name: {} };
    const traveller = merge(pick(selectedTraveller, ['email', 'phone', 'nationality']), {
      ...JSON.parse(JSON.stringify(selectedTraveller)).name,
    });
    clonedOriginItems[idx] = { ...clonedOriginItems[idx], traveller, saveTraveller: false };
    console.log('setOriginItems:onFrequentTravellerChange', clonedOriginItems);
    setOriginItems(clonedOriginItems);
  };

  const onClickPay = async () => {
    if (isOpen) return;

    for (const { travelExtraInfo, extraInfo, skus } of orderItems) {
      let count = 1;
      let inputCount = 0;
      let validCount = 0;
      if (skus && skus.length > 0) {
        count = skus[0].count;
      }
      console.log('orderItems:', orderItems);

      if (!travelExtraInfo || travelExtraInfo.length == 0) {
        alert(getI18NText('信息不全'));
        return;
      }
      if (travelExtraInfo.unitExtraInfo) {
        for (let index = 0; index < travelExtraInfo.unitExtraInfo.length; index++) {
          const travelExtraInfoItem = travelExtraInfo.unitExtraInfo[index];
          for (let index1 = 0; index1 < extraInfo.unitExtraInfo.length; index1++) {
            const extraInfoItem = extraInfo.unitExtraInfo[index1];
            let filterResult = travelExtraInfoItem.items.filter(
              (filterItem: any) => filterItem.key == extraInfoItem.key,
            );
            if (!filterResult || filterResult.length == 0) {
              return alert(getI18NText('信息不全'));
            }
          }
        }
      } else if (travelExtraInfo.bookingExtraInfo) {
        for (let index = 0; index < travelExtraInfo.bookingExtraInfo.length; index++) {
          const travelExtraInfoItem = travelExtraInfo.bookingExtraInfo[index];
          if (!travelExtraInfoItem || !travelExtraInfoItem.content) {
            return alert(getI18NText('信息不全'));
          }
        }
      }

      // if (!traveller ||
      //   !traveller.firstName ||
      //   !traveller.lastName ||
      //   !traveller.email ||
      //   !traveller.phone ||
      //   !traveller.nationality) {
      //   alert(getI18NText('信息不全'));
      //   return;
      // }
    }
    if (!contact.firstName || !contact.lastName || !contact.email || !contact.phone) {
      alert(getI18NText('信息不全'));
      return;
    }

    onOpen();

    let payPms: Array<any> = [];

    orderItems.map((orderItem: any) => {
      payPms.push({
        product: orderItem.product,
        package: {
          cancellationPolicy: orderItem.package.cancellationPolicy,
          cancellationType: orderItem.package.cancellationType,
          id: orderItem.package.id,
          supplierPackageId: orderItem.package.supplierPackageId.toString(),
          title: orderItem.package.title,
        },
        date: orderItem.date,
        skus: orderItem.skus,
        total: orderItem.total,
        extraInfo: orderItem.travelExtraInfo,
      });
    });

    let pms = {
      items: payPms,
      removeShoppingCart: true,
      contact: {
        phone: contact.phone,
        email: contact.email,
        name: {
          firstName: contact.firstName,
          lastName: contact.lastName,
        },
      },
      updateUserInfo: true,
    };

    console.log('create order pms:', pms);
    const response = await post(`${TourBaseUrl}/api/createOrder`, pms);
    console.log('createOrder:', response);

    onClose();

    setShoppingCart({
      valid: _shoppingCart.valid,
    });
    //navigate(`/orders/pay?booking=${response.data.tourBookingNo}`);
    window.open(`/orders/pay?booking=${response.data.tourBookingNo}`);
  };

  const onTravellerChange = (idx: number) => (contact: Contact) => {
    console.log('setOriginItems:onTravellerChange', idx);
    // const clonedOriginItems: any[] = [...originItems];
    // const { saveTraveller, ...others } = JSON.parse(JSON.stringify(contact));
    // clonedOriginItems[idx] = { ...clonedOriginItems[idx], traveller: others, saveTraveller };
    // console.log("setOriginItems:onTravellerChange",clonedOriginItems);
    // setOriginItems(clonedOriginItems);
  };

  const onExtroInfoChange = (idx: number) => (items: any) => {
    console.log('setOriginItems:onTravellerChange', items);

    let isUnitExtraInfo = true;
    let unitExtraInfo: any;

    const clonedOriginItems: any[] = [...originItems];
    clonedOriginItems.map((item: any, index: number) => {
      if (item && item.extraInfo && item.extraInfo.unitExtraInfo) {
        isUnitExtraInfo = true;
        unitExtraInfo = item.extraInfo.unitExtraInfo;
      } else if (item && item.extraInfo && item.extraInfo.bookingExtraInfo) {
        isUnitExtraInfo = false;
      }
    });

    let extroInfo: Array<any> = [];
    items.map((item: any, index: number) => {
      let splitResult = item.key.split('/');
      if (splitResult.length > 2) {
        let sku_id = splitResult[0];
        let contactIndex = parseInt(splitResult[1]) + 1;
        let input_type = splitResult[2];
        let key =
          splitResult.length > 4
            ? splitResult[splitResult.length - 2]
            : splitResult[splitResult.length - 1];

        let itemName = '';
        let filterResult = unitExtraInfo.filter((filterItem: any) => filterItem.key == key);
        if (filterResult && filterResult.length > 0) {
          itemName = filterResult[0].name;
        }

        if (isUnitExtraInfo) {
          let filterResult = extroInfo.filter((filterItem) => filterItem.index == contactIndex);
          if (filterResult && filterResult.length > 0 && filterResult[0].items) {
            let subOptions = filterResult[0].items.filter(
              (subFilterItem: any) => subFilterItem.content == key,
            );
            if (subOptions && subOptions.length > 0) {
              subOptions[0].selected = [];
              subOptions[0].selected.push({
                key: subOptions[0].content,
                content: '',
                selected: [
                  {
                    key: splitResult[splitResult.length - 1],
                    content: item.content,
                    name: itemName,
                  },
                ],
              });
              // subOptions[0].selected.push({
              //   key: key,
              //   content: item.content,
              //   name:itemName
              // });
            } else {
              if (input_type == 'single_select') {
                filterResult[0].items.push({
                  key: key,
                  content: item.content,
                  selected: [
                    {
                      key: item.content,
                      content: item.content,
                    },
                  ],
                  name: itemName,
                });
              } else {
                filterResult[0].items.push({
                  key: key,
                  content: item.content,
                  name: itemName,
                });
              }
            }
          } else {
            extroInfo.push({
              sku_id: sku_id,
              index: contactIndex,
              items: [
                {
                  key: key,
                  content: item.content,
                  name: itemName,
                },
              ],
            });
          }
        } else {
          extroInfo.push({
            key: key,
            content: item.content,
            name: itemName,
          });
        }
      } else {
        extroInfo.push({
          key: item.key,
          content: item.content,
          name: '',
        });
      }
    });

    if (isUnitExtraInfo) {
      let unitExtraInfo = {
        unitExtraInfo: extroInfo,
      };
      clonedOriginItems[idx] = {
        ...clonedOriginItems[idx],
        travelExtraInfo: JSON.parse(JSON.stringify(unitExtraInfo)),
      };
    } else {
      let unitExtraInfo = {
        bookingExtraInfo: extroInfo,
      };
      clonedOriginItems[idx] = {
        ...clonedOriginItems[idx],
        travelExtraInfo: JSON.parse(JSON.stringify(unitExtraInfo)),
      };
    }

    //  clonedOriginItems.map((item:any,index:number)=>{
    //    if(item&&item.extraInfo&&item.extraInfo.extraInfo.unitExtraInfo){

    //    }else if(item&&item.extraInfo&&item.extraInfo.extraInfo.bookingExtraInfo){

    //    }
    //  });
    //const { saveTraveller, ...others } = JSON.parse(JSON.stringify(contact));

    console.log('onExtroInfoChange:', clonedOriginItems[idx]);
    // console.log("setOriginItems:onTravellerChange",clonedOriginItems);
    // setOriginItems(clonedOriginItems);

    // const clonedOriginItems: any[] = [...originItems];
    // const { saveTraveller, ...others } = JSON.parse(JSON.stringify(contact));
    // clonedOriginItems[idx] = { ...clonedOriginItems[idx], traveller: others, saveTraveller };
    // console.log("setOriginItems:onTravellerChange",clonedOriginItems);

    orderItems = clonedOriginItems;
  };

  const onContactChange = (contact: Contact) => {
    setContact(contact);
  };

  const loadUserContactInfo = async () => {
    const contactInfo = await get(`${TourBaseUrl}/api/userContactInfo`);
    const { contactName, traveller: _traveller, phone, email } = contactInfo.data;
    setContact({
      phone,
      email,
      firstName: contactName?.firstName,
      lastName: contactName?.lastName,
    });
    if (_traveller) {
      setTravellers(
        map(_traveller, (row: any) => ({
          label: [row.name.firstName, row.name.lastName].join(' / '),
          value: getContactValueKey(row),
        })),
      );
      setOriginTravellers(_traveller);
    }
  };

  useEffect(() => {
    let checkingCache = localStorage.getItem('order_checking_item');
    if (!_shoppingCart.checking && checkingCache) {
      setShoppingCart({
        ..._shoppingCart,
        checking: JSON.parse(checkingCache),
      });
    }
    if (!_shoppingCart.checking) {
      return;
    }

    showPageLoading();
    setOriginItems(_shoppingCart.checking);
    localStorage.setItem('order_checking_item', JSON.stringify(_shoppingCart.checking));

    setProductItems(
      map(_shoppingCart.checking, (item: any) => {
        return {
          title: item.product.title,
          subtitle: item.package?.title,
          quantity: map(item.skus, (row: any) => [row.name, row.count].join(' x ')).join(' / '),
          date: item.date,
          price: item.total,
          cancellationType: item.packageDetail?.cancellationType || item.cancellationType,
          cancellationPolicy: item.packageDetail?.cancellationPolicy || item.cancellationPolicy,
          extraInfo: item.extraInfo || null,
        };
      }),
    );
    const itemsAmount = handleTotalAmount(_shoppingCart.checking);
    setAmount(itemsAmount);
    setPayAmount(itemsAmount);

    hidePageLoading();
    return () => hidePageLoading();
  }, [_shoppingCart]);

  useEffect(() => {
    const lastUsedTraveller = originTravellers?.[0] || { name: {} };
    // 如果要填最后使用的旅客信息，就把这个赋值给item 替换下面的empty
    const traveller = merge(pick(lastUsedTraveller, ['email', 'phone', 'nationality']), {
      ...lastUsedTraveller.name,
    });
    const clonedOriginItems: any[] = [...originItems];
    const resultItems: any[] = [];
    const empty = {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      nationality: '中国',
    };
    for (const item of clonedOriginItems) {
      resultItems.push({ ...item, traveller: empty, saveTraveller: false });
    }
    console.log('setOriginItems:originTravellers, originItems.length', resultItems);
    setOriginItems(resultItems);
  }, [originTravellers, originItems.length]);

  useEffect(() => {
    // loadUserContactInfo();
  }, ['']);

  const renderItemNotices = (item: any) => {
    const day = dayjs(item.date, 'YYYY-MM-DD').format(
      `YYYY${getI18NTextOnce('component.年')}MM${getI18NTextOnce(
        'component.月',
      )}DD${getI18NTextOnce('component.日')}`,
    );
    return [
      `${getI18NText('方案类型')}: ${item.subtitle}`,
      `${getI18NText('产品数量')}: ${item.quantity}`,
      `${getI18NText('出游日期')}: ${day}`,
    ];
  };

  const renderDefaultTraveller = () => {
    const empty = {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      nationality: '中国',
    };
    return empty;
    // return originTravellers?.[0] || empty;
  };

  const onUpdateContactChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setUpdateUserInfo(ev.target.checked);
  };

  const handleToCart = () => {
    navigate('/orders/cart', { replace: true });
  };

  return (
    <>
      <Helmet>
        <title>提交订单 - 当地玩乐｜多会儿旅行</title>
      </Helmet>
      <div className="order-submit">
        <Container>
          <div className="order-submit-top">
            <NavBack onClick={handleToCart} label={`> ${getI18NText('返回购物车')}`} />
            <div className="order-submit-nav-step-list-wrapper">
              <NavStepList activeKey={activeKey} steps={steps} />
            </div>
          </div>

          <div className="order-submit-title">{getI18NText('填写信息')}</div>

          <div className="order-submit-container">
            <div className="order-submit-content">
              <OrderTitle title={getI18NText('出行信息')} />
              {productItems.length &&
                productItems.map((item, i) => (
                  <OrderTravelInfoBox
                    key={i}
                    item={originItems[i]}
                    notices={renderItemNotices(item)}
                    contact={renderDefaultTraveller()}
                    onChange={onExtroInfoChange(i)}
                    travellers={travellers}
                    onFrequentTravellerChange={onFrequentTravellerChange(i)}
                  />
                ))}

              <OrderTitle title={getI18NText('联系信息')} />
              <OrderContactInfoBox
                contact={contact}
                checked={updateUserInfo}
                onSaveChange={onUpdateContactChange}
                onChange={onContactChange}
              />

              {/* <OrderTitle title={getI18NText("优惠券")}/>
          <div className="order-submit-coupon">
            <span className="label">{getI18NText('选择')}</span>
            <Select defaultValue="使用优惠券" placeholder={getI18NText("使用优惠券")}>
              <option value="折抵10元">暂无可用优惠券</option>
            </Select>
          </div> */}
            </div>

            <div className="order-submit-sidebar">
              {productItems.map((item, i) => (
                <OrderProductItem key={i} {...item} />
              ))}
              <OrderPayActionBox
                amount={amount}
                discount={discount}
                payAmount={payAmount}
                onClickPay={onClickPay}
                btnLoading={isOpen}
              />
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default OrderSubmit;
