import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import './BasicLayout.scss';
import Header from './components/Header';
import Footer from './components/Footer';

interface BasicLayout {
  routes: RouteItem[]
}

const reg = /^\//

export const BasicLayout: React.FC<BasicLayout> = ({ routes }) => {
  const location = useLocation()
  const allwidthPath = routes.filter(route => route.needAllWidth).map(route => reg.test(route.path) ? route.path : `/${route.path}`)

  return <div className="basic-layout">
    <Header />

    <div className={`content-wrapper ${allwidthPath.indexOf(location.pathname) > -1 ? "content-wrapper-allwidth" : ""}`}>
      <Outlet />
    </div>

    <Footer />
  </div>;
};
