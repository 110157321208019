import React, {FormEvent, useEffect, useRef, useState} from 'react';
import './RangeFilter.scss';
import Filter from './Filter';
import {Box, Input} from '@chakra-ui/react';

import { useI18NText } from '~/i18n/i18n';

import { createAntiShake } from '~/utils/shake';

// 防抖
const antiShake = createAntiShake()

const RangeFilter: React.FC<RangeFilterProps> = (props) => {
  // i18n国际化纯文本
  const { getI18NText } = useI18NText({ prefix: 'component.' })

  const {title, value, onChange} = props;
  const [min, setMin] = useState('');
  const [max, setMax] = useState('');

  const verifyInvalid = (min: string, max: string) => !!min && !!max && +min > +max
  const isInvalid = verifyInvalid(min, max)

  const handleChange = (min: string, max: string) => {
    antiShake.use(() => {
      onChange?.([!!min ? Number(min) : undefined, !!max ? Number(max) : undefined])
    })
  }

  const inputMinRef = useRef<HTMLInputElement>(null);
  const inputMaxRef = useRef<HTMLInputElement>(null);

  const onMinInput = (e: FormEvent<HTMLInputElement>) => {
    const value = (e.target as HTMLInputElement).value;
    setMin(value)
    value !== min && !verifyInvalid(value, max) && handleChange(value, max)
  };

  const onMaxInput = (e: FormEvent<HTMLInputElement>) => {
    const value = (e.target as HTMLInputElement).value;
    setMax(value)
    value !== max && !verifyInvalid(min, value) && handleChange(min, value)
  };

  const onClear = () => {
    setMin('');
    setMax('');
    handleChange('', '')
  };

  useEffect(() => {
    if (value) {
      const [min = '', max = ''] = value

      setMin(min + '');
      setMax(max + '');
    }

    return () => {
      antiShake.stop()
    }
  }, []);

  return <Filter title={title} className="range-filter" onClear={onClear}>
    <div className="range-filter-content">
      <Input ref={inputMinRef} className="range-filter-input" borderColor="#4A4A4A" errorBorderColor='red.300' isInvalid={isInvalid} type="number" placeholder={getI18NText('最低金额')} value={min} onInput={onMinInput}/>
      <Box className="range-filter-sep" background={isInvalid ? 'red.300' : "#4A4A4A"}/>
      <Input ref={inputMaxRef} className="range-filter-input" borderColor="#4A4A4A" errorBorderColor='red.300' isInvalid={isInvalid} type="number" placeholder={getI18NText('最高金额')} value={max} onInput={onMaxInput}/>
    </div>
  </Filter>;
};

export default RangeFilter;
