import React, { createRef, useEffect } from 'react'
import './PageLoading.scss'
import { Modal, ModalContent, useDisclosure, ModalOverlay } from '@chakra-ui/react'
import { useI18NText } from '~/i18n/i18n'

const handle = {
    onClose: () => { },
    onOpen: () => { },
}

export const showPageLoading = () => {
    handle.onOpen()
    // setTimeout(() => {
    //     const parnet = document.querySelector('#chakra-modal-pageloading')?.parentElement
    //     if (parnet) {
    //         parnet.style.height = 'initial'
    //     }
    // }, 4)
}

export const hidePageLoading = () => {
    handle.onClose()
}

const PageLoading: React.FC<PageLoadingProps> = () => {
    // i18n国际化纯文本
    const { getI18NText } = useI18NText({ prefix: 'component.' })
    const { isOpen, onClose, onOpen } = useDisclosure()

    handle.onClose = onClose
    handle.onOpen = onOpen

    return (
        <div className='pageloading'>
            <Modal
                id='pageloading'
                isOpen={isOpen}
                onClose={onClose}
                blockScrollOnMount={true}
                trapFocus={true}
                useInert={true}
                lockFocusAcrossFrames={true}
                closeOnOverlayClick={true}
                returnFocusOnClose={true}
                size="lg"
            >
                <ModalOverlay />
                <ModalContent>
                    <div className="page-loading-title">
                        {getI18NText('页面加载中..')}
                    </div>
                </ModalContent>
            </Modal>
        </div>
    )
}

export default PageLoading
