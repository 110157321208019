import React, { useRef, useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';

/* eslint import/no-webpack-loader-syntax: off */
// @ts-ignore
import mapboxgl from '!mapbox-gl';

const mapboxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
mapboxgl.accessToken = mapboxAccessToken;

type MapProps = {
  latitude: number;
  longitude: number;
};

export default function Map({ latitude, longitude }: MapProps) {
  const mapContainerRef = useRef(null);
  const map = useRef<mapboxgl.Map | null>(null);

  const [lng, setLng] = useState(longitude);
  const [lat, setLat] = useState(latitude);
  const [zoom, setZoom] = useState(13);

  // Initialize map when component mounts
  useEffect(() => {
    if (map.current) {
      return;
    }

    map.current = new mapboxgl.Map({
      container: mapContainerRef.current || '',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom,
    });

    new mapboxgl.Marker({
      color: '#6190E8',
    })
      .setLngLat([lng, lat])
      .addTo(map.current);
  }, [map.current]);

  useEffect(() => {
    if (!map.current) {
      return; // wait for map to initialize
    }

    map.current.on('move', () => {
      if (!map.current) {
        return;
      }

      setLng(map.current.getCenter().lng);
      setLat(map.current.getCenter().lat);
      setZoom(map.current.getZoom());
    });
  });

  return (
    <div className="product-detail-location-map">
      <Box className='product-detail-location-map-box'>
        <Box className="map-container" h="400px" ref={mapContainerRef} />
      </Box>
    </div>
  );
}
