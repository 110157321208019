import React, { useState, useEffect } from 'react';
import './ProductItemBox.scss';
import ProductItem from './ProductItem';

import { useI18NText } from '~/i18n/i18n';
import { runSubscribe } from '~/utils/subscribe';


const ProductItemBox: React.FC<ProductItemBoxProps> = (props) => {
  // i18n国际化纯文本
  const { getI18NText } = useI18NText({ prefix: 'component.' })

  const {
    maxItemCount,
    items,
    onChange,
    totalAmount,
  } = props;
  const [totalPrice, setTotalPrice] = useState(0);

  const onItemChange = (i: number) => (count: number) => {
    const _items = JSON.parse(JSON.stringify(items || []));
    const _itemsCounter = JSON.parse(JSON.stringify(items || []));

    _itemsCounter[i].count = count;
    let totalCount = 0;

    _itemsCounter.map((mapItem: any) => {
      totalCount += mapItem.count;
      console.log("---------:", mapItem.count);
    });

    console.log("onItemChange:", count);
    console.log("onItemChange:", totalCount);
    if (totalCount < 0 || count < 0) {
      runSubscribe('showErrorModal', {
        title: getI18NText('数量错误'),
        desc: getI18NText('您已达到最小预定数量'),
        btnText: getI18NText('好的')
      })
      return
    }
    else if (maxItemCount && totalCount > parseInt(maxItemCount?.toString())) {
      runSubscribe('showErrorModal', {
        title: getI18NText('数量错误'),
        desc: getI18NText('您已达到最大预定数量'),
        btnText: getI18NText('好的')
      })
      return
    }

    _items[i].count = count;
    onChange?.(_items);
  };

  useEffect(() => {
    if (items) {
    }
  }, [items]);

  return <div className="product-item-box">
    <div className="product-item-box-top">
      <div className="label">{getI18NText('数量')}</div>
      <div className="label">{getI18NText('此套餐最多预定数量为')}
        <span className="max-item-count">{maxItemCount || 100}</span>
      </div>
    </div>
    <div className="product-item-box-content">
      {items?.map((item, i) => <ProductItem
        key={i} {...item}
        onChange={onItemChange(i)}
      />)}
    </div>
    <div className="product-item-box-bottom">
      <div className="summary">
        <div className="label">{getI18NText('费用总计')}</div>
        <div className="total-amount">
          <span className="unit">¥</span>
          {totalAmount?.toLocaleString()}
        </div>
      </div>
    </div>
  </div>;
};

export default ProductItemBox;
