import React, { useState, useEffect } from 'react';
import * as qs from 'qs';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { map } from 'lodash';
import dayjs from 'dayjs';
import QRCode from 'qrcode';

import { TourBaseUrl } from '~/constants';
import { get, post } from '~/utils/request';
import { getI18NTextOnce, useI18NText } from '~/i18n/i18n';

import NavBack from '../../components/nav/NavBack';
import NavStepList from '../../components/nav/NavStepList';
import orderState from '../../recoil/orderState';
import Container from '../../layouts/components/Container';
import OrderTitle from '../../components/order/OrderTitle';
import OrderPayBox from '../../components/order/OrderPayBox';
import wepay from '../../assets/images/pay/wepay.png';
import alipay from '../../assets/images/pay/alipay.png';
import creditCard from '../../assets/images/pay/creditCard.png';
import OrderContactInfoBox from '../../components/order/OrderContactInfoBox';
import OrderTravelInfoBox from '../../components/order/OrderTravelInfoBox';
import OrderProductItem from '../../components/order/OrderProductItem';
import OrderPayActionBox from '../../components/order/OrderPayActionBox';
import OrderPayNoticeBox from '../../components/order/OrderPayNoticeBox';
import WarnIcon from '../../assets/icons/WarnIcon.svg';

import './OrderPay.scss';
import { hidePageLoading, showPageLoading } from '~/components/pageloading/PageLoading';
import OrderDetailProductItem from '~/components/order/OrderDetailProductItem';
import { Helmet } from 'react-helmet';

const sampleProductItems: OrderProductItemProps[] = [
  {
    orderNo: '14525465729',
    title: '香港迪士尼乐园门票',
    subtitle: '普通日子1日门票',
    quantity: '成人 x 1 / 儿童 ( 3-11岁) x 2',
    date: '2021年12月18日',
    price: 1304,
  },
  {
    orderNo: '14525465729',
    title: '香港迪士尼乐园门票',
    subtitle: '普通日子1日门票',
    quantity: '成人 x 1 / 儿童 ( 3-11岁) x 2',
    date: '2021年12月18日',
    price: 1304,
  },
];

const OrderPay = () => {
  // i18n国际化纯文本
  const { getI18NText } = useI18NText({ prefix: 'orders.pay.' });
  const navigate = useNavigate();
  const queryParams = qs.parse(window.location.search.slice(1));

  const _bookingId = queryParams?.booking || '';
  const [bookingId, setBookingId] = useState(_bookingId);
  const [_orderState, _setOrderState] = useRecoilState(orderState);
  const { steps } = _orderState;

  const activeKey = 'pay';

  const emptyProductItems: OrderProductItemProps[] = [];
  const [productItems, setProductItems] = useState(emptyProductItems);
  const [tourItem, setTourItem] = useState<any>();

  const [amount, setAmount] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [payAmount, setPayAmount] = useState();

  const [orderDetail, setOrderDetail] = useState<any>();
  const [travellerInfos, setTravellerInfos] = useState<any[]>([]);

  const payItems: OrderPayItem[] = [
    {
      key: 'wepay',
      label: getI18NText('微信支付'),
      image: <img src={wepay} />,
    },
    // {
    //   key: 'alipay',
    //   label: getI18NText('支付宝'),
    //   image: <img src={alipay}/>
    // },
    // {
    //   key: 'creditCard',
    //   label: getI18NText('境外信用卡'),
    //   image: <img src={creditCard}/>
    // },
  ];

  const [balance, setBalance] = useState(1294);

  const [traveller, setTraveller] = useState<Contact>({
    firstName: 'DaMing',
    lastName: 'Wang',
    email: 'adawang@qq.com',
    phone: '12345678901',
    nationality: '中国',
  });

  const [contact, setContact] = useState<Contact>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });

  const [paying, setPaying] = useState(false);
  const [payQrcode, setPayQrcode] = useState('');

  const [payMethod, setPayMethod] = useState('');
  const onPayChange = (key: string) => {
    setPayMethod(key);
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const delayHelper = (arr: number[], times: number, delay: number) => {
    for (let i = 0; i < times; i++) {
      arr.push(delay);
    }
  };

  const loadPayStatus = async () => {
    const delayArr: number[] = [];
    // total: 30min
    // 2s x 10, 4s x 10, 8s x 60, 15s - end
    delayHelper(delayArr, 10, 2);
    delayHelper(delayArr, 10, 4);
    delayHelper(delayArr, 60, 8);
    delayHelper(delayArr, 84, 15);
    let idx = 0;
    let paid = false;
    do {
      const response = await get(`${TourBaseUrl}/api/payStatus`, { bookingId });
      if (response?.data.paid) {
        paid = true;
        break;
      }
      await new Promise((resolve: any) => {
        setTimeout(() => resolve(), delayArr[idx] * 1000);
      });
      idx += 1;
      if (idx >= delayArr.length) {
        break;
      }
    } while (true);
    return paid;
  };

  const onClickPay = async () => {
    if (isOpen) return;

    if (!payMethod) {
      setOpenPayWayModal(!isOpenPayWay);
      // alert('请选择支付方式');
      return;
    }

    if (payMethod === 'balance') {
      setIsModalOpen(true);
      return;
    }

    onOpen();

    let pms = {
      paymentType: 'Wechat',
      bookingId: bookingId.toString().replace('B2B_TOUR#', ''),
    };
    console.log('pay order pms:', pms);
    const response = await post(`${TourBaseUrl}/api/payOrder`, pms);
    console.log('pay order result:', response);

    setPayQrcode(await QRCode.toDataURL(response.data.payUrl));
    setPaying(true);

    onClose();

    setIsModalOpen(true);
    const paid = await loadPayStatus();
    if (paid) {
      navigate(`/orders/paid?booking=${_bookingId}`);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpenPayWay, setOpenPayWayModal] = useState(false);

  const getModalTitle = () => {
    if (timeLeft < 0) {
      return getI18NText('时间逾期');
    }
    if (paying) {
      return getI18NText('请手机扫码完成支付');
    }
    if (payMethod === 'balance') {
      if (balance < 5) {
        return getI18NText('余额不足');
      }
      return getI18NText('余额支付');
    } else {
      return getI18NText('跳转页面中');
    }
  };
  const getModalContent = () => {
    if (timeLeft < 0) {
      return (
        <div className="overdue">
          <div>{getI18NText('已超过支付时间，')}</div>
          <div>{getI18NText('请重新再预定下单')}</div>
        </div>
      );
    }
    if (paying) {
      return (
        <div>
          <img src={payQrcode} />
        </div>
      );
    }
    if (payMethod === 'balance') {
      return (
        <div className="balance-content">
          <div className="label">{getI18NText('目前余额为')}</div>
          <div className="value">
            <span className="unit">¥</span>
            {balance.toLocaleString()}
          </div>
        </div>
      );
    } else {
      return (
        <div className="redirect-content">
          <div>{getI18NText('页面正前往跳转支付页面中，')}</div>
          <div>{getI18NText('请勿进行任何操作，避免出现错误')}</div>
        </div>
      );
    }
  };
  const getModalActions = () => {
    if (timeLeft < 0) {
      return (
        <Button className="btn warning" onClick={() => setIsModalOpen(false)}>
          {getI18NText('返回页面')}
        </Button>
      );
    }
    if (payMethod === 'balance') {
      return (
        <Button className="btn warning" onClick={() => navigate('/orders/paid')}>
          {getI18NText('前往支付')}
        </Button>
      );
    }
  };

  const [timeLeft, setTimeLeft] = useState(0);

  const loadOrderDetail = async () => {
    const response = await get(`${TourBaseUrl}/api/orderDetail?bookingId=${bookingId}`);
    console.log('loadOrderDetail:', response);
    const orderDetail = response.data.bookings[0];
    setOrderDetail(orderDetail);
    const { name, phone, email } = orderDetail.tourCustomer.contact;
    setContact({
      ...name,
      phone,
      email,
    });
    setBookingId(orderDetail.bookingId);
    setTimeLeft(response.data.timeLeft || 0);
    console.log('set time left:', response.data.timeLeft);
    setAmount(orderDetail.price);
    setPayAmount(orderDetail.bookingPayment.paidAmount);
    setTravellerInfos(
      JSON.parse(
        orderDetail.tourItem.extraInfo.unitExtraInfo ||
          orderDetail.tourItem.extraInfo.bookingExtraInfo,
      ),
    );
    setTourItem({
      ...orderDetail.tourItem,
      orderNo: orderDetail.bookingId,
    });
    setProductItems(
      map(orderDetail.tourItems, (item: any) => {
        return {
          orderNo: orderDetail.bookingId,
          title: item.product.title,
          subtitle: item.package?.title,
          quantity: map(item.skus, (row: any) => [row.name, row.count].join(' x ')).join(' / '),
          date: item.date,
          price: item.total,
          product: item.product,
          traveller: {
            ...item.traveller.name,
            phone: item.traveller.phone,
            email: item.traveller.email,
            nationality: item.traveller.nationality,
          },
          cancellationType: item.cancellationType,
          cancellationPolicy: item.cancellationPolicy,
        };
      }),
    );
  };

  const toCart = () => {
    navigate('/orders/cart', { replace: true });
  };

  useEffect(() => {
    if (bookingId) {
      showPageLoading();
      loadOrderDetail().then(() => hidePageLoading());
    }
  }, ['']);

  const renderItemNotices = (item: any) => {
    const day = dayjs(item.date, 'YYYY-MM-DD').format(
      `YYYY${getI18NTextOnce('component.年')}MM${getI18NTextOnce(
        'component.月',
      )}DD${getI18NTextOnce('component.日')}`,
    );
    return [
      `${getI18NText('方案类型')}: ${item.subtitle}`,
      `${getI18NText('产品数量')}: ${item.quantity}`,
      `${getI18NText('出游日期')}: ${day}`,
    ];
  };

  return (
    <>
      <Helmet>
        <title>预定支付 - 当地玩乐｜多会儿旅行</title>
      </Helmet>
      <div className="order-pay">
        <Container>
          <div className="order-pay-top">
            <NavBack onClick={toCart} label={`> ${getI18NText('返回购物车')}`} />
            <div className="order-pay-nav-step-list-wrapper">
              <NavStepList activeKey={activeKey} steps={steps} />
            </div>
          </div>

          <div className="order-pay-title">{getI18NText('前往支付')}</div>

          <div className="order-pay-container">
            <div className="order-pay-content">
              <OrderTitle title={getI18NText('出行信息')} />
              <OrderPayBox items={payItems} balance={balance} onChange={onPayChange} />

              <OrderTitle title={getI18NText('联系信息')} />
              {/* {
            productItems.map((item, i) => (
              <OrderTravelInfoBox
                key={i}
                item={item}
                readonly
                hideCancelText
                notices={renderItemNotices(item)}
              />
            ))
          } */}
              {tourItem && (
                <OrderDetailProductItem
                  item={tourItem}
                  notices={renderItemNotices(tourItem)}
                  orderStatus={orderDetail.status}
                />
              )}
              {map(travellerInfos, (item: any) => (
                <>
                  <div style={{ marginBottom: '16px' }}></div>
                  <div className="order-travel-info-box">
                    <div className="title">{getI18NText('出行人 (主要)')}</div>
                    <div className="row row-warp">
                      {map(item.items, (traItem: any) => {
                        return (
                          <FormControl>
                            <FormLabel>{traItem.name}</FormLabel>
                            <FormLabel>
                              {traItem.selected
                                ? traItem.selected[0].selected
                                  ? traItem.selected[0].selected[0].content
                                  : traItem.selected[0].content
                                : traItem.content}
                            </FormLabel>
                          </FormControl>
                        );
                      })}
                    </div>
                  </div>
                </>
              ))}
              <OrderContactInfoBox contact={contact} readonly />
            </div>

            <div className="order-pay-sidebar">
              <OrderPayNoticeBox timeLeft={timeLeft} onChange={(t) => setTimeLeft(t)} />

              {productItems.map((item, i) => (
                <OrderProductItem key={i} {...item} />
              ))}

              <OrderPayActionBox
                amount={amount}
                discount={discount}
                payAmount={payAmount || 0}
                onClickPay={onClickPay}
                btnLoading={isOpen}
              />
            </div>
          </div>
        </Container>

        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <ModalOverlay />
          <ModalContent>
            <div className="modal-content">
              <div className="modal-title">
                <div className="icon">
                  <img src={WarnIcon} />
                </div>
                {getModalTitle()}
              </div>
              <div className="modal-content">{getModalContent()}</div>
              <div className="modal-actions">{getModalActions()}</div>
            </div>
          </ModalContent>
        </Modal>
        <Modal isOpen={isOpenPayWay} onClose={() => setOpenPayWayModal(false)}>
          <ModalOverlay />
          <ModalContent>
            <div className="modal-content">
              <div className="modal-title">
                <div className="icon">
                  <img src={WarnIcon} />
                </div>
                {getI18NText('请选择支付方式')}
              </div>
              <div className="modal-content">{getI18NText('请选择任一方式，进行订单支付')}</div>
              <div className="modal-actions">
                <Button className="btn warning" onClick={() => setOpenPayWayModal(false)}>
                  {getI18NText('返回页面')}
                </Button>
              </div>
            </div>
          </ModalContent>
        </Modal>
      </div>
    </>
  );
};

export default OrderPay;
