import React from 'react';
import './OrderPayActionBox.scss';
import {Button} from '@chakra-ui/react';
import {useLocation, useNavigate} from 'react-router-dom';

import { useI18NText } from '~/i18n/i18n';
import InlineLoading from '../InlineLoading';


const OrderPayActionBox: React.FC<OrderPayActionBox> = (props) => {
  // i18n国际化纯文本
  const { getI18NText } = useI18NText({ prefix: 'component.' })

  const {
    amount,
    discount,
    payAmount,
    paid,
    paidMethod,
    paidTime,
    paidStatus,
    onClickPay,
    btnLoading,
  } = props;

  const location = useLocation()
  const inPaid = location.pathname === '/orders/paid'

  return <div className="order-pay-action-box">
    {inPaid && <div className="order-pay-action-box-payment-detail order-pay-action-box-top">
      <div className="title">{getI18NText('支付明细')}</div>
      <div className="order-pay-action">
        <span className="label">{getI18NText('支付方式')}</span>
        <span className="value">{paidMethod}</span>
      </div>
      <div className="order-pay-action">
        <span className="label">{getI18NText('支付时间')}</span>
        <span className="value">{paidTime}</span>
      </div>
      <div className="order-pay-action">
        <span className="label">{getI18NText('支付状态')}</span>
        <span className="value">{paidStatus}</span>
      </div>
    </div>}
    <div className="order-pay-action-box-top">
      <div className="order-pay-action">
        <span className="label">{getI18NText('结算金额')}</span>
        <span className="value">
          <span className="unit">¥</span>
          {(amount || 0).toLocaleString()}
        </span>
      </div>
      <div className="order-pay-action-discount">
        <span className="label">{getI18NText('优惠金额')}</span>
        <span className="value">
          {
            discount > 0 ? (
              <>
                <span className="unit">¥</span>
                {(discount || 0).toLocaleString()}
              </>
            ) : (
              <>
                <span className="un">-</span>
              </>
            )
          }
          
        </span>
      </div>
    </div>
    <div className="order-pay-action-box-bottom">
      <div className="order-pay-action-pay-amount">
        <span className="label">{getI18NText('支付金额')}</span>
        <span className="value">
          <span className="unit">¥</span>
          {(payAmount || 0).toLocaleString()}
        </span>
      </div>
      {paid ? null :
        <Button className="btn-pay btn warning" onClick={onClickPay}><InlineLoading open={!!btnLoading} />{getI18NText('前往支付')}</Button>}
    </div>
  </div>;
};

export default OrderPayActionBox;
