import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { Moment } from 'moment';
import { useRecoilState } from 'recoil';
import { map, filter, isNumber } from 'lodash';
import * as qs from 'qs';
import { Spinner } from '@chakra-ui/react';

import { get, post } from '~/utils/request';
import { TourBaseUrl } from '~/constants';
import { ProductSort, getSortOption } from '~/utils/product-sort.util';
import FeatureTag from '../../components/tag/FeatureTag';
import Container from '../../layouts/components/Container';
import ProductList from '../../components/product/ProductList';
import TreeFilter from '../../components/filter/TreeFilter';
import DateFilter from '../../components/filter/DateFilter';
import RangeFilter from '../../components/filter/RangeFilter';
import filterState from '../../recoil/filterState';
import TicketBlueIcon from '../../assets/icons/TicketBlueIcon.svg';
import TicketWhiteIcon from '../../assets/icons/TicketWhiteIcon.svg';
import FlagBlueIcon from '../../assets/icons/FlagBlueIcon.svg';
import FlagWhiteIcon from '../../assets/icons/FlagWhiteIcon.svg';
import PerformerBlueIcon from '../../assets/icons/PerformerBlueIcon.svg';
import PerformerWhiteIcon from '../../assets/icons/PerformerWhiteIcon.svg';
import ForkKnifeBlueIcon from '../../assets/icons/ForkKnifeBlueIcon.svg';
import ForkKnifeWhiteIcon from '../../assets/icons/ForkKnifeWhiteIcon.svg';
import TrainBlueIcon from '../../assets/icons/TrainBlueIcon.svg';
import TrainWhiteIcon from '../../assets/icons/TrainWhiteIcon.svg';
import Cart from '../../components/cart/Cart';

import './ProductListLocation.scss';
import banner from './exampleBanner.png';

import { useI18NText } from '~/i18n/i18n';
import { hidePageLoading, showPageLoading } from '~/components/pageloading/PageLoading';
import { Helmet } from 'react-helmet';
import { formatStrWithEllipsis } from '~/utils/helper';

let lastFilters = '';

const ProductListLocation: React.FC = () => {
  // i18n国际化纯文本
  const { getI18NText } = useI18NText({ prefix: 'location.name.' });
  const { name: locationName } = useParams();

  const Section = styled.section`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
  `;

  const emptyFeatures: any[] = [];
  const [features, setFeatures] = useState(emptyFeatures);
  const [_filterState, setFilterState] = useRecoilState<FilterState>(filterState);
  const [currentPage, setCurrentPage] = useState(1);
  const [productTotal, setProductTotal] = useState(0);
  const [suggestSort, setSuggestSort] = useState(ProductSort.ScoreDesc);
  const [searchSort, setSearchSort] = useState(ProductSort.ScoreDesc);

  const emptyProducts: any[] = [];
  const [suggestProducts, setSuggestProducts] = useState(emptyProducts);
  const [suggestProductsLoadend, setSuggestProductsLoadend] = useState(false);
  const [searchProductList, setSearchProductList] = useState(emptyProducts);
  const [searchProductListLoadend, setSearchProductListLoadend] = useState(false);

  const { categoryFilterItems, languageFilterItems, dates } = _filterState;

  const loadCategories = async () => {
    const categoryList = await get(`${TourBaseUrl}/api/categories`, { city: locationName });
    setFeatures(
      map(categoryList.data, (category) => ({
        icon: <img src={TrainBlueIcon} />,
        selectedIcon: <img src={TrainWhiteIcon} />,
        label: category,
        value: category,
      })),
    );
    setFilterState({
      ..._filterState,
      categoryFilterItems: map(categoryList.data, (category) => ({
        label: category,
        value: category,
      })),
    });
  };

  const loadProducts = async (params: any) => {
    const list = await post(`${TourBaseUrl}/api/products`, params);
    const result = map(list.data.docs, (row) => ({
      title: row.title,
      id: row.id,
      price: row.displayPrice,
      category: row.categoryName,
      thumbnail: row.thumbnail?.url,
    }));
    return { list: result, total: list.data.total };
  };

  const getSuggestProducts = async () => {
    const sortOption = getSortOption(suggestSort);
    const { list } = await loadProducts({
      page: 1,
      size: 12,
      sortType: ['price'],
      price: {
        sort: sortOption.sort,
      },
      city: {
        name: [locationName],
      },
    });
    setSuggestProducts(list);
    setSuggestProductsLoadend(true);
  };

  const searchProducts = async () => {
    const params: any = {
      page: currentPage,
      size: 9,
      city: {
        name: [locationName],
      },
    };
    const sortOption = getSortOption(searchSort);
    params.sortType = [sortOption.sortBy];
    const priceOptions: any = {};
    let by = false;
    const { priceRange = [] } = _filterState;
    if (isNumber(priceRange[0])) {
      by = true;
      priceOptions.min = priceRange[0];
    }
    if (isNumber(priceRange[1])) {
      by = true;
      priceOptions.max = priceRange[1];
    }
    if (sortOption.sortBy === 'price') {
      by = true;
      priceOptions.sort = sortOption.sort;
    }
    if (by) {
      params.price = priceOptions;
    }
    by = false;
    const scoreOptions: any = {};
    if (sortOption.sortBy === 'score') {
      by = true;
      scoreOptions.sort = sortOption.sort;
    }
    if (by) {
      params.score = scoreOptions;
    }
    const { categoryFilterItems: categories } = _filterState;
    if (categories && categories.length) {
      params.categories = map(filter(categories, 'checked'), 'label');
      if (!params.categories.length) delete params.categories;
    }

    console.log(
      'dates',
      dates.map((date) => date?.toString()),
    );

    const key = JSON.stringify(params);

    if (lastFilters !== key) {
      lastFilters = key;

      const { list, total } = await loadProducts(params);
      setSearchProductList(list);
      setProductTotal(total);
      setSearchProductListLoadend(true);
    }
  };

  const navigate = useNavigate();

  const onCategoryFilterItems = (items: FilterItem[]) => {
    setFilterState({
      ..._filterState,
      categoryFilterItems: items,
    });
  };

  const onLanguageFilterItems = (items: FilterItem[]) => {
    setFilterState({
      ..._filterState,
      languageFilterItems: items,
    });
  };

  const filterListRef = useRef<HTMLDivElement>(null);

  const [selectedFeatureIndexes, setSelectedFeatureIndexes] = useState<number[]>([]);

  const onFeatureClick = (i: number) => () => {
    filterListRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    const _items = JSON.parse(JSON.stringify(categoryFilterItems));
    //make other checked state equal to false before make current checkbox equal to true
    for (let j = 0; j < _items.length; j++) {
      _items[j].checked = false;
      _items[j].children?.every((subItem: any) => (subItem.checked = false));
    }
    _items[i].children?.every((subItem: any) => (subItem.checked = true));
    _items[i].checked = true;
    setFilterState({
      ..._filterState,
      categoryFilterItems: _items,
    });
    let _selectedFeatureIndexes = [...selectedFeatureIndexes];
    if (_selectedFeatureIndexes.includes(i)) {
      const idx = _selectedFeatureIndexes.indexOf(i);
      _selectedFeatureIndexes.splice(idx, 1);
    } else {
      _selectedFeatureIndexes = []; //remove other activite state before add new activite
      _selectedFeatureIndexes.push(i);
    }
    setSelectedFeatureIndexes(_selectedFeatureIndexes);
  };

  const onPaginationChange = (page: number) => {
    setCurrentPage(page);
  };

  const isFeatureSelected = (i: number): boolean => {
    return selectedFeatureIndexes.includes(i);
  };

  const onDatesChange = (dates: [Moment | null, Moment | null]) => {
    setFilterState({
      ..._filterState,
      dates,
    });
  };

  const onPriceChange = (range: [(number | undefined)?, (number | undefined)?]) => {
    setFilterState({
      ..._filterState,
      priceRange: range,
    });
  };

  useEffect(() => {
    if (locationName) {
      showPageLoading();
      loadCategories().then(() => hidePageLoading());
    }

    return () => {
      hidePageLoading();
      lastFilters = '';
    };
  }, []);

  useEffect(() => {
    getSuggestProducts();

    console.log('suggestSort', suggestSort);
  }, [suggestSort]);

  useEffect(() => {
    searchProducts();
  }, [_filterState, currentPage, searchSort]);
  return (
    <>
      <Helmet>
        <title>城市 - {formatStrWithEllipsis(locationName || '')}｜多会儿旅行</title>
      </Helmet>
      <div className="product-list-location">
        <div className="banner-wrapper">
          <div className="search-wrapper">
            <div className="search-title">{locationName}</div>
            <div className="search-subtitle">
              {getI18NText('探索_当地玩乐，优惠套餐，旅游攻略等', {
                interpolation: { locationName },
              })}
            </div>
          </div>
          <img className="banner" src={banner} alt="banner" />
        </div>
        {/* <div className="feature-list">
        {features.map((f, i) => <FeatureTag
          key={i}
          label={f.label}
          icon={isFeatureSelected(i) ? f.selectedIcon : f.icon}
          color={isFeatureSelected(i) ? '#FFFFFF' : '#002C5E'}
          backgroundColor={isFeatureSelected(i) ? '#002C5E' : '#FFFFFF'}
          onClick={onFeatureClick(i)}
        />)}
      </div> */}
        <Container>
          <ProductList
            className="hot-products"
            title={`${locationName}${getI18NText('最近热门产品')}`}
            products={suggestProducts}
            scrollable
            noSort
            hideSearch
            loadend={suggestProductsLoadend}
          />
        </Container>

        <Container>
          <div className="searched-products-title">
            {dayjs().year()}
            {locationName}
            {getI18NText('当地玩乐')}
          </div>
          <div className="product-list-location">
            <div ref={filterListRef} className="filter-list">
              <TreeFilter
                title={getI18NText('产品分类')}
                items={categoryFilterItems}
                onChange={onCategoryFilterItems}
              />
              <DateFilter
                title={getI18NText('可预定日期')}
                dates={dates}
                onChange={onDatesChange}
              />
              <RangeFilter title={getI18NText('产品价格')} onChange={onPriceChange} />
              {/* <TreeFilter title={getI18NText("服务语言")} items={languageFilterItems} onChange={onLanguageFilterItems} /> */}
            </div>
            <ProductList
              className="searched-products"
              title={
                searchProductListLoadend ? (
                  <div className="product-list-title">
                    <span>{getI18NText('找到')}</span>
                    <span className="product-list-title-highlight">{productTotal}</span>
                    <span>{getI18NText('个产品')}</span>
                  </div>
                ) : (
                  <div className="product-list-title">
                    <span>{getI18NText('找到')}</span>
                  </div>
                )
              }
              products={searchProductList}
              pagination
              total={productTotal}
              pageSize={9}
              current={currentPage}
              onPaginationChange={onPaginationChange}
              sort={ProductSort.ScoreDesc}
              sortChange={(value: ProductSort) => setSearchSort(value)}
              hideSearch
              loadend={searchProductListLoadend}
            />
          </div>
        </Container>

        <Cart />
      </div>
    </>
  );
};

// 切换搜索关键字时，重置组件
const NameChange: React.FC = (props) => {
  const { name } = useParams();
  const [flag, setFlag] = useState(true);

  useEffect(() => {
    setFlag(true);
  }, [name]);

  useEffect(() => {
    if (flag) setFlag(false);
  }, [flag]);

  return flag ? <></> : <ProductListLocation {...props} />;
};

export default NameChange;
