import { atom } from 'recoil';

export const getOrderListFilterStateDefault = () => ({
  page: 1,
  size: 10,
  sort: "createdtime_desc",
})

export default atom<OrderListFilterState>({
  key: 'orderListFilterState',
  default: getOrderListFilterStateDefault(),
});
