import React, {useRef,useEffect} from 'react';
import './ProductBookingBox.scss';
import ClockIcon from '../../assets/icons/ClockIcon.svg';
import FlashIcon from '../../assets/icons/FlashIcon.svg';
import {Button} from '@chakra-ui/react';

import { useI18NText } from '~/i18n/i18n';


const ProductBookingBox: React.FC<ProductBookingBoxProps> = (props) => {
  // i18n国际化纯文本
  const { getI18NText } = useI18NText({ prefix: 'component.' })
  const {price, line1, line2, getTurnType} = props;

  return <div className="product-booking-box">
    <div className="product-booking-box-top">
      <div className="product-booking-box-top-left">
        {getI18NText('只要')}
      </div>
      <div className="product-booking-box-top-right">
        <span className="price-unit">¥</span>
        <span className="price-value">{price}</span>
        <span className="price-suffix">{getI18NText('起')}</span>
      </div>
    </div>
    {(line1 || line2) && <div className="product-booking-box-content">
      {line1 && <div className="notice-item">
        <img className="icon" src={ClockIcon}/>
        <span className="text">
          {line1}
        </span>
      </div>}
      {line2 && <div className="notice-item">
        <img className="icon" src={FlashIcon}/>
        <span className="text">
          {line2}
        </span>
      </div>}
    </div>}
    <div  className="product-booking-box-bottom">
      <Button className="btn location" onClick={() => getTurnType("location")}>{getI18NText('查看地点')}</Button>
      <Button className="btn select" onClick={() => getTurnType("program")}>{getI18NText('选择方案')}</Button>
    </div>
  </div>;
};

export default ProductBookingBox;
