

/**
 * @description 防抖
 * @param {Object} params
 * @param {Number} params.time 延迟时间
 * */
export const createAntiShake = ({ time = 300 } = {}) => {
    let timer: number

    const stop = () => window.clearTimeout(timer)
    const use = (callback: Function) => {
        stop()
        timer = window.setTimeout(() => callback(), time)
    }

    return { use, stop }
}
