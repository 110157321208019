import React from 'react';
import './OrderPayBox.scss';
import {Radio, RadioGroup, Stack} from '@chakra-ui/react';
import LockItem from '../../assets/icons/LockItem.svg';

import { useI18NText } from '~/i18n/i18n';


const OrderPayBox: React.FC<OrderPayBoxProps> = (props) => {
  // i18n国际化纯文本
  const { getI18NText } = useI18NText({ prefix: 'component.' })

  const {items, balance, onChange} = props;

  return <div className="order-pay-box">
    <div className="order-pay-box-notice">
      <span className="icon">
        <img src={LockItem}/>
      </span>
      <span className="title">
        {getI18NText('所有支付信息已获得安全加密保护')}
      </span>
    </div>
    <RadioGroup onChange={onChange}>
      <Stack direction="column">
        <Radio value="balance">
          <span className="label">{getI18NText('余额支付')}</span>
          <span className="info">{getI18NText('剩余金额')}</span>
          <span className="balance">
            <span className="unit">¥</span>
            {balance.toLocaleString()}
          </span>
        </Radio>
        {items.map((d, i) => <Radio key={i} value={d.key}>
          <span className="label">{d.label}</span>
          <span className="image">{d.image}</span>
        </Radio>)}
      </Stack>
    </RadioGroup>
  </div>;
};

export default OrderPayBox;
