import React, { useState } from 'react';
import './OrderTravelInfoBox.scss';
import exampleTravelInfoBoxImage from './exampleTravelInfoBoxImage.png';
import { Checkbox, FormControl, FormLabel, Input, FormErrorMessage } from '@chakra-ui/react';
import Select, { Option } from 'rc-select';
import '../select/select.scss'
import { useForm } from "react-hook-form";
import QuestionMarkCircleIcon from '../../assets/icons/QuestionMarkCircleIcon.svg';

import { useI18NText } from '~/i18n/i18n';
import DateRangePicker from '../date/DateRangePicker';
import { ModifiersShape } from 'react-dates';
import { Moment } from 'moment';
import { CalendarIcon } from '@chakra-ui/icons';

const FormText = ({
  key,
  errors,
  ownKey,
  title,
  emptyMsg,
  readonly,
  readonlyText,
  placeholder,
  register,
  setValue,
  required,
}: any) => {
  return (
    <FormControl isRequired={required} isInvalid={errors[ownKey]}>
      <div className='label-error-wrap'>
        <FormLabel>{title}</FormLabel>
        <FormErrorMessage>
          {errors[ownKey]?.type === "required" && emptyMsg}
          {errors[ownKey]?.type === "pattern" && errors[ownKey]?.message}
        </FormErrorMessage>
      </div>
      {readonly ? <span>{readonlyText}</span> :
        <Input placeholder={placeholder}
          {...register(
            ownKey, {
            required: required,
          })}
          onInput={(e) => {
            setValue(ownKey, e.currentTarget.value, {
              shouldValidate: true,
              shouldDirty: true,
              shouldTouch: false
            });
          }}
        />}
    </FormControl>
  )
}

const FormMobile = ({
  errors,
  ownKey,
  title,
  emptyMsg,
  readonly,
  readonlyText,
  placeholder,
  register,
  setValue,
  required,
}: any) => {
  return (
    <FormControl isRequired={required} isInvalid={errors[ownKey]}>
      <div className='label-error-wrap'>
        <FormLabel>{title}</FormLabel>
        <FormErrorMessage>
          {errors[ownKey]?.type === "required" && emptyMsg}
          {errors[ownKey]?.type === "pattern" && errors[ownKey]?.message}
        </FormErrorMessage>
      </div>
      {readonly ? <span>{readonlyText}</span> :
        <Input placeholder={placeholder}
          type="tel"
          {...register(
            ownKey, {
            required: required,
          })}
          onInput={(e) => {
            const v = e.currentTarget.value.slice(0, 11).replace(/[^\d]/g, '')
            setValue(ownKey, v, {
              shouldValidate: true,
              shouldDirty: true,
              shouldTouch: false
            });
          }}
        />}
    </FormControl>
  )
}

const FormCheckBox = ({
  errors,
  ownKey,
  readonly,
  title,
  register,
  setValue,
  required,
}: any) => {
  return (
    <FormControl isRequired={required} isInvalid={errors[ownKey]}>

      {readonly ? null :
        <div className="row">
          <Checkbox
            {...register(
              ownKey, {
              required: required,
            })}
            onChange={e => {
              setValue(ownKey, e.target.checked, {
                shouldValidate: true,
                shouldDirty: true,
                shouldTouch: false
              });
            }}
          />
          <FormLabel>{title}</FormLabel>
        </div>}
    </FormControl>
  )
}

const FormList = ({
  errors,
  ownKey,
  title,
  emptyMsg,
  defaultValue,
  readonly,
  readonlyText,
  placeholder,
  register,
  setValue,
  options,
  required
}: any) => {
  return (
    <FormControl
      isRequired={required}
      isInvalid={errors[ownKey]}
      className='select-nationality'
    >
      <div className='label-error-wrap'>
        <FormLabel>{title}</FormLabel>
        <FormErrorMessage>
          {errors[ownKey]?.type === "required" && emptyMsg}
          {errors[ownKey]?.type === "pattern" && errors[ownKey]?.message}
        </FormErrorMessage>
      </div>
      {readonly ? <span>{readonlyText}</span> :
        <Select
          className={'form-select' + (errors[ownKey]?.type === "required" ? ' form-select-error' : '')}
          placeholder={placeholder}
          defaultValue={defaultValue}
          {...register(
            ownKey, {
            required: required,
          })}
          onChange={onFormListChange.bind(this, ownKey, setValue, options)}
        >
          {
            options.map((op: any) => <option value={op.key}>{op.name}</option>)
          }
        </Select>
      }
    </FormControl>
  )
}

const onFormListChange = (ownKey: any, setValue: any, options: any, value: any) => {
  // console.log("onFormListChange value:", value);
  // console.log("onFormListChange ownKey:", ownKey);
  // console.log("onFormListChange setValue:", setValue);
  // console.log("onFormListChange options:", options);
  if (options && options.length > 0) {
    let filterReuslt = options.filter((optionItem: any) => optionItem.key == value);
    if (filterReuslt && filterReuslt.length > 0 && filterReuslt[0].options && filterReuslt[0].options.length > 0) {
      //console.log("Cascade options:",filterReuslt[0].options[0]);
      // source.extraInfoCascadeOptions = [];
      // source.extraInfoCascadeOptions.push(filterReuslt[0].options[0]);
      // console.log("extraInfoCascadeOptions:", source.extraInfoCascadeOptions);
    }
  }
  setValue(ownKey, value, {
    shouldValidate: true,
    shouldDirty: true,
    shouldTouch: false
  });
}


const FormDate = ({
  errors,
  ownKey,
  title,
  emptyMsg,
  readonly,
  readonlyText,
  placeholder,
  register,
  setValue,
  getValues,
  required,
}: any) => {
  return (
    <FormControl
      isRequired={required}
      isInvalid={errors[ownKey]}
      className='select-nationality'
    >
      <div className='label-error-wrap'>
        <FormLabel>{title}</FormLabel>
        <FormErrorMessage>
          {errors[ownKey]?.type === "required" && emptyMsg}
          {errors[ownKey]?.type === "pattern" && errors[ownKey]?.message}
        </FormErrorMessage>
      </div>
      {readonly ? <span>{readonlyText}</span> :
        <DateRangePicker
          singleDate
          onChange={value => {
            setValue(ownKey, value[0], {
              shouldValidate: true,
              shouldDirty: true,
              shouldTouch: false
            });
          }}
          CustomBox={({ onClick }) => {
            return (
              <div style={{ position: 'relative' }}>
                <Select
                  className={'form-select' + (errors[ownKey]?.type === "required" ? ' form-select-error' : '')}
                  {...register(
                    ownKey, {
                    required: required,
                  })}
                />
                <div
                  style={{ position: 'absolute', zIndex: '1', left: '0', right: 0, top: 0, bottom: 0, display: 'flex', alignItems: 'center' }}
                  onClick={onClick}
                >
                  <CalendarIcon color="#8B8B8B" width="20px" height="20px" marginLeft="8px" marginRight="5px" />
                  {getValues()[ownKey] ?
                    <span style={{ color: '#333333' }}>{getValues()[ownKey].format('YYYY-MM-DD')}</span> :
                    <span style={{ color: '#8B8B8B' }}>{placeholder}</span>
                  }
                </div>
              </div>
            )
          }}
        />}
    </FormControl>
  )
}


const FormTime = ({
  errors,
  ownKey,
  title,
  emptyMsg,
  defaultValue,
  readonly,
  readonlyText,
  placeholder,
  register,
  setValue,
  required,
}: any) => {
  return (
    <FormControl
      isRequired={required}
      isInvalid={errors[ownKey]}
      className='select-nationality'
    >
      <div className='label-error-wrap'>
        <FormLabel>{title}</FormLabel>
        <FormErrorMessage>
          {errors[ownKey]?.type === "required" && emptyMsg}
          {errors[ownKey]?.type === "pattern" && errors[ownKey]?.message}
        </FormErrorMessage>
      </div>
      {readonly ? <span>{readonlyText}</span> :
        <Select
          className={'form-select' + (errors[ownKey]?.type === "required" ? ' form-select-error' : '')}
          placeholder={placeholder}
          defaultValue={defaultValue}
          {...register(
            ownKey, {
            required: required,
          })}
          onChange={value => {
            setValue(ownKey, value, {
              shouldValidate: true,
              shouldDirty: true,
              shouldTouch: false
            });
          }}
        >
          {
            new Array(24).fill(0).map((_, index: number) => <option value={index}>{`${index < 10 ? `0${index}` : index}:00`}</option>)
          }
        </Select>
      }
    </FormControl>
  )
}

const keyValuePair: any = {};
const OrderTravelInfoBox: React.FC<OrderTravelInfoBoxProps> = (props) => {
  // i18n国际化纯文本
  const { getI18NText } = useI18NText({ prefix: 'component.' })

  const {
    notices,
    travellers,
    readonly,
    orderNo,
    orderStatus,
    onChange,
    item,
    onFrequentTravellerChange: _onFrequentTravellerChange,
    hideCancelText,
  } = props;

  console.log(item)

  const {
    setValue,
    register,
    formState: { errors, isSubmitting },
    getValues,
  } = useForm();

  console.log('getValues', getValues())
  console.log("keyValuePair:", keyValuePair);
  let values: Array<any> = [];
  for (const key in getValues()) {
    const element = getValues()[key];
    values.push({
      key: key,
      content: element
    });
  }

  onChange && onChange(values);

  const getOrderStatusClassName = () => {
    if (!orderStatus) return '';
    if (orderStatus === getI18NText('待确认')) return 'tbc';
    if (orderStatus === getI18NText('确认中')) return 'confirmed';
    return '';
  };

  const setValueSelect = (params1: any, params2: any, params3: any) => {
    setValue(params1, params2, params3);
    keyValuePair[params1] = params2;
  }

  // const [frequentTraveller, setFrequentTraveller] = useState('');
  // const onFrequentTravellerChange = (ev: any) => {
  //   setFrequentTraveller(ev);
  //   _onFrequentTravellerChange?.(ev);
  // };

  // const onInput = (key: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
  //   const _contact = JSON.parse(JSON.stringify(item.traveller || {}));
  //   _contact[key] = ev.target.value;
  //   onChange?.(_contact);
  // };

  // const onSelect = (key: string) => (ev: any) => {
  //   const _contact = JSON.parse(JSON.stringify(item?.traveller || {}));
  //   _contact[key] = ev;
  //   onChange?.(_contact);
  // };

  // const [isSave, setIsSave] = useState(false);
  // const onSaveChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
  //   const _contact = JSON.parse(JSON.stringify(item.traveller || {}));
  //   onChange?.({ ..._contact, saveTraveller: ev.target.checked });
  // };

  // const incidencesStateResource = [
  //   { value: "1", label: 'Daming / Wang' },
  //   { value: "2", label: 'SiaoMing / Wang' },
  //   { value: "3", label: 'JhongMing / Wang' },
  // ];

  // const sorterByLabel = (optionA: any, optionB: any) => optionA.label.localeCompare(optionB.label);

  if (!item || !item.traveller) {
    return null;
  }

  return <div className="order-travel-info-box">
    {!item?.orderNo ? null :
      <div className="order-travel-info-box-order">
        <div className="order-no">
          <span className="label">{getI18NText('订单号')}</span>
          <span className="value">{item.orderNo}</span>
        </div>
        <div className={[
          'order-status',
          getOrderStatusClassName(),
        ].filter(c => !!c).join(' ')}>
          {orderStatus}
        </div>
        {/* {(orderStatus === getI18NText('确认中') || hideCancelText) ? null :
          <div className="cancel-policy">
          <span className="icon">
            <img src={QuestionMarkCircleIcon}/>
          </span>
            <span className="text">{getI18NText('不可取消政策')}</span>
          </div>} */}
      </div>}
    <div className="order-travel-info-box-product">
      <div className="order-travel-info-box-product-image">
        <img src={item?.product?.thumbnail} />
      </div>
      <div className="order-travel-info-box-product-info-wrapper">
        <div className="order-travel-info-box-product-info">
          <div className="order-travel-info-box-product-title">
            {item?.product?.title}
          </div>
          <div className="order-travel-info-box-product-notice-list">
            {notices?.map((d, i) => <div key={i} className="order-travel-info-box-product-notice-item">
              {d}
            </div>)}
          </div>
        </div>
      </div>
    </div>
    {item?.extraInfo?.bookingExtraInfo && <div className="order-travel-info-box-content">
      <div className="order-travel-info-box-title">
        <div className="title">{getI18NText('出行人 (主要)')}</div>
        {/* {readonly ? null :
          <div className="frequent-traveller">
            <span className="frequent-traveller-title">{getI18NText('常旅客')}</span>
            <Select
              placeholder={getI18NText("请选择")}
              onChange={onFrequentTravellerChange}
              showSearch
              filterSort={sorterByLabel}
              optionFilterProp="label"
              options={travellers}
            ></Select>
          </div>} */}
      </div>
      <div className="order-travel-info-box-form">
        <div className="row row-warp">
          {
            item?.extraInfo?.bookingExtraInfo?.map((_item: any) => {
              const id = _item.key + ''

              switch (_item.input_type) {
                case 'text':
                  return <FormText
                    key={id}
                    errors={errors}
                    ownKey={id + ''}
                    title={_item.name}
                    emptyMsg={_item.description + _item.name}
                    readonly={readonly}
                    readonlyText={readonly ? item?.traveller?.[id] : ''}
                    placeholder={_item.description}
                    register={register}
                    setValue={setValue}
                    required={_item.required}
                  />
                case 'checkBox':
                  return <FormCheckBox
                    key={id}
                    errors={errors}
                    ownKey={id + ''}
                    readonly={readonly}
                    title={getI18NText('保存信息于下次使用')}
                    register={register}
                    setValue={setValue}
                    required={_item.required}
                  />
                case 'single_select':
                  return <FormList
                    key={id}
                    title={_item.name}
                    readonly={readonly}
                    readonlyText={readonly ? item?.traveller?.[id] : ''}
                    placeholder={_item.description}
                    errors={errors}
                    ownKey={id + ''}
                    register={register}
                    setValue={setValue}
                    options={_item.options}
                    required={_item.required}
                  />
                case 'date':
                  return <FormDate
                    key={id}
                    errors={errors}
                    ownKey={id + ''}
                    title={_item.name}
                    emptyMsg={_item.description + _item.name}
                    readonly={readonly}
                    readonlyText={readonly ? item?.traveller?.[id] : ''}
                    placeholder={_item.description}
                    register={register}
                    setValue={setValue}
                    getValues={getValues}
                    required={_item.required}
                  />
                case 'time':
                  return <FormTime
                    key={id}
                    title={_item.name}
                    readonly={readonly}
                    readonlyText={readonly ? item?.traveller?.[id] : ''}
                    placeholder={_item.description}
                    errors={errors}
                    ownKey={id + ''}
                    register={register}
                    setValue={setValue}
                    required={_item.required}
                  />

                default:
                  return null
              }
            })
          }

          {/* 
          <FormControl isRequired isInvalid={errors.firstName}>
            <div className='label-error-wrap'>
              <FormLabel>{getI18NText('名')}</FormLabel>
              <FormErrorMessage>
                {errors.firstName?.type === "required" && getI18NText("名不能为空")}
                {errors.firstName?.type === "pattern" && errors.firstName?.message}
              </FormErrorMessage>
            </div>
            {readonly ? <span>{item?.traveller?.firstName}</span> :
              <Input placeholder={`${getI18NText("拼音 例")}: Fang`} value={item?.traveller?.firstName} onInput={onInput('firstName')}
                {...register(
                  "firstName", {
                  required: true,
                  pattern: {
                    value: /^[\u4e00-\u9fa5a-zA-Z]{1,10}$/,
                    message: getI18NText("请输入正确的名")
                  }
                }
                )}
                onChange={(e) => {
                  setValue('firstName', e.target.value, {
                    shouldValidate: true,
                    shouldDirty: true,
                    shouldTouch: false
                  });
                }}
              />}
          </FormControl>
          <FormControl isRequired isInvalid={errors.lastName}>
            <div className='label-error-wrap'>
              <FormLabel>{getI18NText('姓')}</FormLabel>
              <FormErrorMessage>
                {errors.lastName?.type === "required" && getI18NText("姓不能为空")}
                {errors.lastName?.type === "pattern" && errors.lastName?.message}
              </FormErrorMessage>
            </div>
            {readonly ? <span>{item?.traveller?.lastName}</span> :
              <Input placeholder={`${getI18NText("拼音 例")}: Cheng`} value={item?.traveller?.lastName} onInput={onInput('lastName')}
                {...register(
                  "lastName", {
                  required: true,
                  pattern: {
                    value: /^[\u4e00-\u9fa5a-zA-Z]{1,10}$/,
                    message: getI18NText("请输入正确的姓")
                  }
                }
                )}
                onChange={(e) => {
                  setValue('lastName', e.target.value, {
                    shouldValidate: true,
                    shouldDirty: true,
                    shouldTouch: false
                  });
                }}
              />}
          </FormControl> */}
        </div>
        {/* <div className="row">
          <FormControl isRequired isInvalid={errors.email}>
            <div className='label-error-wrap'>
              <FormLabel>{getI18NText('邮箱')}</FormLabel>
              <FormErrorMessage>
                {errors.email?.type === "required" && getI18NText("邮箱不能为空")}
                {errors.email?.type === "pattern" && errors.email?.message}
              </FormErrorMessage>
            </div>
            {readonly ? <span>{item?.traveller?.email}</span> :
              <Input placeholder={getI18NText("请输入邮箱")} value={item?.traveller?.email} onInput={onInput('email')}
                {...register(
                  "email", {
                  required: true,
                  pattern: {
                    value: /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/,
                    message: getI18NText("请输入正确的邮箱")
                  }
                }
                )}
                onChange={(e) => {
                  setValue('email', e.target.value, {
                    shouldValidate: true,
                    shouldDirty: true,
                    shouldTouch: false
                  });
                }}
              />}
          </FormControl>
          <FormControl isRequired isInvalid={errors.phone}>
            <div className='label-error-wrap'>
              <FormLabel>{getI18NText('电话号码')}</FormLabel>
              <FormErrorMessage>
                {errors.phone?.type === "required" && getI18NText("电话号码不能为空")}
                {errors.phone?.type === "pattern" && errors.phone?.message}
              </FormErrorMessage>
            </div>
            {readonly ? <span>{item?.traveller?.phone}</span> :
              <Input placeholder={getI18NText("请输入电话号码")} value={item?.traveller?.phone} onInput={onInput('phone')}
                {...register(
                  "phone", {
                  required: true,
                  pattern: {
                    value: /^1[3-9]\d{9}$/,
                    message: getI18NText("请输入正确的电话号码")
                  }
                }
                )}
                onChange={(e) => {
                  setValue('phone', e.target.value, {
                    shouldValidate: true,
                    shouldDirty: true,
                    shouldTouch: false
                  });
                }}
              />}
          </FormControl>
        </div> */}
        {/* <div className="row">
          <FormControl isRequired className='select-nationality'>
            <FormLabel>{getI18NText('国籍')}</FormLabel>
            {readonly ? <span>{item?.traveller?.nationality}</span> :
              <Select
                placeholder={getI18NText("请选择国籍")}
                defaultValue={item?.traveller?.nationality}
                onChange={onSelect('nationality')}>
                <option value="中国">中国</option>
              </Select>
            }
          </FormControl>
        </div> */}
        {/* {readonly ? null :
          <div className="row">
            <Checkbox
              className={!!(item?.saveTraveller) ? 'checked' : ''}
              isChecked={!!(item?.saveTraveller)}
              onChange={onSaveChange}
            />
            <span>{getI18NText('保存信息于下次使用')}</span>
          </div>} */}
      </div>
    </div>}

    {item?.extraInfo?.unitExtraInfo && item?.skus?.map((sku: any) => new Array(sku.count).fill(0).map((_, index) => <div className="order-travel-info-box-content">
      <div className="order-travel-info-box-title">
        <div className="title">{sku.name}{index + 1}</div>
      </div>
      <div className="order-travel-info-box-form">
        <div className="row row-warp">
          {
            item?.extraInfo?.unitExtraInfo?.map((_item: any) => {
              const id = `${sku.id}/${index}/${_item.input_type}/${_item.key}`;

              switch (_item.input_type) {
                case 'text':
                  return <FormText
                    key={id}
                    errors={errors}
                    ownKey={id + ''}
                    title={_item.name}
                    emptyMsg={_item.description + _item.name}
                    readonly={readonly}
                    readonlyText={readonly ? item?.traveller?.[id] : ''}
                    placeholder={_item.description}
                    register={register}
                    setValue={setValue}
                    required={_item.required}
                  />
                case 'mobile':
                  return <FormMobile
                    key={id}
                    errors={errors}
                    ownKey={id + ''}
                    title={_item.name}
                    emptyMsg={_item.description + _item.name}
                    readonly={readonly}
                    readonlyText={readonly ? item?.traveller?.[id] : ''}
                    placeholder={_item.description}
                    register={register}
                    setValue={setValue}
                    required={_item.required}
                  />
                case 'checkBox':
                  return <FormCheckBox
                    key={id}
                    errors={errors}
                    ownKey={id + ''}
                    readonly={readonly}
                    title={getI18NText('保存信息于下次使用')}
                    register={register}
                    setValue={setValue}
                    required={_item.required}
                  />
                case 'single_select':
                  return <div className='chakra-form-control'>
                    <FormList
                      key={id}
                      title={_item.name}
                      readonly={readonly}
                      readonlyText={readonly ? item?.traveller?.[id] : ''}
                      placeholder={_item.description}
                      errors={errors}
                      ownKey={id + ''}
                      register={register}
                      setValue={setValueSelect.bind(this)}
                      options={_item.options}
                      required={_item.required}
                    />
                    {
                      _item.options && _item.options.filter((optionItem: any) => optionItem.key == keyValuePair[(id)]).map((cascadeOptionItem: any) => {
                        const subId = cascadeOptionItem.options?`${sku.id}/${index}/${_item.key}/${cascadeOptionItem.key}/${cascadeOptionItem.options[0].key}`:`${sku.id}/${index}/${_item.key}/${cascadeOptionItem.key}`;
                        console.log("_item.options:", cascadeOptionItem);
                        if (cascadeOptionItem && cascadeOptionItem.options) {
                          return <FormText
                            key={subId}
                            errors={errors}
                            ownKey={subId + ''}
                            title={cascadeOptionItem.name}
                            emptyMsg={cascadeOptionItem.description + cascadeOptionItem.name}
                            readonly={readonly}
                            readonlyText={readonly ? item?.traveller?.[subId] : ''}
                            placeholder={cascadeOptionItem.description}
                            register={register}
                            setValue={setValue}
                            required={cascadeOptionItem.required}
                          />
                        }
                      })
                    }
                  </div>
                case 'date':
                  return <FormDate
                    key={id}
                    errors={errors}
                    ownKey={id + ''}
                    title={_item.name}
                    emptyMsg={_item.description + _item.name}
                    readonly={readonly}
                    readonlyText={readonly ? item?.traveller?.[id] : ''}
                    placeholder={_item.description}
                    register={register}
                    setValue={setValue}
                    getValues={getValues}
                    required={_item.required}
                  />
                case 'time':
                  return <FormTime
                    key={id}
                    title={_item.name}
                    readonly={readonly}
                    readonlyText={readonly ? item?.traveller?.[id] : ''}
                    placeholder={_item.description}
                    errors={errors}
                    ownKey={id + ''}
                    register={register}
                    setValue={setValue}
                    required={_item.required}
                  />

                default:
                  return null
              }
            })
          }
        </div>
      </div>
    </div>))}
  </div>;
};

export default OrderTravelInfoBox;
