export enum ProductSort {
  PriceAsc = 'price_asc',
  PriceDesc = 'price_desc',
  ScoreDesc = 'score_desc',
};

export const ProductSortList = [
  {
    value: ProductSort.PriceAsc,
    label: '金额低至高',
  },
  {
    value: ProductSort.PriceDesc,
    label: '金额高至低',
  },
  {
    value: ProductSort.ScoreDesc,
    label: '热门推荐',
  },
];

export const getSortOption = (sort: ProductSort) => {
  switch (sort) {
    case ProductSort.PriceAsc:
      return { sortBy: 'price', sort: 'asc' };
    case ProductSort.PriceDesc:
      return { sortBy: 'price', sort: 'desc' };
    case ProductSort.ScoreDesc:
      return { sortBy: 'score', sort: 'desc' };
  }
};
