import React, {useRef} from 'react';
import './DateFilter.scss';
import Filter from './Filter';
import 'moment';
import 'moment/locale/zh-cn';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import DateRangePicker from '../date/DateRangePicker';

import { useI18NText } from '~/i18n/i18n';


const DateFilter: React.FC<DateFilterProps> = (props) => {
  // i18n国际化纯文本
  const { getI18NText } = useI18NText({ prefix: 'component.' })

  const {title, onChange, dates} = props;

  const onClear = () => {
    onChange?.([null, null]);
  };

  const dateRangePickerRef = useRef<typeof DateRangePicker>(null);

  return <Filter title={title} className="date-filter" onClear={onClear}>
    <DateRangePicker
      dates={dates}
      onChange={onChange}
      extra={<div className="extra-date-info">
        <div>{getI18NText('展示产品当地时间')}</div>
        <div/>
      </div>}
    />
  </Filter>;
};

export default DateFilter;
