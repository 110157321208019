import React from 'react';
import './Filter.scss';

import { useI18NText } from '~/i18n/i18n';

const Filter: React.FC<FilterProps> = (props) => {
  // i18n国际化纯文本
  const { getI18NText } = useI18NText({ prefix: 'component.' })

  const {title, className, onClear} = props;
  return <div className={['filter', className].filter(cls => !!cls).join(' ')}>
    <div className="filter-top">
      <div className="filter-title">{title}</div>
      <div className="filter-clean" onClick={() => onClear?.()}>{getI18NText('清空')}</div>
    </div>
    <div className="filter-content">
      {props.children}
    </div>
  </div>;
};

export default Filter;
