import React from 'react';
import { Box, Image, Flex, Text, VStack, chakra } from '@chakra-ui/react';
import wechat from './wechat.png';
import './Footer.scss';

import ImageLogo from '~/assets/images/footer-logo.png';
import { useLocation } from 'react-router-dom';
import { useI18NText } from '~/i18n/i18n';

const LayoutFooter = () => {
  // i18n国际化纯文本
  const { getI18NText } = useI18NText({ prefix: 'footer.' });
  const location = useLocation();

  //判断当前页面是否是登录页
  const isLoggedOut = location.pathname === '/login';

  return (
    // <div className="layout-footer">
    <Box px="6" w="full" bgColor="#002C5E" color="#ffffff">
      {isLoggedOut ? null : (
        <Box
          maxW="1200px"
          mx="auto"
          pt="9"
          pb="6"
          borderBottomWidth="1px"
          borderColor="rgba(215, 223, 238, 0.2)"
        >
          <Flex justify="space-between">
            <Box>
              <Image w="60" minW="60" h="auto" src={ImageLogo} alt="" />
            </Box>
            <VStack align="start" color="#D7DFEE">
              <Text fontSize="md" mb="2">
                关于多会儿旅行
              </Text>
              <FooterLink href="https://www.tripintl.com/" text="公司介绍" />
              <FooterLink href="https://www.tripintl.com/aboutus" text="多会儿服务" />
            </VStack>
            <VStack align="start" color="#D7DFEE">
              <Text fontSize="md" mb="2">
                多会儿产品
              </Text>
              <FooterLink href="https://www.tripintl.com/products" text="全球酒店" />
              <FooterLink href="https://www.tripintl.com/products" text="全球当地玩乐" />
              <FooterLink href="https://www.tripintl.com/APIpartner" text="API分销" />
              <FooterLink href="https://www.tripintl.com/APIpartner" text="技术合作" />
            </VStack>
            <VStack align="start">
              <Text fontSize="lg" fontWeight="medium" mb="2">
                业务咨询
              </Text>
              <Text fontSize="lg" fontWeight="medium">
                021-5106-1106
              </Text>
              <Text fontSize="lg" fontWeight="medium">
                tripintl@tripintl.com
              </Text>
            </VStack>
            <VStack align="start">
              <Text fontSize="md" color="#D7DFEE">
                微信公众号
              </Text>
              <Image w="24" h="24" src={wechat} alt="" />
            </VStack>
          </Flex>
        </Box>
      )}

      <Box py="6" color="#D7DFEE">
        <VStack spacing="0">
          {/* <div className="links">
              {getI18NText("隐私政策")} | {getI18NText("公司政策")} |{" "}
              {getI18NText("条件与条款")}
            </div> */}
          <Text fontSize="sm">
            © 2017-2021 tripintl.com{' '}
            {getI18NText('沪ICP备_号', {
              interpolation: { num: '18018917' },
            })}{' '}
            {getI18NText('上海临行网络科技有限公司')}
          </Text>
          <Text fontSize="sm">北京市朝阳区光华路9号世贸天阶大厦24层</Text>
        </VStack>
      </Box>
    </Box>
    // </div>
  );
};

export default LayoutFooter;

const FooterLink = (props: { text: string; href: string }) => {
  return (
    <chakra.a
      cursor="pointer"
      fontSize="sm"
      color="#D7DFEE"
      _hover={{ textDecorationLine: 'underline', color: '#F2C501' }}
      href={props.href}
      target="_blank"
    >
      {props.text}
    </chakra.a>
  );
};
