import React, { useEffect, useRef, useState } from 'react';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, useDisclosure } from '@chakra-ui/react';
import { map } from 'lodash';
import dayjs from 'dayjs';
import { useRecoilState } from 'recoil';

import { TourBaseUrl } from '~/constants';
import { post } from '~/utils/request';
import { useI18NText } from '~/i18n/i18n';
import { hidePageLoading, showPageLoading } from '~/components/pageloading/PageLoading';
import Container from '~/layouts/components/Container';
import OrderListFilter from '~/components/order/OrderListFilter';
import OrderListTable from '~/components/order/OrderListTable';
import orderListFilterState from '~/recoil/orderListFilterState';

import './OrderList.scss';
import { Helmet } from 'react-helmet';

const getOrderNo = (orderNo: string) => {
  const arr = orderNo.split('#');
  if (arr.length > 1) {
    return arr[1];
  }
  return orderNo;
};

const OrderList = () => {
  // i18n国际化纯文本
  const { getI18NText } = useI18NText({ prefix: 'orders.list.' });
  const firstRef = useRef(true);
  const [pathItems, setPathItems] = useState(['我的', '订单', '当地玩乐', '订单列表']);
  const [_filterState, setFilterState] = useRecoilState<OrderListFilterState>(orderListFilterState);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const sep = '>';

  const [total, setTotal] = useState(93);
  const [data, setData] = useState<any[]>([]);

  const loadOrders = async () => {
    const options: any = {
      page: _filterState.page,
      size: _filterState.size,
    };
    if (_filterState.keyword) {
      options.keyword = _filterState.keyword;
    }
    if (_filterState.bookingStatus && _filterState.bookingStatus !== 'all') {
      options.status = _filterState.bookingStatus;
    }
    if (_filterState.paymentMethod && _filterState.paymentMethod !== 'all') {
      options.paymentType = _filterState.paymentMethod;
    }
    if (_filterState.sort) {
      options.sort = _filterState.sort;
    }
    if (
      /* (_filterState.dateField && _filterState.dateField !== 'all') && */ _filterState.startDate ||
      _filterState.endDate
    ) {
      options.dateType = _filterState.dateField;
      options.dateRange = {};
      if (_filterState.startDate) {
        options.dateRange.min = _filterState.startDate;
      }
      if (_filterState.endDate) {
        options.dateRange.max = _filterState.endDate;
      }
    }

    onOpen();

    const res = await post(`${TourBaseUrl}/api/orderList`, options);

    onClose();

    const { data } = res || { data: {} };
    setTotal(data.totalCount);
    setData(
      map(data.orders, (row: any) => {
        const result: any = {
          orderNo: getOrderNo(row.id),
          product: {
            name: row.productname,
            count: row.skucount,
          },
          contact: {
            surname: row.tourcontactname.split('/')[1] || '',
            name: row.tourcontactname.split('/')[0] || '',
            phone: row.tourcontactphone,
          },
          travelDate: row.productday,
          submitTime: dayjs(row.createdtime).format('YYYY-MM-DD HH:mm:ss'),
          totalAmount: row.agencytotalprice,
          payMethod: '微信',
          paymentStatus: row.paymentstatus,
          status: row.status,
        };
        return result;
      }),
    );
  };

  useEffect(() => {
    showPageLoading();
    loadOrders().then(() => hidePageLoading());
    return () => hidePageLoading();
  }, []);

  useEffect(() => {
    if (firstRef.current) firstRef.current = false;
    else loadOrders();
  }, [_filterState.page, _filterState.sort]);

  return (
    <>
      <Helmet>
        <title>订单列表 - 当地玩乐｜多会儿旅行</title>
      </Helmet>
      <div className="order-list">
        <Container>
          <Breadcrumb
            className="order-list-breadcrumb"
            separator={<span style={{ margin: '0 8px' }}>{sep}</span>}
          >
            {pathItems.map((path, i) => (
              <BreadcrumbItem key={i}>
                <BreadcrumbLink>{path}</BreadcrumbLink>
              </BreadcrumbItem>
            ))}
          </Breadcrumb>

          <div className="order-list-title">{getI18NText('订单列表')}</div>

          <OrderListFilter loadOrders={loadOrders} loading={isOpen} />

          <OrderListTable total={total} data={data} />
        </Container>
      </div>
    </>
  );
};

export default OrderList;
