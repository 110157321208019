import React, { useEffect, useState } from 'react';
import { Button, FormControl, FormLabel, Tooltip } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { map } from 'lodash';

import { get, post } from '~/utils/request';
import { TourBaseUrl } from '~/constants';
import { getI18NTextOnce, useI18NText } from '~/i18n/i18n';
import { hidePageLoading, showPageLoading } from '~/components/pageloading/PageLoading';
import { ORDER_STATUS_MAP, PAYMENT_STATUS_MAP } from '~/utils/orderStatus';

import Container from '../../layouts/components/Container';
import NavBack from '../../components/nav/NavBack';
import OrderTitle from '../../components/order/OrderTitle';
import OrderPayInfoBox from '../../components/order/OrderPayInfoBox';
import OrderTravelInfoBox from '../../components/order/OrderTravelInfoBox';
import OrderDetailProductItem from '../../components/order/OrderDetailProductItem';
import OrderContactInfoBox from '../../components/order/OrderContactInfoBox';
import QuestionMarkCircleIcon from '../../assets/icons/QuestionMarkCircleIcon.svg';

import './OrderDetail.scss';
import MarkedHtml from '~/components/markedhtml/MarkedHtml';
import { Helmet } from 'react-helmet';
import { formatStrWithEllipsis } from '~/utils/helper';

const OrderDetail = () => {
  // i18n国际化纯文本
  const { getI18NText } = useI18NText({ prefix: 'orders.id.' });

  const navigate = useNavigate();
  const { id: bookingId } = useParams();

  const [travellers, setTravellers] = useState<Contact[]>([]);

  const [contact, setContact] = useState<Contact>({
    firstName: 'DaTian',
    lastName: 'Lin',
    email: 'lin@gmail.com',
    phone: '12345678901',
  });

  const [orderNo, setOrderNo] = useState(bookingId);
  const [orderStatus, setOrderStatus] = useState('确认中');

  const [orderDetail, setOrderDetail] = useState<any>();

  const [orderTime, setOrderTime] = useState('2021/11/10 18:01:21');
  const [paidMethod, setPaidMethod] = useState('微信');
  const [paidTime, setPaidTime] = useState('2021/11/10 18:01:21');
  const [totalAmount, setTotalAmount] = useState(1294);
  const [status, setStatus] = useState('已付款');
  const [tourItems, setTourItems] = useState<any[]>([]);
  const [timeLeft, setTimeLeft] = useState(0);
  const [travellerInfos, setTravellerInfos] = useState<any[]>([]);
  const [tourItem, setTourItem] = useState<any>();

  const loadOrderDetail = async () => {
    const response = await get(`${TourBaseUrl}/api/orderDetail?bookingId=${bookingId}`);
    const orderDetail = response.data.bookings[0];
    console.log('orderDetail:', orderDetail);
    setOrderDetail(orderDetail);
    // orderDetail.status = 'SUCCESS'

    const { name, phone, email } = orderDetail.tourCustomer.contact;
    setContact({
      ...name,
      phone,
      email,
    });
    setTimeLeft(orderDetail.timeLeft || 0);
    setPaidTime(
      dayjs(orderDetail.bookingPayment.paidAt).add(8, 'hours').format('YYYY-MM-DD HH:mm:ss'),
    );
    setOrderTime(dayjs(orderDetail.createdAt).format('YYYY-MM-DD HH:mm:ss'));
    setTotalAmount(orderDetail.price);
    setOrderStatus(getI18NText(ORDER_STATUS_MAP[orderDetail.status]));
    setStatus(PAYMENT_STATUS_MAP[orderDetail.bookingPayment.paymentStatus]);
    setTravellerInfos(
      JSON.parse(
        orderDetail.tourItem.extraInfo.unitExtraInfo ||
          orderDetail.tourItem.extraInfo.bookingExtraInfo,
      ),
    );
    console.log(
      'setTravellerInfos:',
      JSON.parse(
        orderDetail.tourItem.extraInfo.unitExtraInfo ||
          orderDetail.tourItem.extraInfo.bookingExtraInfo,
      ),
    );
    setTourItem({
      ...orderDetail.tourItem,
      orderNo: orderDetail.bookingId,
    });
    setTourItems(
      map(orderDetail.tourItems, (item: any) => {
        return {
          orderNo: bookingId,
          title: item.product.title,
          subtitle: item.package?.title,
          quantity: map(item.skus, (row: any) => [row.name, row.count].join(' x ')).join(' / '),
          date: item.date,
          price: item.total,
          product: item.product,
          traveller: {
            ...item.traveller.name,
            phone: item.traveller.phone,
            email: item.traveller.email,
            nationality: item.traveller.nationality,
          },
        };
      }),
    );
  };

  useEffect(() => {
    if (bookingId) {
      showPageLoading();
      loadOrderDetail().then(() => hidePageLoading());
    }
    return () => hidePageLoading();
  }, []);

  const onCancel = async () => {
    let confirmResult = window.confirm(getI18NText('确认取消?'));
    console.log('onChancel:', bookingId);
    if (confirmResult) {
      const response = await post(`${TourBaseUrl}/api/order/cancel`, { bookingId });
      console.log('cancel order result:', response);
      if (
        response.data &&
        response.data.status &&
        response.data.status.toString() == 'CANCELLING'
      ) {
        loadOrderDetail();
        alert(response.data.message);
      } else {
        alert('不可取消,请联系人工客服');
      }
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const renderItemNotices = (item: any) => {
    const day = dayjs(item.date, 'YYYY-MM-DD').format(
      `YYYY${getI18NTextOnce('component.年')}MM${getI18NTextOnce(
        'component.月',
      )}DD${getI18NTextOnce('component.日')}`,
    );
    return [
      `${getI18NText('方案类型')}: ${item.package.title}`,
      `${getI18NText('产品数量')}: ${
        item.skus.length > 0 ? item.skus[0].name + 'x' + item.skus[0].count : 0
      }`,
      `${getI18NText('出游日期')}: ${day}`,
    ];
  };

  const title = orderDetail?.tourItem?.product?.title || '';

  return (
    <>
      <Helmet>
        <title>{formatStrWithEllipsis(title, 15)} 当地玩乐｜多会儿旅行</title>
      </Helmet>
      <div className="order-detail">
        <Container>
          <div className="order-detail-top">
            <NavBack onClick={handleBack} label={`> ${getI18NText('返回上一页')}`} />
          </div>

          <div className="order-detail-title">{getI18NText('订单详情')}</div>

          <div className="order-detail-container">
            <div className="order-detail-content">
              <OrderTitle title={getI18NText('订单信息')} />

              {
                /* [getI18NText(ORDER_STATUS_MAP.PENDING)].indexOf(orderStatus) > -1 &&  */ <OrderPayInfoBox
                  orderTime={orderTime}
                  paidMethod={paidMethod}
                  paidTime={paidTime}
                  totalAmount={totalAmount}
                  status={status}
                  orderStatus={orderStatus}
                  timeLeft={timeLeft}
                  onChange={(t: number) => setTimeLeft(t)}
                />
              }
              {tourItem && (
                <OrderDetailProductItem
                  item={tourItem}
                  notices={renderItemNotices(tourItem)}
                  orderStatus={orderDetail.status}
                />
              )}
              {map(travellerInfos, (item: any, idx) => (
                <div key={idx}>
                  <div style={{ marginBottom: '16px' }}></div>
                  <div className="order-travel-info-box">
                    <div className="title">{getI18NText('出行人 (主要)')}</div>
                    <div className="row row-warp">
                      {map(item.items, (traItem: any, idx) => {
                        return (
                          <FormControl key={idx}>
                            <FormLabel>{traItem.name}</FormLabel>
                            <FormLabel>
                              {traItem.selected
                                ? traItem.selected[0].selected
                                  ? traItem.selected[0].selected[0].content
                                  : traItem.selected[0].content
                                : traItem.content}
                            </FormLabel>
                          </FormControl>
                        );
                      })}
                    </div>
                  </div>
                  {/* <OrderTravelInfoBox
                  contact={travellers[0]}
                  item={item}
                  readonly
                  orderNo={orderNo}
                  travellers={[]}
                  orderStatus={orderStatus}
                  notices={renderItemNotices(item)}
                /> */}
                </div>
              ))}
              <OrderContactInfoBox contact={contact} readonly />

              {
                /* [ORDER_STATUS_MAP.SUCCESS].indexOf(orderStatus) > -1 && */ <>
                  <OrderTitle title={getI18NText('取消政策')} />
                  <div className="order-detail-cancel">
                    <Tooltip
                      label={
                        <>
                          <MarkedHtml html={'兑换凭证前可免费取消' || ''} />
                        </>
                      }
                      hasArrow
                      arrowSize={16}
                      placement="right"
                      bg="#002C5E"
                      borderRadius="4px"
                      padding="12px"
                    >
                      <div className="cancel-policy">
                        <span className="icon">
                          <img src={QuestionMarkCircleIcon} />
                        </span>
                        <span className="label">{getI18NText('兑换凭证前可免费取消')}</span>
                      </div>
                    </Tooltip>
                    {orderDetail?.status == 'SUCCESS' && (
                      <Button className="btn plain" onClick={onCancel}>
                        {getI18NText('取消订单')}
                      </Button>
                    )}
                  </div>
                </>
              }
            </div>

            {[
              getI18NText(ORDER_STATUS_MAP.PENDING),
              getI18NText(ORDER_STATUS_MAP.SUCCESS),
              getI18NText(ORDER_STATUS_MAP.USED),
            ].indexOf(orderStatus) > -1 && (
              <div className="order-detail-sidebar">
                <div className="order-info-box">
                  <div className="order-info-box-top">
                    <span className="label">{getI18NText('订单号')}</span>
                    <span className="order-no">{orderNo}</span>
                  </div>
                  <div className="order-info-box-content">
                    <div className="row">
                      <div className="label">{getI18NText('凭证方式')}</div>
                      <div className="value">{getI18NText('电子凭证')}</div>
                    </div>
                    <div className="row">
                      <div className="label">{getI18NText('确认类型')}</div>
                      <div className="value">{getI18NText('一日内确认并发送凭证')}</div>
                    </div>
                  </div>
                  <div className="order-info-box-bottom">
                    {orderStatus === ORDER_STATUS_MAP.SUCCESS ? (
                      <Button className="btn primary" style={{ marginLeft: 0 }}>
                        {getI18NText('查看凭证')}
                      </Button>
                    ) : null}
                    {orderStatus === ORDER_STATUS_MAP.SUCCESS ? (
                      <Button className="btn warning" style={{ marginLeft: 0 }}>
                        {getI18NText('导出行程单')}
                      </Button>
                    ) : null}
                    {orderStatus === ORDER_STATUS_MAP.PENDING ? (
                      <Button
                        className="btn warning"
                        style={{ marginLeft: 0, flexGrow: 1 }}
                        onClick={() => navigate(`/orders/pay?booking=${orderNo}`)}
                      >
                        {getI18NText('立即支付')}
                      </Button>
                    ) : null}
                  </div>
                </div>
              </div>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};
export default OrderDetail;
