export const formatStrWithEllipsis = (value: string, maxLength: number = 10) => {
  if (!value) {
    return '';
  }

  if (value.length <= maxLength) {
    return value;
  }

  return value.substring(0, maxLength) + '...';
};
