import React, {FormEvent, useRef, useState, useEffect} from 'react';
import * as qs from 'qs';
import {
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select, 
  useOutsideClick,
  Spinner,
} from '@chakra-ui/react';
import { useForm } from "react-hook-form";
import {ChevronDownIcon, SearchIcon} from '@chakra-ui/icons';
import {useNavigate} from 'react-router-dom';
import { map, concat } from 'lodash';

import { get } from '~/utils/request';
import { TourBaseUrl } from '~/constants';
import MapPinIcon from '~/assets/icons/MapPinIcon.svg';
import TicketIcon from '~/assets/icons/TicketIcon.svg';
import FlagIcon from '~/assets/icons/FlagIcon.svg';
import ForkKnifeIcon from '~/assets/icons/ForkKnifeIcon.svg';

import './SearchBox.scss';
import exampleTicket from './exampleTicket.png';

import { useI18NText } from '~/i18n/i18n';
import { createAntiShake } from '~/utils/shake';

// 防抖
const antiShake = createAntiShake()

const inputListSample = [
  {type: 'location', title: '北京', location: '北京,中国'},
  {type: 'location', title: '北京', location: '北京,中国'},
  {type: 'product', title: '北京故宫紫禁城门票', id: '1', location: '北京,中国'},
  {type: 'product', title: '北京故宫紫禁城门票', id: '2', location: '北京,中国'},
];
const selectListSample = [
  {value: 'all', label: '所有产品分类'},
  {value: 'ticket', label: '景点门票 & 表演'},
  {value: 'one-day', label: '一日游 & 小团游'},
  {value: 'featured', label: '特色活动 & 体验'},
  {value: 'food', label: '地道美食'},
  {value: 'travel', label: '当地交通 & 旅行服务'},
];


const SearchBox: React.FC<SearchBoxProps> = (props) => {
  // i18n国际化纯文本
  const { getI18NText } = useI18NText({ prefix: 'component.' })
  const defaultCatetory = {
    value: 'all',
    label: getI18NText('所有产品分类'),
  };

  // navigate
  const navigate = useNavigate();
  
  const {
    register,
    formState: { errors },
  } = useForm();

  // input variables
  const [inputOpen, setInputOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const emptyInputList: any[] = [];
  const [inputList, setInputList] = useState(emptyInputList);
  const inputRef = useRef(null);

  // select variables
  const [selectOpen, setSelectOpen] = useState(false);
  const [isInvalidIn, setValid] = useState(false);
  const [selectValue, setSelectValue] = useState('');
  const [selectList, setSelectList] = useState([defaultCatetory]);
  const selectRef = useRef<HTMLInputElement>(null);
  const [selectedLabel, setSelectedLabel] = useState(selectList[0]?.label);
  const [searchLoading, setSearchLoading] = useState(false);

  const loadCategoryList = async (keyword?: string) => {
    const options: {
      city?: string;
    } = {};
    if (keyword) {
      options.city = keyword;
    }
    const categories = await get(`${TourBaseUrl}/api/categories`, { ...options });
    const categoryList = map(categories.data, category => ({
      value: category,
      label: category,
    }));
    categoryList.unshift(defaultCatetory);
    setSelectList(categoryList);

    if (!categoryList.find(({ label }) => label === selectedLabel)) setSelectedLabel(categoryList[0]?.label)
  };

  const loadSearchHint = async (keyword: string) => {
    const searchHints = await get(`${TourBaseUrl}/api/home/searchHints`, {
      keyword,
      city: 5,
      product: 5,
    });
    const { citySuggestions, productSuggestions } = searchHints?.data || {};
    const locations = map(citySuggestions, row => ({
      type: 'location',
      title: row,
      location: row,
    }));
    const products = map(productSuggestions, row => ({
      type: 'product',
      title: row.title,
      id: row.id,
      thumbnail: row.thumbnail,
      location: [row.city, row.country].join(','),
    }));
    setInputList(concat(locations, products));
  };

  const onInputInput = async (e: FormEvent<HTMLInputElement>) => {
    const value = (e.target as HTMLInputElement).value;
    setInputValue(value);

    antiShake.use(async () => {
      if (value?.length >= 1) {
        setSearchLoading(true)
        await Promise.all([
          loadCategoryList(value),
          loadSearchHint(value)
        ]);
        setSearchLoading(false)
        setInputOpen(true);
      }
      else loadCategoryList()
    })
  };

  const onInputFocus = () => {
    setInputOpen(true);
  };

  const onInputBlur = () => {
    setInputOpen(false);
  };

  const onSelectClick = () => {
    setSelectOpen(true);
  };

  useOutsideClick({
    ref: selectRef,
    handler: () => setSelectOpen(false),
  });

  const onClickItem = (item: any) => {
    console.log('click item', item);
    if (item.type === 'product') {
      navigate(`/products/${item.id}`);
    } else if (item.type === 'location') {
      navigate(`/location/${item.title}`);
    }
  };

  const onClickSearch = ()=> {
    if(inputValue){
      const searchParams: any = { keyword: inputValue };
      if (selectValue && selectValue !== 'all') {
        searchParams.category = selectedLabel;
      }
      navigate(`/search?${qs.stringify(searchParams)}`);
    }else{
      setValid(true);
      // navigate('/search/404')
    }
  }

  useEffect(() => {
    loadCategoryList();
  }, ['']);

  useEffect(() => {
    // 停止执行未执行的方法
    return () => antiShake.stop()
  }, [])

  return <div className="search-box">
    <Popover
      isOpen={inputOpen}
      initialFocusRef={inputRef}
    >
      <PopoverTrigger>
        <InputGroup className="search-input-group">
          <InputLeftElement
            className="search-input-icon"
            pointerEvents="none"
            children={<SearchIcon color="gray.300"/>}
          />
          <Input
            ref={inputRef}
            className={["search-input", inputValue.length == 0 && isInvalidIn ? "is-invalid" : null].join(' ') }
            placeholder={getI18NText('输入目的地/城市，找寻理想的活动')}
            onInput={onInputInput}
            onFocus={onInputFocus}
            onBlur={onInputBlur}
          />
          <InputRightElement
            className="search-input-loading"
          >
            {searchLoading && <Spinner size="sm" />}
          </InputRightElement>
        </InputGroup>
      </PopoverTrigger>
      <PopoverContent className="location-popover-content">
        <ul className="location-list">
          {inputList
            .filter(d => !inputValue ? true : d.title.includes(inputValue))
            .map((d, i) => {
              let prefix = null;
              if (d.type === 'location') {
                prefix = <img src={MapPinIcon} style={{width: 24, height: 24}}/>;
              } else if (d.type === 'product') {
                prefix = <img src={d.thumbnail || exampleTicket}/>;
              }
              return <li key={i} className="location-item" onClick={() => onClickItem(d)}>
                <span className="location-icon">
                  {prefix}
                </span>
                <span className="location-title">{d.title}</span>
                <span className="location-location">{d.location}</span>
              </li>;
            })}
        </ul>
      </PopoverContent>
    </Popover>

    <Popover
      isOpen={selectOpen}
      initialFocusRef={selectRef}
    >
      <PopoverTrigger>
        <div
          className={["search-select-wrapper", selectOpen ? "active" : null].join(' ') }
          onClick={onSelectClick}
        >
          <div
            ref={selectRef}
            className="search-select"
          >
            {selectedLabel}
            <ChevronDownIcon w={12} h={7} color="#8B8B8B" className="dropdown-icon"/>
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent className="category-popover-content">
        <ul className="category-list">
          {selectList
            .map((d, i) => {
              return <li key={i} className="category-item" onClick={() => {setSelectedLabel(d.label); setSelectValue(d.value);}}>
                <span className="category-title">{d.label}</span>
              </li>;
            })}
        </ul>
      </PopoverContent>
    </Popover>

    <Button
      className="search-button"
      leftIcon={<SearchIcon/>}
      onClick={() => onClickSearch()}
    >
      {getI18NText('搜索')}
    </Button>
  </div>;
};

export default SearchBox;
