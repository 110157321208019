import React, {useEffect, useState} from 'react';
import dayjs from 'dayjs';

import { useI18NText } from '~/i18n/i18n';
import { ORDER_STATUS_MAP, PAYMENT_STATUS_MAP } from '~/utils/orderStatus';

import './OrderPayInfoBox.scss';

const OrderPayInfoBox: React.FC<OrderPayInfoBoxProps> = (props) => {
  // i18n国际化纯文本
  const { getI18NText } = useI18NText({ prefix: 'component.' })

  const {
    orderTime,
    paidMethod,
    paidTime,
    totalAmount,
    status,
    orderStatus,
    timeLeft,
    onChange,
  } = props;

  const [t, setT] = useState<number>(timeLeft || 0);

  const getTime = () => {
    if (t <= 0) {
      return '00:00:00';
    }
    const m = dayjs().startOf('day').add(t, 'seconds');
    return m.format('HH:mm:ss');
  };

  const renderPayStatus = (() => {
    if (ORDER_STATUS_MAP.INVALID === orderStatus) {
      return orderStatus;
    }
    return status;
  })();

  useEffect(() => {
    if (renderPayStatus !== PAYMENT_STATUS_MAP.unpaid) return;
    setT(timeLeft!);
    const handle = setInterval(() => {
      setT(() => {
        const r = (timeLeft || 0) - 1;
        onChange?.(r);
        return r;
      });
    }, 1000);

    // cleanup
    return () => clearInterval(handle);
  }, [timeLeft, renderPayStatus]);

  return <div className="order-pay-info-box">
    <div className="order-pay-info-box-title" style={{color: renderPayStatus === PAYMENT_STATUS_MAP.unpaid ? '#FF6770' : ''}}>
      {renderPayStatus}
    </div>
    <div className="order-pay-info-box-top">
      {renderPayStatus === PAYMENT_STATUS_MAP.unpaid ? <div className="row">
        <div className="label">{getI18NText('下单时间')}</div>
        <div className="value">
          {getI18NText('请于')}<span className="time">{getTime()}</span>{getI18NText('内完成付款')}
        </div>
      </div> : null}
      <div className="row">
        <div className="label">{getI18NText('下单时间')}</div>
        <div className="value">{orderTime}</div>
      </div>
      {status === PAYMENT_STATUS_MAP.paid ? <div className="row">
        <div className="label">{getI18NText('支付方式')}</div>
        <div className="value">{paidMethod}</div>
      </div> : null}
      {status === PAYMENT_STATUS_MAP.paid ? <div className="row">
        <div className="label">{getI18NText('支付时间')}</div>
        <div className="value">{paidTime}</div>
      </div> : null}
    </div>
    <div className="order-pay-info-box-bottom">
      <span className="label">{getI18NText('总金额')}</span>
      <span className="total-amount">
        <span className="unit">¥</span>
        <span className="value">{totalAmount.toLocaleString()}</span>
      </span>
    </div>
  </div>;
};

export default OrderPayInfoBox;
