import {
  Box,
  Button,
  Heading,
  Image,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  VStack,
  HStack,
} from "@chakra-ui/react";
import NavMenuButton from "./NavMenuButton";
import forgetPassword from "~/views/login/forgetPassword.png";
import useAuthingClient from "~/hooks/useAuthingClient";

const NavMenuLogout = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { logout } = useAuthingClient()

  const handleLogout = () => {
    // TODO: clear token
    onClose();
    // navigate("/login");
    logout()
  };

  return (
    <>
      <NavMenuButton text="登出" onClick={onOpen} />
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="#002C5E">
            <VStack pt="4">
              <Box w="9" h="9" rounded="full" overflow="hidden">
                <Image src={forgetPassword} alt="warning" />
              </Box>
              <Heading fontSize="2xl">登出</Heading>
            </VStack>
          </ModalHeader>
          <ModalBody py="0">
            <Text textAlign="center" color="#333333">
              确定要登出网站吗？
            </Text>
          </ModalBody>
          <ModalFooter pb="8">
            <HStack w="full" align="center" justify="center">
              <Button
                fontSize="sm"
                fontWeight="normal"
                variant="outline"
                onClick={onClose}
                color="#002C5E"
                borderColor="#002C5E"
                w="24"
              >
                返回
              </Button>
              <Button
                fontSize="sm"
                fontWeight="normal"
                onClick={handleLogout}
                color="#002C5E"
                borderColor="#F0D43A"
                bgColor="#F0D43A"
                colorScheme="yellow"
                w="24"
              >
                确定登出
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
export default NavMenuLogout;
