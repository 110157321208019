import React, {useRef, useState, Component} from 'react';
import Select, { Option } from 'rc-select';
import {Button, Spinner} from '@chakra-ui/react';
import {useRecoilState} from 'recoil';
import {useLocation, useNavigate} from 'react-router-dom';
import { trim } from 'lodash';

import { ProductSort, ProductSortList } from '~/utils/product-sort.util';
import selectState from '../../recoil/selectState';
import ArrowRightCircleIcon from '../../assets/icons/ArrowRightCircleIcon.png';
import ArrowLeftCircleIcon from '../../assets/icons/ArrowLeftCircleIcon.png';

import Pagination from '../nav/Pagination';
import ProductThumbnail from './ProductThumbnail';
import '../select/select.scss'
import './ProductList.scss';

import { useI18NText } from '~/i18n/i18n';

const ProductList: React.FC<ProductListProps> = (props) => {
  // i18n国际化纯文本
  const { getI18NText } = useI18NText({ prefix: 'component.' })
  const navigate = useNavigate();

  const {
    title,
    products,
    pagination,
    className,
    scrollable,
    sort,
    sortChange,
    noSort,
    total,
    pageSize,
    current,
    onPaginationChange,
    loadend,
    hideSearch,
  } = props;

  const [_selectState, setSelectState] = useRecoilState<SelectState>(selectState);
  const {
    colourOptions
  } = _selectState;

  // const onPaginationChange = (page: number, pageSize: number) => {
  //   setCurrent(page);
  // };

  const [itemWidth, setItemWidth] = useState(270 + 24);
  const [index, setIndex] = useState(0);


  const productListRef = useRef<HTMLDivElement>(null);

  const getStyle = () => {
    return {
      transform: `translateX(${-index * itemWidth}px)`
    };
  };
  
  const sorterByLabel = (optionA:any, optionB:any) => optionA.label.localeCompare(optionB.label);

  const getVisibleProductCount = (): number => {
    if (productListRef.current?.clientWidth === undefined) return 0;
    return Math.floor(productListRef.current.clientWidth / itemWidth);
  };

  const getRemainingProductThumbnails = (): React.ReactNode[] => {
    const res = [];
    const n = 15 - products.length;
    for (let i = 0; i < n; i++) {
      res.push(<div key={i} className="product-thumbnail-placeholder"/>);
    }
    return res;
  };

  return <div className={[
    'product-list-wrapper',
    className,
    scrollable ? 'scrollable' : '',
  ].filter(c => !!c).join(' ')}
  >
    {title ?
      <div className="product-list-top">
        <div className="product-list-title">
          {title}
        </div>
        <div className="product-list-sort-wrapper" style={{display: noSort ? 'none' : ''}}>
          <span className="product-list-sort-title">{getI18NText('排序方法')}: </span>
         <Select
            
            showSearch={false}
            filterSort={sorterByLabel}
            optionFilterProp="label"
            defaultValue={sort || ProductSort.ScoreDesc}
            options={ProductSortList.map(item => ({ ...item, label: getI18NText(item.label) }))}
            onSelect={sortChange}
         ></Select>
        </div>
      </div>
      : null}
    {products.length > 0 ? <div
        ref={productListRef}
        className={[
          'product-list',
        ].filter(c => !!c).join(' ')}
      >
        {products.map((product, i) =>
          <ProductThumbnail
            key={i}
            title={trim(product.title)}
            category={product.category}
            price={product.price}
            thumbnail={product.thumbnail}
            id={product.id}
            style={getStyle()}
          />)}
        {getRemainingProductThumbnails()}
      </div>
      :
      ((typeof loadend === 'boolean' ? loadend : true) ? (
        <div className="product-list-not-found">
          <div className="title">{hideSearch ? getI18NText('抱歉，我们没有找到相关的产品') : getI18NText('抱歉，我们没有找到相关的产品，请重新搜索')}</div>
          {!hideSearch && (
            <div className="actions">
              <Button className="btn warning" onClick={() => navigate('/')}>{getI18NText('返回页面')}</Button>
            </div>
          )}
        </div>
      ) : (
        <div className="product-list-not-found">
          <div className="title"><Spinner style={{ marginRight: '10px' }} size="sm" />{getI18NText('加载中')}</div>
        </div>
      ))}
    {scrollable && index > 0 ? <div className="prev" onClick={() => setIndex(index - 4)}>
      <img src={ArrowLeftCircleIcon}/>
    </div> : null}
    {scrollable && index <= products.length - 1 - getVisibleProductCount() ?
      <div className="next" onClick={() => setIndex(index + 4)}>
        <img src={ArrowRightCircleIcon}/>
      </div> : null}
    {pagination && products.length > 0 ?
      <div className="product-list-pagination">
        <Pagination total={total} pageSize={pageSize} current={current} onChange={onPaginationChange}/>
      </div>
      : null}
  </div>;
};

export default ProductList;
