import axios, { AxiosRequestConfig } from 'axios';
import { merge } from 'lodash';
import { toast } from 'react-toastify';
import i18n from '../i18n/i18n';
import AuthHelper from './auth';

axios.interceptors.request.use(
  function (config) {
    const token = AuthHelper.getToken() || window.localStorage.getItem('_trip_b2b_token');

    if (token) {
      (config.headers as any).authorization = `Bearer ${token}`;
    }

    return config;
  },
  function (error) {
    console.log('error: ', error);

    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  function (response) {
    console.log('response: ', response);
    return response;
  },
  function (error) {
    console.log('error: ', JSON.stringify(error));

    // 后端可能改了返回，status = 401 的时候 response 为 undefined
    // 无法判断 status，所以默认 response = undefined 也做登出操作
    if (
      !error.response ||
      (error.response && (error.response.status === 403 || error.response.status === 401))
    ) {
      toast.error('登录失效,请重新登录', {
        position: 'top-center',
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => {
        localStorage.clear();
        // let url = `${window.location.host}`;
        // if (!url.startsWith('http')) {
        //   url = `http://${url}/login`;
        // }
        window.location.href = 'https://b2b.tripintl.com/login';
      }, 500);
    }

    return Promise.resolve(error);
  },
);

// const getToken = () => {
//   const token = Cookie.get("_trip_token");
//   if (token) {
//     localStorage.setItem("_trip_token", token);
//     return token;
//   }

// }

const request = async (_options: AxiosRequestConfig, method = 'GET') => {
  const options = merge(
    { ..._options },
    {
      headers: {
        'i18n-language': i18n.language || 'zh', // 当前选择的语言
      },
    },
    {
      method,
    },
  );
  return axios(options);
};

/**
 * 封装get请求
 * @param { String } url 请求路径
 * @param { Object } 请求参数
 * params GET请求参数
 */
const get = (url: string, params?: any, _options?: AxiosRequestConfig) => {
  return request({ ..._options, params, url });
};

/**
 * 封装post请求
 * @param { Object } 请求参数
 * data POST请求请求参数，对象形式
 */
const post = (url: string, data?: any, _options?: AxiosRequestConfig) => {
  return request({ ..._options, data, url }, 'POST');
};

export { get, post, request };
