export const ORDER_STATUS_MAP: any = {
  FAILED: '预定失败',
  SUCCESS: '已确认',
  USED: '已出行',
  INVALID: '已失效',
  PENDING: '待付款',
  CANCELLED: '已取消',
  CANCELLING: '取消中',
  PROCESSING: '确认中',
  IN_BOOKING: '预定中',
};

export const PAYMENT_STATUS_MAP: any = {
  paid: '已付款',
  unpaid: '待付款',
};
