import { useNavigate } from 'react-router-dom';

import Cookie from 'js-cookie';
import { HttpApiErrorCode, User } from '~/types';
import axios, { AxiosError } from 'axios';
import { TourBaseUrl } from '~/constants';

const clearToken = () => {
  window.localStorage.removeItem('_trip_b2b_token');
  window.localStorage.removeItem('_trip_b2b_refresh');
  Cookie.remove('_trip_token', {
    domain: '.tripintl.com',
  });
  Cookie.remove('_trip_refresh', {
    domain: '.tripintl.com',
  });
};

async function memberLogin(input: { account: string; password: string }): Promise<{
  accessToken?: string;
  refreshToken?: string;
  errorCode?: HttpApiErrorCode;
}> {
  const { account, password } = input;

  try {
    const memberLoginResponse = await axios.post<{
      accessToken: string;
      refreshToken: string;
    }>(`${TourBaseUrl}/auth/login`, {
      phone: account,
      password: password,
    });

    const { accessToken, refreshToken } = memberLoginResponse.data;
    return { accessToken, refreshToken };
  } catch (error) {
    const bookingFailedError = error as AxiosError<{ errorCode: string; message: string }>;
    if (bookingFailedError.response) {
      const { errorCode, message } = bookingFailedError.response.data;

      console.error('Request faile for member login api: ', message);
      return {
        errorCode: errorCode as HttpApiErrorCode,
      };
    }

    return {
      errorCode: HttpApiErrorCode.UNEXPECTED_ERROR,
    };
  }
}

async function memberLogout(input: { refreshToken: string }): Promise<{
  isLoggedOut: boolean;
  errorCode?: HttpApiErrorCode;
}> {
  const { refreshToken } = input;

  try {
    await axios.post<{
      accessToken: string;
      refreshToken: string;
    }>(`${TourBaseUrl}/auth/logout`, {
      refreshToken,
    });
  } catch (error) {
    const bookingFailedError = error as AxiosError<{ errorCode: string; message: string }>;
    if (bookingFailedError.response) {
      const { errorCode, message } = bookingFailedError.response.data;

      console.error('Request faile for member refresh token api: ', message);
      return {
        isLoggedOut: false,
        errorCode: errorCode as HttpApiErrorCode,
      };
    }

    return {
      isLoggedOut: false,
      errorCode: HttpApiErrorCode.UNEXPECTED_ERROR,
    };
  }

  return { isLoggedOut: true };
}

async function memberDetail(input: { accessToken: string }): Promise<{
  errorCode?: HttpApiErrorCode;
  user?: User;
}> {
  let user: User;
  try {
    const memberDetailResponse = await axios.get<User>(`${TourBaseUrl}/auth/member`);
    user = memberDetailResponse.data;
  } catch (error) {
    const bookingFailedError = error as AxiosError<{ errorCode: string; message: string }>;
    if (bookingFailedError.response) {
      const { errorCode, message } = bookingFailedError.response.data;

      console.error('Request faile for member refresh token api: ', message);
      return {
        errorCode: errorCode as HttpApiErrorCode,
      };
    }

    return {
      errorCode: HttpApiErrorCode.UNEXPECTED_ERROR,
    };
  }

  return { user };
}

async function memberVerifyUser(): Promise<boolean> {
  try {
    await axios.get<{}>(`${TourBaseUrl}/agency/user/verify`);

    return true;
  } catch (error) {
    return false;
  }
}

async function memberUpdatePassword(input: { oldPassword: string; newPassword: string }): Promise<{
  errorCode?: HttpApiErrorCode;
}> {
  const { oldPassword, newPassword } = input;

  try {
    await axios.post<any>(`${TourBaseUrl}/auth/updatepassword`, {
      oldPassword,
      newPassword,
    });
  } catch (error) {
    const bookingFailedError = error as AxiosError<{ errorCode: string; message: string }>;
    if (bookingFailedError.response) {
      const { errorCode, message } = bookingFailedError.response.data;

      console.error('Request faile for member refresh token api: ', message);
      return {
        errorCode: errorCode as HttpApiErrorCode,
      };
    }

    return {
      errorCode: HttpApiErrorCode.UNEXPECTED_ERROR,
    };
  }

  return {};
}

export default function useAuthingClient() {
  const navigate = useNavigate();

  const login = async (input: { username: string; password: string }): Promise<void> => {
    const { username, password } = input;
    try {
      const { accessToken, refreshToken } = await memberLogin({
        account: username,
        password,
      });
      if (!accessToken || !refreshToken) {
        throw new Error('No Token after login API has been called');
      }

      window.localStorage.setItem('_trip_b2b_token', accessToken);
      window.localStorage.setItem('_trip_b2b_refresh', refreshToken);
      // 添加 cookie
      Cookie.set('_trip_token', accessToken, {
        domain: '.tripintl.com',
      });
      Cookie.set('_trip_refresh', refreshToken, {
        domain: '.tripintl.com',
      });
    } catch (error) {
      // clearToken();
      // navigate('/login');
      throw error;
    }
  };

  const getUser = async (): Promise<User | null> => {
    const token = window.localStorage.getItem('_trip_b2b_token') || '';
    try {
      const { user = null } = await memberDetail({
        accessToken: token,
      });

      return user;
    } catch (error) {
      clearToken();
      navigate('/login');

      throw error;
    }
  };

  const checkIfLoggedIn = (): boolean => {
    const isTokenExist = !!window.localStorage.getItem('_trip_b2b_token');
    const isRefreshTokenExist = !!window.localStorage.getItem('_trip_b2b_refresh');

    return isTokenExist && isRefreshTokenExist;
  };

  // const getIdToken = async (): Promise<string> => {
  //   let token = window.localStorage.getItem('_trip_b2b_token') || '';
  //   const refresh = window.localStorage.getItem('_trip_b2b_refresh');

  //   const tokenInfo = token ? jwt.decode(token, { complete: true }) : null;
  //   const { exp = null } = tokenInfo ? tokenInfo?.payload : {};
  //   const currentUnixTimestamp = getUnixTime(new Date());
  //   if ((exp || Number.MIN_SAFE_INTEGER) < currentUnixTimestamp) {
  //     try {
  //       const { accessToken, refreshToken } = await memberRefreshToken({
  //         refreshToken: refresh || '',
  //       });

  //       if (!accessToken || !refreshToken) {
  //         throw new Error('No Token after login API has been called');
  //       }

  //       window.localStorage.setItem('_trip_b2b_token', accessToken);
  //       window.localStorage.setItem('_trip_b2b_refresh', refreshToken);

  //       token = accessToken;
  //     } catch (error) {
  //       console.error('Refreshing token has error', error);
  //       window.localStorage.removeItem('_trip_b2b_token');
  //       window.localStorage.removeItem('_trip_b2b_refresh');

  //       navigate('/login');

  //       throw '无法取得数据，请重新登入';
  //     }
  //   }

  //   return token;
  // };

  const logout = async () => {
    const refresh = window.localStorage.getItem('_trip_b2b_refresh');
    try {
      await memberLogout({
        refreshToken: refresh || '',
      });
    } catch (error) {
      console.error('logout at server side error', error);
    }

    clearToken();
    // navigate('/login');
    window.location.href = 'https://b2b.tripintl.com/login';
  };

  const modifyPassword = async (oldPassword: string, newPassword: string) => {
    try {
      await memberUpdatePassword({
        oldPassword,
        newPassword,
      });
    } catch (error) {
      clearToken();
      navigate('/login');

      throw error;
    }
  };

  const verifyUser = async () => {
    try {
      const isValidUser = await memberVerifyUser();

      return isValidUser;
    } catch (error) {
      console.error('verifyUser at server side error', error);
    }
  };

  return {
    login,
    getUser,
    checkIfLoggedIn,
    // getIdToken,
    logout,
    modifyPassword,
    verifyUser,
  };
}
