import React, { useEffect, useState } from 'react';
import * as qs from 'qs';
import { map, filter, isNumber } from 'lodash';
import { Moment } from 'moment';
import { useRecoilState } from 'recoil';

import Container from '../../layouts/components/Container';
import TreeFilter from '../../components/filter/TreeFilter';
import ProductList from '../../components/product/ProductList';
import DateFilter from '../../components/filter/DateFilter';
import RangeFilter from '../../components/filter/RangeFilter';
import filterState from '../../recoil/filterState';
import Cart from '../../components/cart/Cart';

import { useI18NText } from '~/i18n/i18n';
import { hidePageLoading, showPageLoading } from '~/components/pageloading/PageLoading';
import { post, get } from '~/utils/request';
import { TourBaseUrl } from '~/constants';
import { ProductSort, getSortOption } from '~/utils/product-sort.util';

import './ProductListSearch.scss';
import { findContinentItem } from '~/recoil/layoutState';
import { Helmet } from 'react-helmet';
import { formatStrWithEllipsis } from '~/utils/helper';

let lastFilters = '';

const Search = () => {
  // i18n国际化纯文本
  const { getI18NText } = useI18NText({ prefix: 'search.' });

  const getIsNotFound = (): boolean => {
    return window.location.pathname.endsWith('/404');
  };
  const queryParams = qs.parse(window.location.search.slice(1));

  const [keyword, setKeyword] = useState(queryParams.keyword);
  const [category, setCategory] = useState(queryParams.category);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchSort, setSearchSort] = useState(ProductSort.ScoreDesc);
  const [productTotal, setProductTotal] = useState(getIsNotFound() ? 0 : 480);

  const emptyProducts: Product[] = [];
  const [products, setProducts] = useState(emptyProducts);
  const [productsLoadend, setProductsLoadend] = useState(false);

  const [_filterState, setFilterState] = useRecoilState<FilterState>(filterState);

  const { locationFilterItems, categoryFilterItems, languageFilterItems, priceRange, dates } =
    _filterState;

  useEffect(() => {
    const obj: { [key: string]: any } = {};
    const loactions: FilterItem[] = [];

    products.forEach((item) => {
      if (item.countryName) {
        if (!obj[item.countryName]) {
          const find = findContinentItem(item.countryName);

          if (find) {
            if (!obj[find.name]) obj[find.name] = {};
            obj[find.name][item.countryName] = 1;
          }
        }
      }
    });

    Object.keys(obj).forEach((continentName) => {
      const continent = obj[continentName];
      const location: FilterItem = {
        label: continentName,
        value: continentName,
        children: [],
      };
      const find = locationFilterItems.find((item) => item.value === location.value);
      if (find) {
        location.checked = find.checked;
        location.intermediateChecked = find.intermediateChecked;
      }

      Object.keys(continent).forEach((countryName) => {
        const children: FilterItem = {
          label: countryName,
          value: countryName,
        };
        if (find) {
          const findChildren = find.children?.find((item) => item.value === children.value);

          if (findChildren) {
            children.checked = findChildren.checked;
            children.intermediateChecked = findChildren.intermediateChecked;
          }
        }

        location.children?.push(children);
      });

      loactions.push(location);
    });

    setFilterState((state) => ({
      ...state,
      locationFilterItems: loactions,
    }));
  }, [products]);

  const loadCategories = async () => {
    const categoryList = await get(`${TourBaseUrl}/api/categories`);
    setFilterState({
      ..._filterState,
      categoryFilterItems: map(categoryList.data, (category) => ({
        label: category,
        value: category,
      })),
    });
  };

  const loadProducts = async (params: any) => {
    const list = await post(`${TourBaseUrl}/api/products`, params);
    const result = map(list.data.docs, (row) => ({
      title: row.title,
      id: row.id,
      price: row.displayPrice,
      category: row.categoryName,
      thumbnail: row.thumbnail?.url,
      cityName: row.cityName,
      countryName: row.countryName,
    }));
    return { list: result, total: list.data.total };
  };

  const searchProducts = async () => {
    const params: any = {
      page: currentPage,
      size: 9,
    };
    const sortOption = getSortOption(searchSort);
    params.sortType = [sortOption.sortBy];
    const priceOptions: any = {};
    let by = false;
    const { priceRange = [] } = _filterState;
    if (isNumber(priceRange[0])) {
      by = true;
      priceOptions.min = priceRange[0];
    }
    if (isNumber(priceRange[1])) {
      by = true;
      priceOptions.max = priceRange[1];
    }
    if (sortOption.sortBy === 'price') {
      by = true;
      priceOptions.sort = sortOption.sort;
    }
    if (by) {
      params.price = priceOptions;
    }
    by = false;
    const scoreOptions: any = {};
    if (sortOption.sortBy === 'score') {
      by = true;
      scoreOptions.sort = sortOption.sort;
    }
    if (by) {
      params.score = scoreOptions;
    }
    const checkedCategories = map(filter(categoryFilterItems, 'checked'), 'label');
    if (checkedCategories.length) {
      params.categories = checkedCategories;
    }
    const checkedLanguages = map(filter(languageFilterItems, 'checked'), 'value');
    if (checkedLanguages.length) {
      // params.languages = checkedLanguages;
    }
    if (keyword) {
      params.keyword = keyword;
    }
    const locations = locationFilterItems.reduce((arr: string[], item) => {
      item.children?.forEach((_item) => {
        if (_item.checked) arr.push(_item.value);
      });

      return arr;
    }, []);
    if (locations.length > 0) {
      params.countryName = locations;
    }

    const key = JSON.stringify(params);

    if (lastFilters !== key) {
      lastFilters = key;

      const { list, total } = await loadProducts(params);
      setProducts(list);
      setProductTotal(total);
      setProductsLoadend(true);
    }
  };

  const onLocationFilterItemsChange = (items: FilterItem[]) => {
    setFilterState({
      ..._filterState,
      locationFilterItems: items,
    });
  };

  const onCategoryFilterItems = (items: FilterItem[]) => {
    setFilterState({
      ..._filterState,
      categoryFilterItems: items,
    });
  };

  const onLanguageFilterItems = (items: FilterItem[]) => {
    setFilterState({
      ..._filterState,
      languageFilterItems: items,
    });
  };

  const onPriceChange = (range: [(number | undefined)?, (number | undefined)?]) => {
    setFilterState({
      ..._filterState,
      priceRange: range,
    });
  };

  const onDatesChange = (dates: [Moment | null, Moment | null]) => {
    setFilterState({
      ..._filterState,
      dates,
    });
  };
  const onPaginationChange = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    // 在这里加载数据
    searchProducts();
  }, [_filterState, currentPage, searchSort]);

  useEffect(() => {
    showPageLoading();
    loadCategories().then(() => hidePageLoading());
    return () => {
      hidePageLoading();
      lastFilters = '';
    };
  }, []);

  const renderSearchFor = () => {
    const arr: any[] = [];
    if (keyword) {
      arr.push(keyword);
    }
    if (category) {
      arr.push(category);
    }
    return arr.join('+');
  };

  const search = new URLSearchParams(window.location.search);
  const kword = search.get('keyword') || '';

  return (
    <>
      <Helmet>
        <title>{formatStrWithEllipsis(kword)} 当地玩乐 | 多会儿旅行</title>
      </Helmet>
      <Container>
        <div className="product-list-search">
          <div className="filter-list">
            <TreeFilter
              title={getI18NText('目的地')}
              items={locationFilterItems}
              onChange={onLocationFilterItemsChange}
            />
            <TreeFilter
              title={getI18NText('产品分类')}
              items={categoryFilterItems}
              onChange={onCategoryFilterItems}
            />
            <DateFilter title={getI18NText('可预定日期')} dates={dates} onChange={onDatesChange} />
            <RangeFilter title={getI18NText('产品价格')} onChange={onPriceChange} />
            {/* <TreeFilter title={getI18NText('服务语言')} items={languageFilterItems} onChange={onLanguageFilterItems}/> */}
          </div>
          <ProductList
            title={
              <div className="product-list-title">
                <span>{getI18NText('搜索')}</span>
                <span className="product-list-title-highlight">{renderSearchFor()}</span>
                <span style={{ marginLeft: '20px' }}>{getI18NText('找到')}</span>
                <span className="product-list-title-highlight">{productTotal}</span>
                <span>{getI18NText('个产品')}</span>
              </div>
            }
            products={getIsNotFound() ? [] : products}
            pagination
            total={productTotal}
            current={currentPage}
            pageSize={9}
            sort={ProductSort.ScoreDesc}
            sortChange={(value: ProductSort) => setSearchSort(value)}
            onPaginationChange={onPaginationChange}
            hideSearch
            loadend={productsLoadend}
          />
        </div>

        <Cart />
      </Container>
    </>
  );
};

export default Search;
