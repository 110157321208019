export const NavLocations = [
  {
    name: "東亞",
    data: [
      {
        area: "韩国",
        locations: [
          { name: "首尔" },
          { name: "釜山" },
          { name: "济州岛" },
          { name: "江原道" },
          { name: "京畿道" },
        ],
      },
      {
        area: "日本",
        locations: [
          { name: "东京" },
          { name: "北海道" },
          { name: "京都" },
          { name: "大阪" },
          { name: "冲绳" },
          { name: "爱知县" },
          { name: "福冈县" },
          { name: "埼玉县" },
        ],
      },
      {
        area: "中国内地",
        locations: [
          { name: "上海" },
          { name: "北京" },
          { name: "深圳" },
          { name: "广州" },
          { name: "三亚市" },
          { name: "珠海" },
          { name: "成都" },
          { name: "惠州" },
          { name: "云南" },
          { name: "杭州市" },
        ],
      },
      {
        area: "中国台湾",
        locations: [
          { name: "台北" },
          { name: "台中" },
          { name: "台南" },
          { name: "宜兰" },
          { name: "花莲" },
          { name: "台东" },
          { name: "南投" },
          { name: "高雄" },
          { name: "澎湖" },
          { name: "屏东" },
        ],
      },
      {
        area: "中国港澳",
        locations: [{ name: "香港" }, { name: "澳门" }],
      },
    ],
  },
  {
    name: "東南亞",
    data: [
      {
        area: "泰国",
        locations: [
          { name: "曼谷" },
          { name: "普吉岛" },
          { name: "清迈" },
          { name: "华欣" },
          { name: "芭提雅" },
          { name: "甲米岛" },
          { name: "素叻他尼" },
          { name: "清莱" },
          { name: "皮皮岛" },
          { name: "素可泰" },
        ],
      },
      {
        area: "马来西亚",
        locations: [
          { name: "吉隆坡" },
          { name: "兰卡威" },
          { name: "槟城" },
          { name: "新山" },
          { name: "马六甲" },
          { name: "云顶高原" },
          { name: "怡保" },
          { name: "雪兰莪" },
          { name: "沙巴" },
          { name: "迪沙鲁" },
        ],
      },
      {
        area: "越南",
        locations: [
          { name: "会安" },
          { name: "岘港" },
          { name: "芽庄市" },
          { name: "富国岛" },
          { name: "沙坝" },
          { name: "胡志明市" },
        ],
      },
      {
        area: "新加坡",
        locations: [{ name: "新加坡" }],
      },
    ],
  },
  {
    name: "大洋洲",
    data: [
      {
        area: "澳洲",
        locations: [
          { name: "珀斯" },
          { name: "悉尼" },
          { name: "凯恩斯" },
          { name: "黄金海岸" },
          { name: "霍巴特" },
          { name: "爱丽斯泉" },
          { name: "墨尔本" },
        ],
      },
    ],
  },
];
