import Cookie from "js-cookie";

const TOKEN_KEY = "_trip_token";
// const REFRESH_KEY = "_trip_refresh";

const getToken = () => {
  // 优先从 cookie 取最新 token
  const tokenInCookie = getTokenFromCookie();
  if (tokenInCookie) {
    localStorage.setItem(TOKEN_KEY, tokenInCookie);
    return tokenInCookie;
  }

  const tokenInLocalStorage = localStorage.getItem(TOKEN_KEY);
  return tokenInLocalStorage || "";
};

const getTokenFromCookie = () => Cookie.get(TOKEN_KEY);

const AuthHelper = {
  getToken,
};

export default AuthHelper;
