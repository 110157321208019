import React from 'react';
import './OrderContactInfoBox.scss';
import {Checkbox, FormControl, FormLabel, Input, Select,FormErrorMessage} from '@chakra-ui/react';
import { useForm } from "react-hook-form";

import { useI18NText } from '~/i18n/i18n';

const OrderContactInfoBox: React.FC<OrderContactInfoBoxProps> = (props) => {
  // i18n国际化纯文本
  const { getI18NText } = useI18NText({ prefix: 'component.' })
  const {
    readonly,
    contact,
    onChange,
    checked,
    onSaveChange,
  } = props;

  const {
    setValue,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  const onInput = (key: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
    const _contact = JSON.parse(JSON.stringify(contact));
    _contact[key] = ev.target.value;
    onChange?.(_contact);
  };

  return <div className="order-contact-info-box">
    <div className="order-contact-info-box-content">
      <div className="order-contact-info-box-title">
        <div className="title">{getI18NText('联系人')}</div>
      </div>
      <div className="order-contact-info-box-form">
        <div className="row">
          <FormControl isRequired isInvalid={errors.firstName}>
          <div className='label-error-wrap'>
              <FormLabel>{getI18NText('名')}</FormLabel>
              <FormErrorMessage>
                {errors.firstName?.type === "required" && getI18NText("名不能为空")}
                {errors.firstName?.type === "pattern" && errors.firstName?.message}
              </FormErrorMessage>
            </div>
            {readonly ? <span>{contact.firstName}</span> :
              <Input placeholder={`${getI18NText('拼音 例')}: Fang`} value={contact.firstName} onInput={onInput('firstName')}
                {...register(
                  "firstName", {
                    required:true,
                    pattern: {
                      value: /^[\u4e00-\u9fa5a-zA-Z]{1,10}$/,
                      message:getI18NText("请输入正确的名")
                    }
                  }
                )}
                onChange={(e) => {
                  setValue('firstName', e.target.value, {
                    shouldValidate: true,
                    shouldDirty: true,
                    shouldTouch:false
                  });
                }}
              />}
          </FormControl>
          <FormControl isRequired isInvalid={errors.lastName}>
            <div className='label-error-wrap'>
              <FormLabel>{getI18NText('姓')}</FormLabel>
              <FormErrorMessage>
                {errors.lastName?.type === "required" && getI18NText("姓不能为空")}
                {errors.lastName?.type === "pattern" && errors.lastName?.message}
              </FormErrorMessage>
            </div>
            {readonly ? <span>{contact.lastName}</span> :
            <Input placeholder={`${'拼音 例'}: Cheng`} value={contact.lastName} onInput={onInput('lastName')} 
              {...register(
                "lastName", {
                  required:true,
                  pattern: {
                    value: /^[\u4e00-\u9fa5a-zA-Z]{1,10}$/,
                    message:getI18NText("请输入正确的姓")
                  }
                }
              )}
              onChange={(e) => {
                setValue('lastName', e.target.value, {
                  shouldValidate: true,
                  shouldDirty: true,
                  shouldTouch:false
                });
              }}
            />}
          </FormControl>
        </div>
        <div className="row">
        <FormControl isRequired isInvalid={errors.email}>
            <div className='label-error-wrap'>
              <FormLabel>{getI18NText('邮箱')}</FormLabel>
              <FormErrorMessage>
                {errors.email?.type === "required" && getI18NText("邮箱不能为空")}
                {errors.email?.type === "pattern" && errors.email?.message}
              </FormErrorMessage>
            </div>
            {readonly ? <span>{contact.email}</span> :
              <Input placeholder={getI18NText("请输入邮箱")} value={contact.email} onInput={onInput('email')}
                {...register(
                  "email", {
                    required:true,
                    pattern: {
                      value: /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/,
                      message:getI18NText("请输入正确的邮箱")
                    }
                  }
                )}
                onChange={(e) => {
                  setValue('email', e.target.value, {
                    shouldValidate: true,
                    shouldDirty: true,
                    shouldTouch:false
                  });
                }}
              />}
          </FormControl>
          <FormControl isRequired isInvalid={errors.phone}>
            <div className='label-error-wrap'>
              <FormLabel>{getI18NText('电话号码')}</FormLabel>
              <FormErrorMessage>
                {errors.phone?.type === "required" && getI18NText("电话号码不能为空")}
                {errors.phone?.type === "pattern" && errors.phone?.message}
              </FormErrorMessage>
            </div>
            {readonly ? <span>{contact.phone}</span> :
              <Input placeholder={getI18NText("请输入电话号码")} value={contact.phone} onInput={onInput('phone')}
                {...register(
                  "phone", {
                    required:true,
                    pattern: {
                      value: /^1[3-9]\d{9}$/,
                      message:getI18NText("请输入正确的电话号码")
                    }
                  }
                )}
                onChange={(e) => {
                  setValue('phone', e.target.value, {
                    shouldValidate: true,
                    shouldDirty: true,
                    shouldTouch:false
                  });
                }}
              />}
          </FormControl>
        </div>
        {/**
        {readonly ? null :
          <div className="row">
            <Checkbox
              isChecked={checked}
              onChange={onSaveChange}
            />
            <span>{getI18NText('同时更新资料')}</span>
          </div>}
          **/}
      </div>
    </div>
  </div>;
};

export default OrderContactInfoBox;
