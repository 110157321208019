import { atom } from 'recoil';
import { getI18NTextOnce } from '~/i18n/i18n';

const navMenuItems = [
  {
    name: '东亚',
    children: [
      {
        name: '韩国',
        children: `首尔、釜山、济州岛、江原道、京畿道`.split('、').map((name) => ({ name })),
      },
      {
        name: '日本',
        children: `东京、北海道、京都、大阪、冲绳、爱知县、福冈县、埼玉县`
          .split('、')
          .map((name) => ({ name })),
      },
      {
        name: '中国内地',
        children: `上海、北京、深圳、广州、三亚市、珠海、成都、惠州、云南、杭州市`
          .split('、')
          .map((name) => ({ name })),
      },
      {
        name: '中国台湾',
        children: `台北、屏东、台中、台南、宜兰、台东、花莲、南投、高雄、澎湖`
          .split('、')
          .map((name) => ({ name })),
      },
      {
        name: '中国港澳',
        children: `香港、澳门`.split('、').map((name) => ({ name })),
      },
    ],
  },
  {
    name: '东南亚',
    children: [
      {
        name: '泰国',
        children: `曼谷、普吉岛、清迈、华欣、芭提雅、甲米岛、素叻他尼、清莱、皮皮岛、素可泰`
          .split('、')
          .map((name) => ({ name })),
      },
      {
        name: '马来西亚',
        children: `吉隆坡、兰卡威、槟城、新山、马六甲、云顶高原、怡保、雪兰莪、沙巴、迪沙鲁`
          .split('、')
          .map((name) => ({ name })),
      },
      {
        name: '越南',
        children: `会安、岘港、芽庄市、富国岛、顺化、沙坝、胡志明市`
          .split('、')
          .map((name) => ({ name })),
      },
      {
        name: '新加坡',
        children: `新加坡`.split('、').map((name) => ({ name })),
      },
    ],
  },
  {
    name: '大洋洲',
    children: [
      {
        name: '澳洲',
        children:
          `珀斯、悉尼、凯恩斯、黄金海岸、霍巴特、爱丽斯泉、墨尔本、菲利普岛、达尔文、阿德莱德`
            .split('、')
            .map((name) => ({ name })),
      },
    ],
  },
];

export default atom<LayoutState>({
  key: 'layoutState',
  default: {
    headerNavItemsLeft: [
      {
        name: '酒店',
        path: 'hotel',
      },
      {
        name: '当地玩乐',
        path: 'recreation',
      },
    ],
    headerNavItemsRight: [
      {
        name: '行程',
        path: 'journey',
      },
      {
        name: '我的',
        path: 'me',
        dropdown: true,
        children: [
          {
            name: '会员中心',
          },
          {
            name: '当地玩乐订单',
            path: 'orders',
          },
          {
            name: '酒店订单',
          },
          {
            name: '登出',
            // onClick: () => {
            //   // @ts-ignore
            //   window.navigate('/login');
            // },
            className: 'logout',
          },
        ],
      },
    ],
    headerNavItemsRightLoggedOut: [
      {
        name: '登陆',
        path: 'login',
      },
    ],
    navMenuItems,
  },
});

// 寻找国家所在大洲
export const findContinent = (countryName?: string): string => {
  if (!countryName) return '';

  const find = findContinentItem(countryName);

  return !!find ? find.name : '';
};
export const findContinentItem = (countryName?: string) => {
  if (!countryName) return null;

  const find = navMenuItems.find(
    (item) =>
      item.children.findIndex(
        (_item) =>
          getI18NTextOnce(`areas.${_item.name}`) === countryName ||
          _item.children.findIndex(
            (__item) => getI18NTextOnce(`areas.${__item.name}`) === countryName,
          ) > -1,
      ) > -1,
  );

  return !!find ? find : null;
};
