import React, {useEffect, useState} from 'react';
import { useRecoilState } from 'recoil';
import {Button, Checkbox, Modal, ModalBody, ModalContent, ModalOverlay, Text, useDisclosure} from '@chakra-ui/react';
import {useNavigate} from 'react-router-dom';

import { useI18NText } from '~/i18n/i18n';
import orderListFilterState from '~/recoil/orderListFilterState';
import { ORDER_STATUS_MAP } from '~/utils/orderStatus';

import TableSort from '../table/TableSort';
import Pagination from '../nav/Pagination';
import './OrderListTable.scss';
import { WarningIcon } from '@chakra-ui/icons';

const OrderListTable: React.FC<OrderListTableProps> = (props) => {
  // i18n国际化纯文本
  const { getI18NText } = useI18NText({ prefix: 'component.' })

  const navigate = useNavigate();
  const [_filterState, setFilterState] = useRecoilState<OrderListFilterState>(orderListFilterState);

  const {total, data} = props;
  const columns = [
    {
      key: 'orderNo',
      label: '订单号',
    },
    {
      key: 'product',
      label: '产品名称',
    },
    {
      key: 'submitTime',
      label: '预定提交时间',
    },
    {
      key: 'travelTime',
      label: '预定出行时间',
    },
    {
      key: 'totalAmount',
      label: '订单总价',
    },
    {
      key: 'status',
      label: '支付方式订单状态',
    },
    {
      key: 'contact',
      label: '联系人手机',
    },
  ];

  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(_filterState.size);
  const [currentOrderType, sortByOrderType] = useState("");//根据订单字段排序
  const [CurrentDirectionType, sortByDirectionType] = useState(-1);//根据方向排序
  const { isOpen, onOpen, onClose } = useDisclosure()
  
  const onPageChange = (page: number, _pageSize: number) => {
    setCurrent(page);
    setPageSize(_pageSize);
    setFilterState({
      ..._filterState,
      page,
      size: pageSize,
    });
  };

  const handleSortByOrderType = (orderType: string, DirectionType: number) => {
    sortByOrderType(orderType);
    sortByDirectionType(DirectionType);
    const sortArr: string[] = [orderType];
    if (DirectionType === 1) {
      sortArr.push('asc');
    }
    if (DirectionType === -1) {
      sortArr.push('desc');
    }
    setFilterState({
      ..._filterState,
      sort: sortArr.join('_'),
    });

    // const mysort = (a:any,b:any)=> {
    //   if(a[orderType] !== b[orderType]){
    //     if(DirectionType == -1){
    //       return a[orderType] > b[orderType] ? -1 : 1;
    //     }
    //     else if(DirectionType == 1){
    //       return a[orderType] < b[orderType] ? -1 : 1;
    //     }
    //   }
    //   return a[orderType] < b[orderType] ? -1 : 1;
    // }
    // data.sort(mysort);
  }

  const [checkedItems, setCheckedItems] = React.useState<boolean[]>([])
  const allChecked = checkedItems.length > 0 && checkedItems.every(Boolean)
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked

  useEffect(() => {
    if (checkedItems.length !== data.length) {
      setCheckedItems(data.map((_, index) => checkedItems[index] || false))
    }
  }, [data])

  const onChangeCheckState = (i:number,e: React.ChangeEvent<HTMLInputElement>)=>{
    // 需要克隆一个数组，改变克隆数组的指定索引的值，然后再把整个克隆数组扔进set方法更新state
    const _checkedItems = JSON.parse(JSON.stringify(checkedItems))
    _checkedItems[i] = e.target.checked;
    setCheckedItems(_checkedItems)
  }

  const redirectToDetail = (order: any) => {
    navigate(`/orders/${order.orderNo}`);
  }

  const renderPayStatus = (order: any) => {
    const { status } = order;
    const statusText = ORDER_STATUS_MAP[status];
    if (statusText) {
      return statusText;
    }
  }

  const getPayStatusTheme = (order: any) => {
    const { status } = order
    let color: number[] = []

    switch (status) {
      case 'FAILED':
      case 'INVALID':
      case 'CANCELLED':
        color = [139, 139, 139]
        break;
      case 'PENDING':
      case 'PROCESSING':
      case 'CANCELLING':
        color = [255, 103, 112]
        break;
      case 'SUCCESS':
      case 'USED':
        color = [89, 207, 213]
        break;
        
      default:
        color = [139, 139, 139]
        break;
    }

    return {
      backgroundColor: `rgba(${color.toString()},0.1)`,
      color: `rgb(${color.toString()})`,
    }
  }

  const handleExportOrders = () => {
    console.log(checkedItems)
    if (!checkedItems.find(item => item)) {
      onOpen()
      return
    }
  }

  const getTargetSort = (target: string): number => {
    if (!_filterState.sort) return 0
    const [t, s] = _filterState.sort.split('_')
    if (t !== target) return 0
    return s === 'asc' ? 1 : -1
  }

  return <div className="order-list-table">
    <div className="order-list-table-top">
      <div className="total">
        {getI18NText('共有_笔订单', { interpolation: { total } })}
      </div>
      <div className="btn-group">
        <Button className="btn warning" onClick={handleExportOrders}>{getI18NText('导出订单')}</Button>
      </div>
    </div>

    <div className="order-list-table-content">
      <div className="order-list-table-header">
        <div className="header-cell" style={{flexBasis: 64}}>
          <Checkbox
           className='order-list-table-checkbox' 
           isChecked={allChecked}
           isIndeterminate={isIndeterminate}
           onChange={(e) => setCheckedItems(new Array(data.length).fill(allChecked ? false : true))}
          />
        </div>
        <div className="header-cell" style={{flexBasis: 177}}>{getI18NText('订单号')}</div>
        <div className="header-cell" style={{flexBasis: 294}}>{getI18NText('产品名称')}</div>
        <div className="header-cell" style={{flexBasis: 294}}>
          <TableSort title={getI18NText('预定提交时间')} sortType={'createdtime'} sortBy={getTargetSort('createdtime')} getSortByOrderType={handleSortByOrderType} />
        </div>
        <div className="header-cell" style={{flexBasis: 144}}>
          <TableSort title={getI18NText('预定出行时间')} sortType={'productday'} sortBy={getTargetSort('productday')} getSortByOrderType={handleSortByOrderType} />
        </div>
        <div className="header-cell" style={{flexBasis: 100}}>
          <TableSort title={getI18NText('订单总价')} sortType={'agencytotalprice'} sortBy={getTargetSort('agencytotalprice')} getSortByOrderType={handleSortByOrderType} />
        </div>
        <div className="header-cell" style={{flexBasis: 100, flexGrow: 1, flexDirection: 'column'}}>
          <div>{getI18NText('支付方式')}</div>
          <div>{getI18NText('订单状态')}</div>
        </div>
        <div className="header-cell" style={{flexBasis: 120, flexGrow: 1, flexDirection: 'column'}}>
          <div>{getI18NText('联系人')}</div>
          <div>{getI18NText('手机')}</div>
        </div>
      </div>
      <div className="order-list-table-body">
        {
          data.map((row: any, i) => <div className="row">
            <div className="cell sep" style={{flexBasis: 64}}>
              <Checkbox 
              className='order-list-table-checkbox' 
              isChecked={checkedItems[i]}
              onChange={(e) => onChangeCheckState(i,e)}
              />
            </div>
            <div
              className="cell sep order-no"
              style={{flexBasis: 177}}
              onClick={() => redirectToDetail(row)}
            >
              {row.orderNo}
            </div>
            <div className="cell sep order-name" 
              style={{flexBasis: 294, flexDirection: 'column'}}
              onClick={() => redirectToDetail(row)}
            >
              <div className="product-name">{row.product.name.slice(0, 16)}{row.product.name.length > 16 ? '...' : ''}</div>
              <div className="product-info">{getI18NText('订单共有_样产品', { interpolation: { count: row.product.count } })}</div>
            </div>
            <div className="cell" style={{flexBasis: 294}}>
              <div style={{flexBasis: 100, textAlign: 'center'}}>
                {row.submitTime}
              </div>
            </div>
            <div className="cell" style={{flexBasis: 144}}>{row.travelDate}</div>
            <div className="cell" style={{flexBasis: 100}}>
              <span className="unit">¥</span>
              {row.totalAmount}
            </div>
            <div className="cell" style={{flexBasis: 100, flexGrow: 1, flexDirection: 'column'}}>
              <div>{row.payMethod}</div>
              <div className="status" style={getPayStatusTheme(row)}>
                {renderPayStatus(row)}
              </div>
            </div>
            <div className="cell" style={{flexBasis: 120, flexGrow: 1, flexDirection: 'column', wordBreak: 'break-all', textAlign: 'center'}}>
              <div>{row.contact.surname.slice(0, 7)}{row.contact.surname.length > 7 ? '...' : ''}</div>
              <div>{row.contact.name.slice(0, 7)}{row.contact.name.length > 7 ? '...' : ''}</div>
              <div>{row.contact.phone}</div>
            </div>
          </div>)
        }
      </div>
      <div className="order-list-table-pagination">
        <Pagination total={total} current={current} onChange={onPageChange}/>
      </div>
    </div>

    <Modal
          id="sidebar-error-modal"
          onClose={onClose}
          isOpen={isOpen}
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalBody paddingTop="8" textAlign="center">
              <WarningIcon textColor="#788DA5" height="9" width="9" />
              <Text fontSize="21px" textColor="#002C5E" marginTop="2" fontWeight="700">{getI18NText("未勾选订单")}</Text>
              <Text fontSize="14px" textColor="#333333" marginTop="4">{getI18NText("请选择您要的订单号，确认后才可导出")}</Text>
              <Button className="warn-btn" onClick={onClose}>{getI18NText("确认取消")}</Button>
            </ModalBody>
          </ModalContent>
        </Modal>
  </div>;
};

export default OrderListTable;
