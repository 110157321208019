import React from 'react';
import './MarkedHtml.scss';
import { marked } from 'marked';

const MarkedHtml: React.FC<MarkedHtmlProps> = ({ html }) => {

    return <div className="marked-html" dangerouslySetInnerHTML={{ __html: marked.parse(html.replace(/(?<!\n)\n(?!\n)/g, '\n\n')) }} ></div>;
};

export default MarkedHtml;
