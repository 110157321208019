import React, { useState } from 'react';
import './OrderDetailProductItem.scss';
import '../select/select.scss'

import { useI18NText } from '~/i18n/i18n';
import { ORDER_STATUS_MAP } from '~/utils/orderStatus';

const OrderDetailProductItem: React.FC<OrderTravelInfoBoxProps> = (props) => {
  // i18n国际化纯文本
  const { getI18NText } = useI18NText({ prefix: 'component.' })

  const {
    notices,
    orderStatus,
    item
  } = props;

  console.log(item)

  const getOrderStatusClassName = () => {
    if (!orderStatus) return '';
    if (orderStatus === getI18NText('待确认')) return 'tbc';
    if (orderStatus === getI18NText('确认中')) return 'confirmed';
    return '';
  };

  const getOrderStatusText = () => {
    if (!orderStatus) return '';
    const statusText = ORDER_STATUS_MAP[orderStatus];
    if (statusText) {
      return statusText;
    }
  }

  return <div className="order-travel-info-box">
    {!item?.orderNo ? null :
      <div className="order-travel-info-box-order">
        <div className="order-no">
          <span className="label">{getI18NText('订单号')}</span>
          <span className="value">{item.orderNo}</span>
        </div>
        <div className={[
          'order-status',
          getOrderStatusClassName(),
        ].filter(c => !!c).join(' ')}>
          {getOrderStatusText()}
        </div>
      </div>}
    <div className="order-travel-info-box-product">
      <div className="order-travel-info-box-product-image">
        <img src={item?.product?.thumbnail} />
      </div>
      <div className="order-travel-info-box-product-info-wrapper">
        <div className="order-travel-info-box-product-info">
          <div className="order-travel-info-box-product-title">
            {item?.product?.title}
          </div>
          <div className="order-travel-info-box-product-notice-list">
            {notices?.map((d, i) => <div key={i} className="order-travel-info-box-product-notice-item">
              {d}
            </div>)}
          </div>
        </div>
      </div>
    </div>
  </div>;
};

export default OrderDetailProductItem;
