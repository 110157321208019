import React, { useEffect, useState } from 'react';
import { map } from 'lodash';
import { Helmet } from 'react-helmet';
import { TourBaseUrl } from '~/constants';
import SearchBox from '~/components/search/SearchBox';
import ProductList from '~/components/product/ProductList';
import Cart from '~/components/cart/Cart';
import banner from '~/assets/images/banner.png';
import Container from '~/layouts/components/Container';
import { post } from '~/utils/request';
import { getValidShoppingCart } from '~/services/shoppingCart';

import './Home.scss';

import { useI18NText } from '~/i18n/i18n';
import { getSortOption, ProductSort } from '~/utils/product-sort.util';
import qs from 'qs';

// const sampleProducts = [
//   {
//     title: '香港迪士尼乐园',
//     category: '景点门票 & 表演',
//     price: 418,
//   },
//   {
//     title: '香港迪士尼乐园',
//     category: '景点门票 & 表演',
//     price: 1418,
//   },
//   {
//     title: '香港迪士尼乐园',
//     category: '景点门票 & 表演',
//     price: 418,
//   },
//   {
//     title: '香港迪士尼乐园',
//     category: '景点门票 & 表演',
//     price: 418,
//   },
//   {
//     title: '香港迪士尼乐园',
//     category: '景点门票 & 表演',
//     price: 418,
//   },
//   {
//     title: '香港迪士尼乐园',
//     category: '景点门票 & 表演',
//     price: 418,
//   },
// ];

const Home = () => {
  // i18n国际化纯文本
  const { getI18NText } = useI18NText({ prefix: 'home.' });

  const { sortBy, sort } = getSortOption(ProductSort.ScoreDesc);
  const empty: any[] = [];
  const [productList1, setProductList1] = useState(empty);
  const [productList1Loadend, setProductList1Loadend] = useState(false);
  const [productList2, setProductList2] = useState(empty);
  const [productList2Loadend, setProductList2Loadend] = useState(false);
  const [shoppingCartCount, setShoppingCartCount] = useState(0);

  const loadList = async (params: any) => {
    const list = await post(`${TourBaseUrl}/api/products`, params, {
      paramsSerializer: options => qs.stringify(options, { arrayFormat: 'repeat' })
    });
    return map(list.data.docs, (row) => ({
      title: row.title,
      id: row.id,
      price: row.displayPrice,
      category: row.categoryName,
      thumbnail: row.thumbnail?.url,
    }));
  };

  const loadList1 = async () => {
    const list = await loadList({
      page: 1,
      size: 12,
      sortType: [sortBy],
      price: {
        sort: sort,
      },
    });
    setProductList1(list);
    setProductList1Loadend(true);
  };

  const loadList2 = async () => {
    const list = await loadList({
      page: 2,
      size: 12,
      sortType: [sortBy],
      price: {
        sort: sort,
      },
      categories: [],
    });
    setProductList2(list);
    setProductList2Loadend(true);
  };

  const validShoppingCart = async () => {
    const valid = await getValidShoppingCart();
    setShoppingCartCount(valid);
  };

  useEffect(() => {
    loadList1();
    loadList2();
    validShoppingCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>当地玩乐 | 多会儿旅行</title>
      </Helmet>
      <div className="home-page">
        <div className="banner-wrapper">
          <div className="search-wrapper">
            <div className="search-title">{getI18NText('想要找')}</div>
            <SearchBox />
          </div>
          <img className="banner" src={banner} alt="banner" />
        </div>

        <Container>
          <ProductList
            title={getI18NText('最近热门产品')}
            products={productList1}
            scrollable
            noSort
            hideSearch
            loadend={productList1Loadend}
          />
          <ProductList
            title={getI18NText('近期推荐产品')}
            products={productList2}
            scrollable
            noSort
            hideSearch
            loadend={productList2Loadend}
          />
        </Container>

        <Cart productCount={shoppingCartCount} />
      </div>
    </>
  );
};

export default Home;
