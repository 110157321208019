import { chakra } from "@chakra-ui/react";

export default function IconLocation(props: { size: number }) {
  return (
    <chakra.svg
      w={props.size}
      h={props.size}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 5.14277V17.9668L7.065 15.7968L13.065 18.7968L18 16.6798V3.85677L19.303 3.29877C19.3791 3.26615 19.4621 3.25294 19.5445 3.26031C19.627 3.26769 19.7063 3.29542 19.7754 3.34101C19.8445 3.38661 19.9012 3.44865 19.9404 3.52157C19.9796 3.59448 20.0001 3.67599 20 3.75877V17.9998L13 20.9998L7 17.9998L0.697 20.7008C0.620914 20.7334 0.537919 20.7466 0.455466 20.7392C0.373012 20.7319 0.293679 20.7041 0.224587 20.6585C0.155495 20.6129 0.0988058 20.5509 0.059607 20.478C0.0204082 20.4051 -7.34563e-05 20.3236 1.9795e-07 20.2408V5.99977L2 5.14277ZM14.243 10.2428L10 14.4848L5.757 10.2428C4.91799 9.40361 4.34664 8.33451 4.11521 7.17066C3.88379 6.0068 4.00266 4.80045 4.45682 3.70415C4.91098 2.60786 5.68001 1.67084 6.66668 1.0116C7.65336 0.352356 8.81336 0.000488281 10 0.000488281C11.1866 0.000488281 12.3466 0.352356 13.3333 1.0116C14.32 1.67084 15.089 2.60786 15.5432 3.70415C15.9973 4.80045 16.1162 6.0068 15.8848 7.17066C15.6534 8.33451 15.082 9.40361 14.243 10.2428ZM10 11.6568L12.828 8.82777C13.3875 8.26841 13.7686 7.55568 13.9231 6.77972C14.0775 6.00376 13.9983 5.19944 13.6956 4.46846C13.3929 3.73749 12.8802 3.1127 12.2224 2.67312C11.5646 2.23354 10.7912 1.99892 10 1.99892C9.20882 1.99892 8.43542 2.23354 7.77759 2.67312C7.11977 3.1127 6.60708 3.73749 6.30437 4.46846C6.00165 5.19944 5.92251 6.00376 6.07695 6.77972C6.23139 7.55568 6.61247 8.26841 7.172 8.82777L10 11.6568Z"
        fill="currentColor"
      />
    </chakra.svg>
  );
}
