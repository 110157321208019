import React, {useEffect, useState} from 'react';
import './OrderPayNoticeBox.scss';
import ClockLargeIcon from '../../assets/icons/ClockLargeIcon.svg';
import dayjs from 'dayjs';

import { useI18NText } from '~/i18n/i18n';

const OrderPayNoticeBox: React.FC<OrderPayNoticeBoxProps> = (props) => {
  // i18n国际化纯文本
  const { getI18NText } = useI18NText({ prefix: 'component.' })

  const {timeLeft, onChange} = props;

  const [t, setT] = useState<number>(timeLeft);

  useEffect(() => {
    setT(timeLeft)
    // decrement by 1 second
    const handle = setInterval(() => {
      setT(() => {
        const r = timeLeft - 1;
        onChange?.(r);
        return r;
      });
    }, 1000);

    // cleanup
    return () => clearInterval(handle);
  }, [timeLeft]);

  const getTime = () => {
    if (t <= 0) {
      return '00:00:00';
    }
    const m = dayjs().startOf('day').add(t, 'seconds');
    return m.format('HH:mm:ss');
  };

  return <div className="order-pay-notice-box">
    <div className="order-pay-notice-box-top">
      <img className="icon" src={ClockLargeIcon}/>
      <span className="text">{getI18NText('订单提交成功')}</span>
    </div>
    <div className="order-pay-notice-box-bottom">
      {getI18NText('为避免订单被取消，请在')}
      <span className="time">{getTime()}</span>
      {getI18NText('内付款')}
    </div>
  </div>;
};

export default OrderPayNoticeBox;
