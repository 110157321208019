import React, { useState, useEffect } from 'react';
import * as qs from 'qs';
import dayjs from 'dayjs';
import { map } from 'lodash';

import { getI18NTextOnce, useI18NText } from '~/i18n/i18n';
import { TourBaseUrl } from '~/constants';
import { get } from '~/utils/request';

import OrderPaySuccessBox from '../../components/order/OrderPaySuccessBox';
import Container from '../../layouts/components/Container';
import OrderTitle from '../../components/order/OrderTitle';
import OrderPayActionBox from '../../components/order/OrderPayActionBox';
import OrderTravelInfoBox from '../../components/order/OrderTravelInfoBox';
import OrderContactInfoBox from '../../components/order/OrderContactInfoBox';

import './OrderPaid.scss';
import { Helmet } from 'react-helmet';

const OrderPaid = () => {
  // i18n国际化纯文本
  const { getI18NText } = useI18NText({ prefix: 'orders.paid.' });
  const queryParams = qs.parse(window.location.search.slice(1));

  const [bookingId, setBookingId] = useState(queryParams?.booking || '');

  const [amount, setAmount] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [payAmount, setPayAmount] = useState(0);

  const [paidMethod, setPaidMethod] = useState('微信');
  const [paidTime, setPaidTime] = useState('2021/11/10 18:01:21');
  const [paidStatus, setPaidStatus] = useState('已付款');
  const emptyProductItems: OrderProductItemProps[] = [];
  const [productItems, setProductItems] = useState(emptyProductItems);

  const [traveller, setTraveller] = useState<Contact>({
    firstName: 'DaMing',
    lastName: 'Wang',
    email: 'adawang@qq.com',
    phone: '12345678901',
    nationality: '中国',
  });

  const [contact, setContact] = useState<Contact>({
    firstName: 'DaTian2',
    lastName: 'Lin2',
    email: 'lin@gmail.com',
    phone: '12345678901',
  });

  const [orderNo, setOrderNo] = useState('14525465729');
  const [orderStatus, setOrderStatus] = useState('待确认');

  const loadOrderDetail = async () => {
    const response = await get(`${TourBaseUrl}/api/orderDetail?bookingId=${bookingId}`);
    const orderDetail = response.data.bookings[0];
    const { name, phone, email } = orderDetail.tourCustomer.contact;
    setContact({
      ...name,
      phone,
      email,
    });
    setBookingId(orderDetail.bookingId);
    setPayAmount(orderDetail.price);
    setAmount(orderDetail.price);
    setProductItems(
      map(orderDetail.tourItems, (item: any) => {
        return {
          orderNo: orderDetail.bookingId,
          title: item.product.title,
          subtitle: item.package?.title,
          quantity: map(item.skus, (row: any) => [row.name, row.count].join(' x ')).join(' / '),
          date: item.date,
          price: item.total,
          product: item.product,
          traveller: {
            ...item.traveller.name,
            phone: item.traveller.phone,
            email: item.traveller.email,
            nationality: item.traveller.nationality,
          },
        };
      }),
    );
  };

  useEffect(() => {
    if (bookingId) {
      loadOrderDetail();
    }
  }, ['']);
  const renderItemNotices = (item: any) => {
    const day = dayjs(item.date, 'YYYY-MM-DD').format(
      `YYYY${getI18NTextOnce('component.年')}MM${getI18NTextOnce(
        'component.月',
      )}DD${getI18NTextOnce('component.日')}`,
    );
    return [`方案类型: ${item.subtitle}`, `产品数量: ${item.quantity}`, `出游日期: ${day}`];
  };

  return (
    <>
      <Helmet>
        <title>预定支付 - 当地玩乐｜多会儿旅行</title>
      </Helmet>
      <div className="order-paid">
        <div className="order-paid-top">
          <OrderPaySuccessBox />
        </div>

        <Container>
          <div className="order-paid-container">
            <div className="order-paid-content">
              <OrderTitle title={getI18NText('订单信息')} />
              {productItems.map((item, i) => (
                <OrderTravelInfoBox
                  key={i}
                  item={item}
                  readonly
                  notices={renderItemNotices(item)}
                />
              ))}
              <OrderContactInfoBox contact={contact} readonly />
            </div>

            <div className="order-paid-sidebar">
              <OrderPayActionBox
                amount={amount}
                discount={discount}
                payAmount={payAmount}
                paid
                paidMethod={paidMethod}
                paidTime={paidTime}
                paidStatus={paidStatus}
              />
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default OrderPaid;
