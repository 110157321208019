import './Login.scss'
import React, { useState } from "react";
import background from "./background.png";
import forgetPassword from "./forgetPassword.png";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Checkbox,
  Input,
  InputGroup,
  FormControl,
  FormErrorMessage,
  InputRightElement,
  Link,
} from '@chakra-ui/react'
import { Navigate, useNavigate } from 'react-router-dom'

import useAuthingClient from '~/hooks/useAuthingClient'
import { toast } from 'react-toastify'
import { useForm } from "react-hook-form";
import PhoneIcon from "../../assets/icons/PhoneIcon.svg";
import EyeCrossed from "../../assets/icons/EyeCrossed.svg";
import Eye from "../../assets/icons/Eye.svg";

import { useI18NText } from "~/i18n/i18n";

const Login = () => {
  // i18n国际化纯文本
  const { getI18NText } = useI18NText({ prefix: "login." });
  const navigate = useNavigate();
  const [hidePassword, setHidePassword] = useState(true);
  const [isOpen, toggleModle] = useState(false);
  const {
    trigger,
    setValue,
    register,
    getValues,
    setError,
    formState: { errors, isSubmitting },
  } = useForm()
  const { login, logout, checkIfLoggedIn, verifyUser } = useAuthingClient()

  //判断是否可以登录
  const onClickLogin = async () => {
    const { phoneNumber: username, passWord: password } = getValues()

    try {
      await login({
        username,
        password,
      })
      // const token = await getIdToken();
      const isValidUser = await verifyUser()
      if (!isValidUser) {
        await logout()
        // eslint-disable-next-line no-throw-literal
        throw { code: 999, message: getI18NText('用户无法登入，请洽客服') }
      }

      document.title = '多会儿旅行'
      navigate('/')
    } catch (error) {
      // const { message } = error as { code: number; message: string };
      setError('username', {
        message: '',
      })
      setError('password', {
        message: '',
      })

      toast({
        position: 'top',
        status: 'error',
        title: getI18NText('登入发生错误，请洽客服'),
      })
    }
  }

  if (checkIfLoggedIn()) {
    return <Navigate to="/" replace={true} />
  }

  return (
    <div className="login-page">
      <Modal
        id="login-page-foget-password-modal"
        isOpen={isOpen}
        onClose={() => toggleModle}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <div
              className="title-icon"
              style={{
                backgroundImage: `url(${forgetPassword})`,
              }}
            ></div>
            <div className="title-text">{getI18NText('忘记密码')}</div>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {getI18NText("请联系客服电话 _ ，将有专人服务", {
              interpolation: { phone: "+86 13810248624" },
            })}
          </ModalBody>
          <ModalFooter>
            <Button
              fontSize="sm"
              fontWeight="normal"
              variant="outline"
              onClick={() => toggleModle(!isOpen)}
              color="#002C5E"
              borderColor="#002C5E"
            >
              {getI18NText("取消返回")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <div
        className="background-wrapper"
        style={{
          backgroundImage: `url(${background})`,
        }}
      ></div>
      <div className="login-form-wrapper">
        <div className="login-form-intro">
          {getI18NText("让你简单地")}
          <br />
          {getI18NText("获取更优质的资源")}
        </div>
        <div className="login-form">
          <div className="login-form-content">
            <div className="login-form-title">{getI18NText("登入")}</div>
            <form>
              <InputGroup
                className={[
                  "login-form-input-group",
                  errors.phoneNumber ? "hasError" : null,
                ].join(" ")}
              >
                <InputRightElement
                  className="login-form-input-icon"
                  children={<img src={PhoneIcon} alt="" />}
                />
                <FormControl isInvalid={errors.phoneNumber}>
                  <Input
                    className="login-form-input"
                    placeholder={getI18NText("请输入手机号码")}
                    {...register("phoneNumber", {
                      required: true,
                      pattern: {
                        value: /^1[3-9]\d{9}$/,
                        message: getI18NText("请输入正确的手机号码"),
                      },
                    })}
                    onChange={(e) => {
                      setValue("phoneNumber", e.target.value, {
                        shouldValidate: true,
                        shouldDirty: true,
                        shouldTouch: false,
                      });
                    }}
                  />

                  <FormErrorMessage>
                    {errors.phoneNumber?.type === "required" &&
                      getI18NText("手机号不能为空")}
                    {errors.phoneNumber?.type === "pattern" &&
                      errors.phoneNumber?.message}
                  </FormErrorMessage>
                </FormControl>
              </InputGroup>
              <InputGroup
                className={[
                  "login-form-input-group",
                  errors.passWord ? "hasError" : null,
                ].join(" ")}
              >
                <InputRightElement
                  className="login-form-input-icon"
                  children={
                    <img
                      src={hidePassword ? EyeCrossed : Eye}
                      onClick={() => setHidePassword(!hidePassword)}
                      alt=""
                    />
                  }
                />
                <FormControl isInvalid={errors.passWord}>
                  <Input
                    type={hidePassword ? "password" : undefined}
                    className="login-form-input"
                    placeholder={getI18NText("请输入密码")}
                    {...register("passWord", {
                      required: true,
                    })}
                    onChange={(e) => {
                      setValue("passWord", e.target.value, {
                        shouldValidate: true,
                        shouldDirty: true,
                        shouldTouch: false,
                      });
                    }}
                  />
                  <FormErrorMessage>
                    {errors.passWord?.type === "required" &&
                      getI18NText("密码不能为空")}
                    {errors.passWord?.type === "pattern" &&
                      errors.passWord?.message}
                  </FormErrorMessage>
                </FormControl>
              </InputGroup>

              <Button
                type="button"
                isLoading={isSubmitting}
                className="login-form-submit"
                onClick={() =>
                  trigger(["phoneNumber", "passWord"]).then(() =>
                    onClickLogin()
                  )
                }
              >
                {getI18NText("登入")}
              </Button>
            </form>
            <div className="login-form-actions">
              <Checkbox className="login-form-remember">
                {getI18NText("记住账号")}
              </Checkbox>
              <Link
                onClick={() => toggleModle(!isOpen)}
                className="login-form-forgot-password"
              >
                {getI18NText("忘记密码？")}
              </Link>
            </div>
            <Button className="login-form-register">
              {getI18NText("还没注册嘛？立即注册")}
            </Button>
            <div className="login-form-service-term">
              {getI18NText("成为旅点用户，代表同意旅点服务条款")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
