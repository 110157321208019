import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Box,
  useBoolean,
  VStack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import NavMenuButton from "~/components/nav/NavMenuButton";
import IconOrder from "~/components/icons/IconOrder";
import IconHotel from "~/components/icons/IconHotel";
import IconTicket from "~/components/icons/IconTicket";

const NavMenuOrder = () => {
  const [visible, setVisible] = useBoolean();
  const navigate = useNavigate();

  const handleClickHotelOrder = () => {
    window.location.href = "http://b2b.tripintl.com/orderlist";
  };

  const handleClickLocalExpOrder = () => {
    setVisible.off();
    navigate("/orders");
  };

  return (
    <Popover
      matchWidth
      isOpen={visible}
      onOpen={setVisible.on}
      onClose={setVisible.off}
      closeOnBlur
      isLazy
      gutter={2}
      placement="bottom-start"
    >
      <PopoverTrigger>
        <Box>
          <NavMenuButton icon={<IconOrder size={5} />} />
        </Box>
      </PopoverTrigger>
      <PopoverContent
        _focus={{ boxShadow: "lg", outline: "none" }}
        border="none"
        w="full"
        rounded="3xl"
      >
        <PopoverBody px="10" py="8">
          <VStack w="36" align="start" spacing="8">
            <NavMenuButton
              onClick={handleClickHotelOrder}
              text="酒店订单"
              icon={<IconHotel size={5} />}
            />
            <NavMenuButton
              onClick={handleClickLocalExpOrder}
              text="当地玩乐订单"
              icon={<IconTicket size={5} />}
            />
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default NavMenuOrder;
