export enum PriorityOption {
  HOT_PRIORITY = 'HOT_PRIORITY',
  LOW_PRICE_PRIORITY = 'LOW_PRICE_PRIORITY',
  HIGH_PRICE_PRIORITY = 'HIGH_PRICE_PRIORITY',
  HIGH_STAR_PRIORITY = 'HIGH_STAR_PRIORITY',
  HIGH_COMMENT_PRIORITY = 'HIGH_COMMENT_PRIORITY',
}

export enum TMIBookingStatus {
  PENDING = 'PENDING',        // 待付款
  PROCESSING = 'PROCESSING',   // 待确认
  IN_BOOKING = 'IN_BOOKING',   // 待确认
  SUCCESS = 'SUCCESS',   // 已确认
  FAILED = 'FAILED', // 预定失败
  CANCELLED = 'CANCELLED', // 已取消
  CANCELLING = 'CANCELLING', // 取消中
  INVALID = 'INVALID', // 已失效 
  USED = 'USED', // 已确认
}
export enum TMIBookingStatusText {
  PENDING = '待付款',        // 待付款
  PROCESSING = '待确认',   // 待确认
  IN_BOOKING = '待确认',   // 待确认
  SUCCESS = '已确认',   // 已确认
  FAILED = '预定失败', // 预定失败
  CANCELLED = '已取消', // 已取消
  CANCELLING = '待退款', // 待退款
  INVALID = '已失效', // 已失效 
  USED = '已确认', // 已确认
}
export enum PaymentTypeStatus {
  Wechat = 'Wechat',   // 微信
  AliPay = 'AliPay',   // 支付宝
  CreditCard = 'CreditCard', // 信用卡
  Balance = 'Balance'  //余额
}

export enum OrderSortStatus {
  NONE = '',
  RECENT = 'createdtime_desc',
  FARTHEST = 'createdtime_asc',
  TRAVEL_RECENT = 'checkindate_desc',
  TRAVEL_FARTHEST = 'checkindate_asc',
  LARGE_PRICE = 'agencytotalprice_desc',
}

export enum OrderStatus {
  NONE = '',
  ALL = 'all',
  UNPAID = 'PENDING',
  FAIL = 'FAILED',
  CONFIRMED = 'SUCCESS',
  CANCEL = 'CANCELLED',
  INVALID = 'INVALID',
  COMPLETE = 'USED',
}

export enum PaymentStatus {
  NONE = '',
  // 已付款
  PAID = 'paid',
  // 退款中
  REFUNDING = 'refunding',
  // 已退款
  REFUNDED = 'refunded',
  // 待付款
  UNPAID = 'unpaid',
}

export const HitPerPageBase = 5;

//============ common =============\\
export enum FetchingState {
  INIT = 'init',
  LOADING = 'loading',
  SUCCESS = 'success',
  FAILED = 'failed',
  COMPLETE = 'complete',
}

export enum Locale {
  EN_US = 'en',
  ZH_CN = 'zh_CN',
}

export enum Lang {
  zh_CN = 'zh-CN',
  en_US = 'en-US',
}

export enum FetchError {
  FETCH_HOTELS_ERROR = '获取酒店失败请重试',
}

export type LocaleString = Record<Locale, string>;

export enum Policies {
  general = 'POLICY_GENERAL',
  payment = 'POLICY_PAYMENT',
  checkInCheckOut = 'POLICY_CHECK_IN_CHECK_OUT',
  pets = 'POLICY_PETS',
  child = 'POLICY_CHILD',
  other = 'POLICY_OTHER',
}

export enum Facilities {
  hotel = 'FACILITY_HOTEL',
  room = 'FACILITY_ROOM',
}

//============= error handling =======\\
export enum HttpApiErrorCode {
  INVALID_BODY = 'ERR_TRIPB2B_INVALID_BODY',
  MISSING_FIELDS = 'ERR_TRIPB2B_MISSING_FIELDS',
  NO_SUCH_RATE = 'ERR_TRIPB2B_NO_SUCH_RATE',
  RATE_VALIDATION_FAILED = 'ERR_TRIPB2B_RATE_VALIDATION_FAILED',
  GATEWAY_ERROR = 'ERR_TRIPB2B_GATEWAY_ERROR',
  UNEXPECTED_ERROR = 'ERR_TRIPB2B_UNEXPECTED_ERROR',
}

//============= algolia ==============\\
// algolia name field locale name
export enum LocaleName {
  EN_US = 'name_en',
  ZH_CN = 'name_zh_CN',
}

// algolia address field locale name
export enum LocaleAddress {
  EN_US = 'address_en',
  ZH_CN = 'address_zh_CN',
}

export type AlgoliaResponse<T> = {
  hits: T[];
  page: number;
  hitsPerPage: number;
  nbHits: number;
  nbPages: number;
};

//============== sanity =============\\
export enum Suppliers {
  DIDA = 'DIDA',
  RAKUTEN_JAPAN = 'RAKUTEN_JAPAN',
  RAKUTEN_CHINA = 'RAKUTEN_CHINA',
  MEITUAN = 'MEITUAN',
  CTRIP_DIST = 'CTRIP_DIST',
}

export type SanityImage = {
  _key: string;
  asset: {
    _ref: string;
  };
  caption?: string;
};

export type FullName = {
  firstName: string;
  lastName: string;
};

export type ContactInfo = {
  name: FullName;
  phone: string;
  email: string;
};

export type ContactUserInfo = {
  name: string;
  phone: string;
  email: string;
};

export type Bed = {
  seq: number;
  code: string;
  desc: string;
  count: number;
};

export type Meal = {
  ID: number;
  Name_CN: string;
  Name: string;
};

export type Group = {
  adultCount: number;
  children?: Array<number>;
};

export type Facility = { type: Facilities; value: Record<Locale, string> };

export type Policy = { type: Policies; value: Record<Locale, string> };

export type HotelDoc = {
  _id: string;
  _createdAt: Date;
  _updatedAt: Date;
  tripId: string;
  address: LocaleString;
  attractions: LocaleString;
  description: LocaleString;
  facilities: Array<Facility>;
  images: Array<SanityImage>;
  location: {
    lat: number;
    lng: number;
  };
  name: LocaleString;
  policies: Array<Policy>;
  rooms: Array<Room>;
  star: number;
  supplierId: Partial<Record<Suppliers, string>>;
};

export type Room = {
  _key: string;
  roomId: string;
  name: Record<Locale, string>;
  images: Array<SanityImage>;
};

//========= aws api gateway =========

// export type Room = {
//   rateId: string;
//   hotelId: string;
//   range: string;
//   price: number;
//   currency: Currency;
//   fees: number;
//   feesCurrency: Currency;
//   sessionCode: string;
//   supplierRateId: string;
//   checkIn: Date;
//   checkOut: Date;
//   bed: string; // json string
//   meal: string; // json string
//   mealCount: Array<number>;
//   dailyPrice: Array<number>;
//   hasBreakfast: boolean;
//   group: Group;
//   occupancy: number;
//   cancelPolicies: {
//     endAt: Date;
//     refund: number;
//     percent: number;
//     currency: Currency;
//   }[];
//   expiration: number;
//   createdAt: Date;
//   updatedAt: Date;
//   roomName: string;
//   supplier: Suppliers;
// };

export type CancelPolicy = {
  endAt: string; // ISO time string
  fromTime: number | string;
  refund: number;
  percent: number;
  currency: Currency;
};

export type Rate = {
  rateId: string;
  hotelId: string;
  hotel: {
    name: Record<Lang, string>;
    address: Record<Lang, string>;
  };
  range: string;
  price: number;
  currency: Currency;
  fees: number;
  feesCurrency: Currency;
  checkIn: string; // ISO time string
  checkOut: string; // ISO time string
  bed: string; // json string
  meal: string; // json string
  mealCount: Array<number>;
  dailyPrice: Array<number>;
  hasBreakfast: boolean;
  group: Group;
  occupancy: number;
  cancelPolicies: Array<CancelPolicy>;
  roomName: Record<Lang, string>;
  roomId: number;
  priceWithProfit?: number;
  dailyPriceWithProfit?: number[];
};

export enum BookingStatus {
  PENDING = 'PENDING', // 用户提交订单后，尚未付款
  PROCESSING = 'PROCESSING', // 用户付款后，内部处理中
  IN_BOOKING = 'IN_BOOKING', // 将订单提交给供应商，等待供应商确认订单
  SUCCESS = 'SUCCESS', // 供应商确定订单并接受
  FAILED = 'FAILED', // 供应商拒绝订单
  CANCELLED = 'CANCELLED', // 订单已经成功取消
  CANCELLING = 'CANCELLING', // 订单发起取消，待确认中
  INVALID = 'INVALID', // 用户指定时间内未付款，订单失效
  USED = 'USED', // 订单用户已使用
}

export enum OrderType {
  NONE = '',
  ALL = 'all',
  // 待付款訂單
  UNPAID = 'PENDING',
  // 待确认訂單
  UNCONFIRMED = 'PROCESSING',
  // 預定失敗訂單
  FAIL = 'FAILED',
  // 已确认訂單
  CONFIRMED = 'confirmed',
  // 取消訂單
  CANCELED = 'CANCELLED',
  // 取消訂單
  CANCELLING = 'CANCELLING',
  // 失效訂單
  EXPIRED = 'INVALID',
  // 完成訂單
  COMPLETED = 'SUCCESS',
}

export type BookingRoom = { roomNum: number } & Rate;

export type BookingPayment = {
  transactionId?: string;
  // pay info
  payingAt?: string;
  paidAt?: string;
  paidAmount?: number;
  paymentType?: PaymentType;
  paymentTypeURL?: string;
  paymentStatus: PaymentStatus;
  paymentAppUrlMap: Record<PaymentType, string>;
  // cancel info
  cancellingAt?: string;
  cancelledByUser?: boolean;
  refundAt?: string;
  refundAmount?: number;
};

export type BookingListItem = {
  id: string;
  hoteladdress: string;
  hotelname: string;
  hoteladdressen: string;
  hotelnameen: string;
  paymentstatus: PaymentStatus;
  paymenttype: string;
  status: BookingStatus;
  guests: string[];
  checkindate: Date;
  checkoutdate: Date;
  createdtime: Date;
  agencytotalprice: number;
  checked: boolean;
  hotelroomtype: string;
  roomnum: number;
};

export type Booking = {
  bookingId: string;
  supplierId: string;
  currency: Currency;
  updatedAt?: Date;
  status: BookingStatus;
  createdAt?: Date;
  customer: {
    contactPerson: ContactInfo;
    guest: Array<FullName>;
    guestByRoom: Array<Array<FullName>>;
  };
  rates: Array<BookingRoom>;
  hotel: {
    name: Record<Lang, string>;
    address: Record<Lang, string>;
    staticDocId: string;
  };
  profitMargin: number;
  price: number;
  bookingPayment?: bookingPaymentProps;
  confirmationPdfUrl?: string;
};

export type bookingPaymentProps = {
  paymentAppUrlMap: paymentAppUrlMapProps;
  paymentStatus: string,
  invalidAt: any
}

export type paymentAppUrlMapProps = {
  Wechat: string;
}
export type BookingFromApi = {
  bookingId: string;
  supplierId: string;
  currency: Currency;
  updatedAt: string;
  status: BookingStatus;
  createdAt: string;
  customer: {
    contactPerson: ContactInfo;
    guest: Array<FullName>;
    guestByRoom: Array<Array<FullName>>;
  };
  rates: Array<BookingRoom>;
  hotel: {
    name: Record<Lang, string>;
    address: Record<Lang, string>;
    staticDocId: string;
  };
  profitMargin: number;
  price: number;
  payment: BookingPayment;
};

export enum PaymentType {
  Wechat = '微信',
  AliPay = '支付宝',
  Credit = '信用卡',
  Balance = '余额支付'
}

//======== websocket msg data type ========
export enum WebSocketState {
  NOT_CONNECT = -1,
  CONNECTING = 0,
  OPEN = 1,
  CLOSING = 2,
  CLOSED = 3,
  PAGE_CHANGING = 4,
  ERROR = 5,
}

export type WebSocketMsg = {
  action: 'hotelRates' | 'bookingStatus';
  data: {
    hotelId: string | null;
    rates: Rate[] | null;
  };
};

export type HotelSearchPriceResponse = {
  hotelId: string;
  hotelTripId: string;
  hotelName: Record<Locale, string>;
  score: number;
  hotelAddress: Record<Locale, string>;
  lowestPrice: number;
  commentCount: number;
  star: number;
  checkIn: string;
  checkOut: string;
  adults: number;
  children: string;
  createdAt: string;
  updatedAt: string;
  keyword: string;
  imageUrl: string;
};

export type ImageInfo = {
  url: string;
  caption: string;
};

export type RoomInfo = {
  roomId: string;
  name: Record<Locale, string>;
  images: ImageInfo[];
};

export type RoomsInfoList = {
  roomId: string;
  name: Record<Locale, string>;
  images: ImageInfo[];
  showMore: boolean;
  list: Rate[];
}

export type Hotel = {
  hotelId: string;
  address: Record<Locale, string>;
  city: Record<Locale, string>;
  country: Record<Locale, string>;
  attractions: Record<Locale, string>;
  description: Record<Locale, string>;
  facilities: Facility[];
  images: ImageInfo[];
  location: number[];
  name: Record<Locale, string>;
  policies: Policy[];
  rooms: RoomInfo[];
  star: number;
};

export type HotelList = {
  hotels: Array<HotelPrice>;
  totalPage: number;
  totalCount: number;
}

export type HotelPrice = {
  hotelId: string;
  address: Record<Locale, string>;
  city: Record<Locale, string>;
  country: Record<Locale, string>;
  attractions: Record<Locale, string>;
  description: Record<Locale, string>;
  facilities: Facility[];
  images: ImageInfo[];
  location: number[];
  name: Record<Locale, string>;
  policies: Policy[];
  rooms: RoomInfo[];
  star: number;
  lowestPrice: number;
};

export type WebSocketHotelSearchMsg = {
  action: 'hotels';
  data: {
    hotels: HotelSearchPriceResponse[];
    cursor: any;
  };
};

export type HotelsRequest = {
  keyword: string;
  cityCode: string;
  sortingWay: PriorityOption;
  priceRange?: {
    min?: number;
    max?: number;
  };
  stars?: number[];
  checkIn: string;
  checkOut: string;
  group: {
    adults: number;
    children: number[];
  };
  cursor: any;
};

export type HotelRatesRequest = {
  hotels: Array<string>;
  group: {
    adults: number;
    children: Array<number>;
  };
  checkIn: string;
  checkOut: string;
};

export type WeSocketSendObj = {
  action: string;
  data: HotelsRequest | HotelRatesRequest;
};

export enum LocationType {
  HOTEL = 'TRIP_LOCATION_HOTEL',
  CITY = 'TRIP_LOCATION_CITY',
  POI = 'TRIP_LOCATION_POI',
}

export type SearchSuggestion = {
  id: string;
  name: string;
  displayName: string;
  type: LocationType;
};

export type HotelSearchSuggestion = {
  city: {
    en: string;
    zh_CN: string;
  };
  country: {
    en: string;
    zh_CN: string;
  };
  hotelId: string;
  name: {
    en: string;
    zh_CN: string;
  }
}

export type User = {
  userId: string;
  userName: string;
  phone: string;
  email: string;
  password: string;
  initPassword: string;
  agencyId: string;
  agencyName: string;
  agencyAddress: string;
  shoppingCart: string;
  extends: string;
  createdAt: Date;
  updatedAt: Date;
};

export enum Currency {
  AED = 'AED',
  AFN = 'AFN',
  ALL = 'ALL',
  AMD = 'AMD',
  ANG = 'ANG',
  AOA = 'AOA',
  ARS = 'ARS',
  AUD = 'AUD',
  AWG = 'AWG',
  AZN = 'AZN',
  BAM = 'BAM',
  BBD = 'BBD',
  BDT = 'BDT',
  BGN = 'BGN',
  BHD = 'BHD',
  BIF = 'BIF',
  BMD = 'BMD',
  BND = 'BND',
  BOB = 'BOB',
  BOV = 'BOV',
  BRL = 'BRL',
  BSD = 'BSD',
  BTN = 'BTN',
  BWP = 'BWP',
  BYN = 'BYN',
  BZD = 'BZD',
  CAD = 'CAD',
  CDF = 'CDF',
  CHE = 'CHE',
  CHF = 'CHF',
  CHW = 'CHW',
  CLF = 'CLF',
  CLP = 'CLP',
  CNY = 'CNY',
  COP = 'COP',
  COU = 'COU',
  CRC = 'CRC',
  CUC = 'CUC',
  CUP = 'CUP',
  CVE = 'CVE',
  CZK = 'CZK',
  DJF = 'DJF',
  DKK = 'DKK',
  DOP = 'DOP',
  DZD = 'DZD',
  EGP = 'EGP',
  ERN = 'ERN',
  ETB = 'ETB',
  EUR = 'EUR',
  FJD = 'FJD',
  FKP = 'FKP',
  GBP = 'GBP',
  GEL = 'GEL',
  GHS = 'GHS',
  GIP = 'GIP',
  GMD = 'GMD',
  GNF = 'GNF',
  GTQ = 'GTQ',
  GYD = 'GYD',
  HKD = 'HKD',
  HNL = 'HNL',
  HRK = 'HRK',
  HTG = 'HTG',
  HUF = 'HUF',
  IDR = 'IDR',
  ILS = 'ILS',
  INR = 'INR',
  IQD = 'IQD',
  IRR = 'IRR',
  ISK = 'ISK',
  JMD = 'JMD',
  JOD = 'JOD',
  JPY = 'JPY',
  KES = 'KES',
  KGS = 'KGS',
  KHR = 'KHR',
  KMF = 'KMF',
  KPW = 'KPW',
  KRW = 'KRW',
  KWD = 'KWD',
  KYD = 'KYD',
  KZT = 'KZT',
  LAK = 'LAK',
  LBP = 'LBP',
  LKR = 'LKR',
  LRD = 'LRD',
  LSL = 'LSL',
  LYD = 'LYD',
  MAD = 'MAD',
  MDL = 'MDL',
  MGA = 'MGA',
  MKD = 'MKD',
  MMK = 'MMK',
  MNT = 'MNT',
  MOP = 'MOP',
  MRU = 'MRU',
  MUR = 'MUR',
  MVR = 'MVR',
  MWK = 'MWK',
  MXN = 'MXN',
  MXV = 'MXV',
  MYR = 'MYR',
  MZN = 'MZN',
  NAD = 'NAD',
  NGN = 'NGN',
  NIO = 'NIO',
  NOK = 'NOK',
  NPR = 'NPR',
  NZD = 'NZD',
  OMR = 'OMR',
  PAB = 'PAB',
  PEN = 'PEN',
  PGK = 'PGK',
  PHP = 'PHP',
  PKR = 'PKR',
  PLN = 'PLN',
  PYG = 'PYG',
  QAR = 'QAR',
  RON = 'RON',
  RSD = 'RSD',
  RUB = 'RUB',
  RWF = 'RWF',
  SAR = 'SAR',
  SBD = 'SBD',
  SCR = 'SCR',
  SDG = 'SDG',
  SEK = 'SEK',
  SGD = 'SGD',
  SHP = 'SHP',
  SLL = 'SLL',
  SOS = 'SOS',
  SRD = 'SRD',
  SSP = 'SSP',
  STN = 'STN',
  SVC = 'SVC',
  SYP = 'SYP',
  SZL = 'SZL',
  THB = 'THB',
  TJS = 'TJS',
  TMT = 'TMT',
  TND = 'TND',
  TOP = 'TOP',
  TRY = 'TRY',
  TTD = 'TTD',
  TWD = 'TWD',
  TZS = 'TZS',
  UAH = 'UAH',
  UGX = 'UGX',
  USD = 'USD',
  USN = 'USN',
  UYI = 'UYI',
  UYU = 'UYU',
  UYW = 'UYW',
  UZS = 'UZS',
  VES = 'VES',
  VND = 'VND',
  VUV = 'VUV',
  WST = 'WST',
  XAF = 'XAF',
  XAG = 'XAG',
  XAU = 'XAU',
  XBA = 'XBA',
  XBB = 'XBB',
  XBC = 'XBC',
  XBD = 'XBD',
  XCD = 'XCD',
  XDR = 'XDR',
  XOF = 'XOF',
  XPD = 'XPD',
  XPF = 'XPF',
  XPT = 'XPT',
  XSU = 'XSU',
  XTS = 'XTS',
  XUA = 'XUA',
  XXX = 'XXX',
  YER = 'YER',
  ZAR = 'ZAR',
  ZMW = 'ZMW',
  ZWL = 'ZWL',
}

export enum Week {
  SUN = 'SUN',
  MON = 'MON',
  TUE = 'TUE',
  WED = 'WED',
  THU = 'THU',
  FRI = 'FRI',
  SAT = 'SAT',
}

export const weekLabel = [
  {
    value: Week.SUN,
    label: '日',
  },
  {
    value: Week.MON,
    label: '一',
  },
  {
    value: Week.TUE,
    label: '二',
  },
  {
    value: Week.WED,
    label: '三',
  },
  {
    value: Week.THU,
    label: '四',
  },
  {
    value: Week.FRI,
    label: '五',
  },
  {
    value: Week.SAT,
    label: '六',
  },
];

export const monthLabel = [
  '一',
  '二',
  '三',
  '四',
  '五',
  '六',
  '七',
  '八',
  '九',
  '十',
  '十一',
  '十二',
];

export type Schedule = Partial<Record<Week, Array<{ open: number; close: number }>>>;
