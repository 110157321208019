import React from "react"
import { Spinner } from "@chakra-ui/react"

const InlineLoading: React.FC<InlineLoadingProps> = ({ open }) => {
    return (
        open ? <Spinner style={{ marginRight: '10px' }} size="sm" /> : <></>
    )
}

export default InlineLoading
