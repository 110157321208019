import React, {Ref, useEffect, useRef, useState} from 'react';
import './ProductImageSlider.scss';
import LeftCircleIcon from '../../assets/icons/LeftCircleIcon.svg';
import RightCircleIcon from '../../assets/icons/RightCircleIcon.svg';
import DefaultCover from '../../assets/images/default-product.png'
import { useDisclosure } from '@chakra-ui/react';

const ProductImageSlider: React.FC<ProductImageSliderProps> = (props) => {
  const {images} = props;

  const [currentSlide, setCurrentSlide] = useState(0);
  const [itemWidth, setItemWidth] = useState(1148);
  const [itemHeight, setItemHeight] = useState(511);
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    if (images.length > 0) {
      setCurrentSlide(1)
      setTimeout(() => onOpen(), 4)
    }
    else {
      onClose()
      setTimeout(() => setCurrentSlide(0), 4)
    }

    const timer = setInterval(() => {
      setCurrentSlide(c => {
        let next = c + 1
        if (next >= images.length) next = 0
        return next
      })
    }, 3000)

    return () => clearInterval(timer)
  }, [images])
  
  const onClickNext = () => {
    setCurrentSlide(currentSlide + 1);
  };

  const onClickPrevious = () => {
    setCurrentSlide(currentSlide - 1);
  };

  const getDefaultTransform = () => {
    const root = document.querySelector('.content-wrapper')
    return ((root ? root.clientWidth : window.innerWidth) - itemWidth) / 2;
  };

  const getStyle = () => {
    return {
      transform: `translateX(${-(currentSlide * itemWidth - getDefaultTransform())}px)`,
      transition: isOpen ? 'transform 500ms' : 'none',
    };
  };

  return <div className="product-image-slider">
    <div
      className="product-image-slider-list"
      style={getStyle()}
    >
      {(images.length === 0 ? [DefaultCover] : images).map((img, i) => <div key={i} className="product-image-slider-item">
        <div
          className="product-image-slider-image"
          style={{
            backgroundImage: `url(${img})`,
          }}
        >
          {/* <div className="product-image-slider-mask"/> */}
        </div>
      </div>)}
    </div>

    <div
      className={
        [
          'product-image-slider-arrow-left',
          currentSlide == 0 ? 'hidden' : undefined,
        ]
          .filter(c => !!c)
          .join(' ')
      }
      onClick={onClickPrevious}
    >
      <img src={LeftCircleIcon}/>
    </div>
    <div
      className={
        [
          'product-image-slider-arrow-right',
          currentSlide >= ((images?.length ?? 0) - 1) ? 'hidden' : undefined,
        ]
          .filter(c => !!c)
          .join(' ')
      }
      onClick={onClickNext}
    >
      <img src={RightCircleIcon}/>
    </div>
  </div>;
};

export default ProductImageSlider;
