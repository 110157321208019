import React, {FormEvent, useState, useRef, useEffect} from 'react';
import {Button, Input, Radio, RadioGroup, Stack} from '@chakra-ui/react';
import Select, { Option } from 'rc-select';
import { useRecoilState } from 'recoil';
import {Moment} from 'moment';
import {ChevronDownIcon} from '@chakra-ui/icons';

import '~/components/select/select.scss'
import { useI18NText } from '~/i18n/i18n';
import orderListFilterState, { getOrderListFilterStateDefault } from '~/recoil/orderListFilterState';

import DateRangePicker from '../date/DateRangePicker';
import './OrderListFilter.scss';
import InlineLoading from '../InlineLoading';


const OrderListFilter: React.FC<OrderListFilterProps> = (props) => {
  // i18n国际化纯文本
  const { getI18NText } = useI18NText({ prefix: 'component.' })
  const dateFormat = `YYYY${getI18NText('年')}M${getI18NText('月')}D${getI18NText('日')}`
  const [dates, setDates] = useState<[Moment | null, Moment | null]>([null, null]);

  const [_filterState, setFilterState] = useRecoilState<OrderListFilterState>(orderListFilterState);
  const onDatesChange = (_dates: [Moment | null, Moment | null]) => {
    setDates(_dates);
    const datesResult: any = {};
    if (_dates[0]) {
      datesResult.startDate = _dates[0].format('YYYY-MM-DD');
    }
    if (_dates[1]) {
      datesResult.endDate = _dates[1].format('YYYY-MM-DD');
    }

    setFilterState({
      ..._filterState,
      ...datesResult,
    });
  };
  const keywordInputRef = useRef<HTMLInputElement>(null);
  const onKeywordInput = (e: FormEvent<HTMLInputElement>) => {
      const value = (e.target as HTMLInputElement).value;
      setFilterState({
        ..._filterState,
        keyword: value,
      });
  };
  const renderValue = (dates: [Moment | null, Moment | null]): React.ReactNode => {
    return <div className="date-value">
      {dates?.[0]?.format(dateFormat)} - {dates?.[1]?.format(dateFormat)}
    </div>
  };

  const onBookingStatusChange = (ev: any) => {
    setFilterState({
      ..._filterState,
      bookingStatus: ev,
    });
  };
  const onPaymentMethodChange = (ev: any) => {
    setFilterState({
      ..._filterState,
      paymentMethod: ev,
    });
  };
  const onDateFieldChange = (ev: any) => {
    setFilterState({
      ..._filterState,
      dateField: ev,
    });
  };

  const handleClear = () => { 
    setFilterState(getOrderListFilterStateDefault())
    setDates([null, null])
  }


  return <div className="order-list-filter">
    <div className="order-list-filter-top">
      <Input ref={keywordInputRef} onInput={onKeywordInput} value={_filterState.keyword || ''} className="search" placeholder={getI18NText("输入订单号、联系人、手机")}/>
      <Select
        className="order-status"
        placeholder={getI18NText("全部订单状态")}
        onChange={onBookingStatusChange}
        value={_filterState.bookingStatus || 'all'}
      >
        <option value="all">{getI18NText("全部订单状态")}</option>
        <option value="PENDING">{getI18NText("待付款")}</option>
        <option value="FAILED">{getI18NText("预定失败")}</option>
        <option value="IN_BOOKING">{getI18NText("确认中")}</option>
        <option value="SUCCESS">{getI18NText("已确认")}</option>
        <option value="CANCELLED">{getI18NText("已取消")}</option>
        <option value="INVALID">{getI18NText("已失效")}</option>
        <option value="USED">{getI18NText("已出行")}</option>
      </Select>
      <Select
        className="pay-method"
        placeholder={getI18NText("全部支付方式")}
        onChange={onPaymentMethodChange}
        value={_filterState.paymentMethod || 'all'}
      >
        <option value="all">{getI18NText("全部支付方式")}</option>
        <option value="Wechat">{getI18NText("微信")}</option>
        {/* <option value="alipay">{getI18NText("支付宝")}</option>
        <option value="creditCard">{getI18NText("信用卡")}</option> */}
      </Select>
    </div>
    <div className="order-list-filter-bottom">
      <div className="select-group">
        <RadioGroup onChange={onDateFieldChange} value={_filterState.dateField || 'all'}>
          <Radio value="all">{getI18NText('所有时间')}</Radio>
          <Radio value="Booking">{getI18NText('预定提交时间')}</Radio>
          <Radio value="CheckIn">{getI18NText('预定出行时间')}</Radio>
        </RadioGroup>
        <div className="time-select">
          <DateRangePicker
            dates={dates}
            placeholder={getI18NText("选择时间")}
            onChange={(_dates) => onDatesChange(_dates)}
            renderValue={renderValue}
            isOutsideRange={() => false}
          />
          {dates?.[0] ? null : <ChevronDownIcon w="24px" h="16px" className="down-icon"/>}
        </div>
      </div>
      <div className="btn-group">
        <Button className="btn plain" onClick={handleClear}>{getI18NText('清空')}</Button>
        <Button className="btn primary" onClick={() => props.loadOrders?.()}><InlineLoading open={props.loading} />{getI18NText('筛选')}</Button>
      </div>
    </div>
  </div>;
};

export default OrderListFilter;
