import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
  BreadcrumbSeparator,
  Spinner,
} from '@chakra-ui/react';
import moment, { Moment } from 'moment';
import { useRecoilState } from 'recoil';
import { map, reduce, filter, keyBy, findIndex, concat } from 'lodash';
import dayjs from 'dayjs';
import * as qs from 'qs';
import { marked } from 'marked';
import { WarningIcon } from '@chakra-ui/icons';

import { TourBaseUrl } from '~/constants';
import { get, post } from '~/utils/request';
import ProductImageSlider from '~/components/product/ProductImageSlider';
import Container from '~/layouts/components/Container';
import TicketIcon from '~/assets/icons/TicketIcon.svg';
import FeatureTag from '~/components/tag/FeatureTag';
import ProductBookingBox from '~/components/product/ProductBookingBox';
import ProductList from '~/components/product/ProductList';
import FlashIcon from '~/assets/icons/flash.png';
import CircleIcon from '~/assets/icons/circle.png';
import ArchiveIcon from '~/assets/icons/archive.png';
import CalendarIcon from '~/assets/icons/calendar.png';
import ReceiptIcon from '~/assets/icons/receipt.png';
import filterState from '~/recoil/filterState';
import DateRangePicker from '~/components/date/DateRangePicker';
import ProductItemBox from '~/components/product/ProductItemBox';
import Cart from '~/components/cart/Cart';
import MapBox from '~/components/mapbox/';
import { getValidShoppingCart } from '~/services/shoppingCart';
import shoppingCart from '~/recoil/shoppingCart';
import MarkedHtml from '~/components/markedhtml/MarkedHtml';

import exampleLocation from './exampleLocation.png';
import './ProductDetail.scss';

import { useI18NText } from '~/i18n/i18n';
import { hidePageLoading, showPageLoading } from '~/components/pageloading/PageLoading';
import { findContinent } from '~/recoil/layoutState';
import { Helmet } from 'react-helmet';
import { formatStrWithEllipsis } from '~/utils/helper';

const dayBlockedCache = new Map<Moment, boolean>();
const sampleFeatures = [
  {
    icon: <img src={TicketIcon} />,
    label: '不支持退改',
  },
  {
    icon: <img src={TicketIcon} />,
    label: '有效期内任何一天使用',
  },
  {
    icon: <img src={TicketIcon} />,
    label: '可出示手机电子凭证或打印凭证',
  },
  {
    icon: <img src={TicketIcon} />,
    label: '出示凭证入场/必须提前兑换实体票券',
  },
  {
    icon: <img src={TicketIcon} />,
    label: '可出示手机电子凭证或打印凭证',
  },
  {
    icon: <img src={TicketIcon} />,
    label: '有效期内任何一天使用',
  },
];
const sampleNotices = [
  '由于开业期间票务系统库存变化较快，门票预订后请以订单确认状态为准，如遇售罄，我们将为您取消订单并全额退款，带来不便，敬请谅解，带来不便，敬请谅解',
  '确认时效：付款成功后24小时之内，KLOOK客路会将凭证发送至您的邮箱，请注意查收。或可在客路官方App的订单详情中查看凭证。',
  '游客需提前通过北京环球度假区官方App预约入园时间，并根据预约的时间入园',
  '若有更多疑问，请联系KLOOK客路客服，将有专人为您答疑解惑',
  '温馨提示：假期出游人流较高，应防疫要求，出游前请通过北京环球度假区官方App或小程序预约入园时间： 请您携带购票时绑定的身份证件并在指定日期前往北京环球影城。关于最新疫情防控政策，请前往北京环球度假区官方APP中的“安全指南” 进行了解',
];

const ProductDetail = () => {
  // i18n国际化纯文本
  const { getI18NText } = useI18NText({ prefix: 'products.id.' });
  const navigate = useNavigate();
  const { id } = useParams();

  const locationRef = useRef<HTMLDivElement>(null);
  const programRef = useRef<HTMLDivElement>(null);
  const maskRef = useRef<HTMLDivElement>(null);
  const [showMask, setShowMask] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: warnOpen, onOpen: onWarnOpen, onClose: onWarnClose } = useDisclosure();
  const [scrollBehavior, setScrollBehavior] = useState('inside');
  const emptyFeatures: any[] = [];
  const [features, setFeatures] = useState(emptyFeatures);
  const emptyDetail: any = {};
  const [productDetail, setProductDetail] = useState(emptyDetail);
  const [selectedPackageId, setSelectedPackageId] = useState('');
  const emptyProducts: any[] = [];
  const [nearbyProducts, setNearbyProducts] = useState(emptyProducts);
  const [nearbyProductsLoadend, setNearbyProductsLoadend] = useState(false);
  const [shoppingCartCount, setShoppingCartCount] = useState(0);

  const emptyNotices: string[] = [];
  const [notices, setNotices] = useState(emptyNotices);
  const emptyImages: string[] = [];
  const [images, setImages] = useState(emptyImages);
  const [dateRange, setDateRange] = useState([
    dayjs().format('YYYY-MM-DD'),
    dayjs().add(28, 'days').format('YYYY-MM-DD'),
  ]);
  const [selectedDate, setSelectedDate] = useState('');

  const btnRef = useRef<HTMLDivElement>(null);
  const [pathItems, setPathItems] = useState<Array<string>>([]);
  const [selectedConfirmationType, setSelectedConfirmationType] = useState('');

  const [totalAmount, setTotalAmount] = useState<number>(0);

  const {
    isOpen: addCartLoading,
    onOpen: openCartLoading,
    onClose: closeCartLoading,
  } = useDisclosure();
  const undate = !productDetail.avaiableDates || productDetail.avaiableDates.length === 0;

  const loadList = async (params: any) => {
    const list = await post(`${TourBaseUrl}/api/products`, params);
    return map(list.data.docs, (row) => ({
      title: row.title,
      id: row.id,
      price: row.displayPrice,
      category: row.categoryName,
      thumbnail: row.thumbnail?.url,
    }));
  };

  const loadNearbyList = async (location: any) => {
    const list = await loadList({
      page: 1,
      size: 10,
      sortType: ['distance'],
      distance: {
        distance: '50km',
        ...location,
        sort: 'desc',
      },
    });
    setNearbyProducts(list);
    setNearbyProductsLoadend(true);
  };

  const loadProductDetail = async () => {
    const detail = await get(`${TourBaseUrl}/api/product/${id}`, {
      startDate: dateRange[0],
      endDate: dateRange[1],
    });
    console.log('loadProductDetail:', detail);
    const { highlights, images, tags, ...others } = detail?.data || {};
    setFeatures(
      map(tags, (row) => ({
        icon: <img src={TicketIcon} />,
        label: row,
      })),
    );
    setNotices(highlights || emptyNotices);
    if (images?.length < 3) {
      const _images = map(images, 'url');
      setImages(concat(_images, _images, _images));
    } else {
      setImages(map(images, 'url'));
    }
    setProductDetail(others);
    setPathItems([
      findContinent(others.countryName) || findContinent(others.cityName),
      others.countryName,
      others.cityName,
      others.categoryName,
    ]);
    loadNearbyList(others.location);
  };

  useEffect(() => {
    if (id) {
      showPageLoading();
      loadProductDetail().then(() => hidePageLoading());
    }

    return () => hidePageLoading();
  }, [id]);

  const [programs, setPrograms] = useState([
    '[限时优惠]特定日子1日门票（有效期至2022年1月19日)',
    '普通日子1日门票（有效期至2022年1月19日）',
    '特定日子1日门票（有效期至2022年1月19日）',
    '美食餐券',
    '特惠美食餐券',
    '乐园午餐＋乐园小食＋酒店晚餐 (2张HK$100酒店餐厅内用餐券)',
    '【需自行预约入园日期】2日门票（有效期至2022年1月19日）',
  ]);

  const validShoppingCart = async () => {
    const valid = await getValidShoppingCart();
    setShoppingCartCount(valid);
  };

  useEffect(() => {
    validShoppingCart();
  }, ['']);

  const products = [
    {
      title: '香港迪士尼乐园',
      category: '景点门票 & 表演',
      price: 418,
    },
    {
      title: '香港迪士尼乐园',
      category: '景点门票 & 表演',
      price: 1418,
    },
    {
      title: '香港迪士尼乐园',
      category: '景点门票 & 表演',
      price: 418,
    },
    {
      title: '香港迪士尼乐园',
      category: '景点门票 & 表演',
      price: 418,
    },
    {
      title: '香港迪士尼乐园',
      category: '景点门票 & 表演',
      price: 418,
    },
    {
      title: '香港迪士尼乐园',
      category: '景点门票 & 表演',
      price: 418,
    },
    {
      title: '香港迪士尼乐园',
      category: '景点门票 & 表演',
      price: 418,
    },
    {
      title: '香港迪士尼乐园',
      category: '景点门票 & 表演',
      price: 418,
    },
  ];

  const sep = '>';
  const [_filterState, setFilterState] = useRecoilState<FilterState>(filterState);
  const [_shoppingCart, setShoppingCart] = useRecoilState<ShoppingCart>(shoppingCart);

  const { dates } = _filterState;

  const onDatesChange = (dates: [Moment | null, Moment | null]) => {
    setFilterState({
      ..._filterState,
      dates,
    });
    setSelectedDate(dates[0]?.format('YYYY-MM-DD') || '');
  };

  const isDayBlocked = (day: Moment): boolean => {
    return findIndex(productDetail.avaiableDates, (d) => d === day.format('YYYY-MM-DD')) < 0;
  };

  const priceCache = new Map<Moment, number>();
  const getPrice = (day: Moment): number => {
    return productDetail.datesMinPrice[day.format('YYYY-MM-DD')] || 0;
  };

  const renderDayContents = (day: Moment): React.ReactNode => {
    return (
      <div className="day-content-wrapper">
        <div className="day-content-time">{day.format('D')}</div>
        {isDayBlocked(day) ? null : <div className="day-content-price">{getPrice(day)}</div>}
      </div>
    );
  };

  const [activeProgramIndex, setActiveProgramIndex] = useState<number>(-1);
  const emptyProductItems: ProductItemProps[] = [];
  const [productItems, setProductItems] = useState<ProductItemProps[]>(emptyProductItems);

  useEffect(() => {
    console.log('ProductDetail.tsx product items:', productItems);
  }, [productItems]);

  const onProgramClick = (pid: string) => () => {
    if (!dates?.[0]) {
      setDateRangePickerOpen(true);
      return;
    }
    const packageDetail = productDetail.packageDetail[pid];
    const packageDaySkus = packageDetail.dateDetail[dates[0].format('YYYY-MM-DD')];
    if (!packageDaySkus) {
      return;
    }
    if (selectedPackageId === pid) {
      setSelectedPackageId('');
      setProductItems(emptyProductItems);
    } else {
      setSelectedPackageId(pid);
      setProductItems(
        map(packageDaySkus.skuInventory, (row) => ({
          id: row.skuId,
          name: row.title,
          price: row.price || 0,
          count: 0,
          ...(function () {
            const find = packageDetail.SKUs.find((item: any) => item.id === row.skuId);

            if (!find) return {};

            return {
              maxPax: find.maxPax,
              minPax: find.minPax,
            };
          })(),
        })),
      );
    }
    setTotalAmount(0);
  };

  const onProductItemsChange = (items: ProductItemProps[]) => {
    const total = reduce(
      items,
      (sum, item: any) => {
        if (!item) return sum;
        sum += (item?.price || 0) * item?.count;
        return sum;
      },
      0,
    );
    setTotalAmount(total);
    setProductItems(items);
  };
  useEffect(() => {
    if (!dates?.[0]) {
      setActiveProgramIndex(-1);
    }
  }, [dates]);

  const [programDisabledIndexes, setProgramDisabledIndexes] = useState<number[]>([2]);
  useEffect(() => {
    if (!dates?.[0]) {
      setProgramDisabledIndexes([]);
    } else {
      setProgramDisabledIndexes([2]);
    }
  }, [dates]);

  const onClear = () => {
    setFilterState({
      ..._filterState,
      dates: [null, null],
    });
    setActiveProgramIndex(-1);
    setSelectedPackageId('');
  };

  const add2ShoppingCart = async () => {
    if (!selectedPackageId) {
      return null;
    }
    const day = dates?.[0]?.format('YYYY-MM-DD');
    if (!day) {
      return null;
    }
    const skus = filter(productItems, 'count');
    if (!skus.length) {
      onWarnOpen();
      return null;
    }
    const packageMap = keyBy(productDetail.packages, 'id');

    const adding = {
      product: {
        id: productDetail.id,
        title: productDetail.title,
        thumbnail: productDetail.thumbnail,
        location: productDetail.location,
      },
      package: {
        ...packageMap[selectedPackageId],
        cancellationType: productDetail.packageDetail[selectedPackageId].cancellationType,
        cancellationPolicy: productDetail.packageDetail[selectedPackageId].cancellationPolicy,
      },
      date: day,
      skus,
      extraInfo: packageMap[selectedPackageId]?.extraInfo,
    };
    openCartLoading();
    await post(`${TourBaseUrl}/api/shoppingCart`, { ...adding, replace: isEdit });
    closeCartLoading();
    setShoppingCart({
      ..._shoppingCart,
      adding,
    });
    navigate('/products/cart');
  };
  const checkout = async () => {
    if (!selectedPackageId) {
      return null;
    }
    const day = dates?.[0]?.format('YYYY-MM-DD');
    if (!day) {
      return null;
    }
    const skus = filter(productItems, 'count');
    if (!skus.length) {
      onWarnOpen();
      return null;
    }
    const packageMap = keyBy(productDetail.packages, 'id');

    const checking = {
      product: {
        id: productDetail.id,
        title: productDetail.title,
        thumbnail: productDetail.thumbnail,
        location: productDetail.location,
      },
      package: {
        ...packageMap[selectedPackageId],
        cancellationType: productDetail.packageDetail[selectedPackageId].cancellationType,
        cancellationPolicy: productDetail.packageDetail[selectedPackageId].cancellationPolicy,
      },
      date: day,
      skus,
      total: totalAmount,
      packageDetail: productDetail.packageDetail[selectedPackageId],
      extraInfo: packageMap[selectedPackageId]?.extraInfo,
    };
    setShoppingCart({
      ..._shoppingCart,
      checking: [checking],
    });
    navigate('/orders/submit');
  };

  const [dateRangePickerOpen, setDateRangePickerOpen] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  useEffect(() => {
    if (window.location.search.includes('edit=')) {
      setIsEdit(true);
      setFilterState({
        ..._filterState,
        dates: [moment(), null],
      });
      setActiveProgramIndex(1);
    }
  }, []);

  //从子组件接受参数，根据参数类别滚动到选择方案模块或者查看地点模块
  const handleTurnType = (turnType: string) => {
    turnType == 'location'
      ? locationRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
      : programRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  useEffect(() => {
    setTimeout(() => {
      const flag = !!maskRef.current && maskRef.current.scrollHeight > maskRef.current.offsetHeight;

      console.log(flag, showMask);

      flag !== showMask && setShowMask(flag);
    }, 0);
  });

  return (
    <>
      <Helmet>
        <title>{formatStrWithEllipsis(productDetail.title, 30)}</title>
      </Helmet>
      <div className="product-detail">
        <ProductImageSlider images={images} />

        <Container>
          <Breadcrumb
            className="product-detail-breadcrumb"
            separator={<span style={{ margin: '0 8px' }}>{sep}</span>}
          >
            {pathItems
              .filter((p) => !!p)
              .map((path, i) => (
                <BreadcrumbItem key={i}>
                  {i !== (pathItems.filter((p) => !!p).length === 3 ? 1 : 2) ? (
                    <BreadcrumbSeparator>{path}</BreadcrumbSeparator>
                  ) : (
                    <BreadcrumbLink as={RouterLink} to={`/location/${path}`}>
                      {path}
                    </BreadcrumbLink>
                  )}
                </BreadcrumbItem>
              ))}
          </Breadcrumb>

          <div className="product-detail-container">
            <div className="product-detail-content">
              <div className="product-detail-title">{productDetail.title}</div>
              <div className="product-detail-subtitle">
                {productDetail.description || productDetail.subTitle}
              </div>
              <div className="sep-line" />

              <div className="product-detail-features">
                {features.map((f: any, i) => (
                  <FeatureTag key={i} icon={f.icon} label={f.label} border={'none'} />
                ))}
              </div>
              <div className="sep-line" />

              <div className="product-detail-notice">
                <ul className="product-detail-notice-list">
                  {notices
                    .filter((n) => !!n)
                    .map((n, i) => (
                      <li key={i} className="product-detail-notice-item">
                        {/* <MarkedHtml html={n} /> */}
                        {n}
                      </li>
                    ))}
                </ul>
              </div>
            </div>

            <div className="product-detail-sidebar">
              {(() => {
                const _pid = selectedPackageId || productDetail?.packages?.[0]?.id;
                if (!_pid) {
                  return null;
                }
                const packageDetail = productDetail.packageDetail[_pid];
                return (
                  <>
                    <ProductBookingBox
                      line1={''}
                      line2={packageDetail?.confirmationType}
                      getTurnType={handleTurnType}
                      price={productDetail.displayPrice}
                    />
                  </>
                );
              })()}
            </div>
          </div>
        </Container>

        <Container>
          <div className="product-detail-container">
            <div className="product-detail-content">
              <div ref={programRef} className="product-detail-program">
                <div className="product-detail-common-title">{getI18NText('方案选项')}</div>
                <div className="product-detail-program-box">
                  <div className="product-detail-program-box-title">
                    {getI18NText('请选择日期、预订选项')}
                  </div>
                  <div className="product-detail-program-box-section">
                    <div className="product-detail-program-box-label">
                      {getI18NText('请选择预计兑换日期，预订时所选日期仅供参考')}
                    </div>
                    <div className="product-detail-program-box-list product-detail-program-box-select-date">
                      <DateRangePicker
                        undate={undate}
                        dates={dates}
                        onChange={onDatesChange}
                        isDayBlocked={isDayBlocked}
                        renderDayContents={renderDayContents}
                        singleDate
                        open={dateRangePickerOpen}
                        onOpenChange={(open) => setDateRangePickerOpen(open)}
                        extra={
                          <div className="extra-date-info">
                            <div>{getI18NText('货币')}: ¥ CNY</div>
                            <div>{getI18NText('当地时间')}</div>
                          </div>
                        }
                      />
                      <Button className="btn plain" onClick={onClear}>
                        {getI18NText('清空')}
                      </Button>
                    </div>
                  </div>

                  {!undate && (
                    <div className="product-detail-program-box-section">
                      <div className="product-detail-program-box-label">
                        {getI18NText('方案类型')}
                      </div>
                      <div className="product-detail-program-box-list">
                        {map(productDetail.packages, ({ id: pid, title: packageTitle }) => {
                          let packageDisabled = false;
                          const day = dates?.[0]?.format('YYYY-MM-DD');
                          const packageDetail = productDetail.packageDetail[pid];
                          if (day && !packageDetail?.dateDetail?.[day]) {
                            packageDisabled = true;
                          }
                          return (
                            <div
                              key={pid}
                              className={[
                                'product-detail-program-box-item',
                                selectedPackageId === pid ? 'selected' : '',
                                packageDisabled ? 'disabled' : '',
                              ]
                                .filter((c) => !!c)
                                .join(' ')}
                              onClick={onProgramClick(pid)}
                            >
                              <div className="program-title">{packageTitle}</div>
                              {packageDisabled ? (
                                <div className="disabled-notice">
                                  {getI18NText('所选日期不可预定')}
                                </div>
                              ) : null}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  {(() => {
                    if (!selectedPackageId) {
                      return null;
                    }
                    const day = dates?.[0]?.format('YYYY-MM-DD');
                    if (!day) {
                      return null;
                    }
                    const selectPackageDetail = productDetail.packageDetail[selectedPackageId];
                    if (day && selectPackageDetail.dateDetail[day]) {
                      return (
                        <>
                          <div className="product-detail-product-item-section">
                            <ProductItemBox
                              items={productItems}
                              maxItemCount={selectPackageDetail.maxPax || 0}
                              totalAmount={totalAmount}
                              onChange={onProductItemsChange}
                            />
                          </div>
                          <div className="product-detail-actions-section">
                            <div className="right">
                              {isEdit ? (
                                <>
                                  <Button
                                    className="btn warning"
                                    onClick={() => navigate('/products/cart')}
                                  >
                                    {getI18NText('返回购物车')}
                                  </Button>
                                  <Button
                                    className="btn primary"
                                    onClick={() => add2ShoppingCart()}
                                  >
                                    {addCartLoading && (
                                      <Spinner style={{ marginRight: '10px' }} size="sm" />
                                    )}
                                    {getI18NText('确认修改')}
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <Button
                                    className="btn warning"
                                    onClick={() => add2ShoppingCart()}
                                  >
                                    {addCartLoading && (
                                      <Spinner style={{ marginRight: '10px' }} size="sm" />
                                    )}
                                    {getI18NText('加入购物车')}
                                  </Button>
                                  <Button className="btn primary" onClick={() => checkout()}>
                                    {getI18NText('立即预定')}
                                  </Button>
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      );
                    }
                  })()}
                </div>
              </div>

              <div className="product-detail-description">
                <div className="product-detail-common-title">{getI18NText('活动介绍')}</div>
                <div className="product-detail-description-content">
                  <div className="product-detail-description-para">
                    {productDetail.description || productDetail.subTitle}
                  </div>
                </div>
              </div>
              <div ref={locationRef} className="product-detail-location">
                <div className="product-detail-common-title">{getI18NText('地点')}</div>
                <div className="product-detail-location-content">
                  {productDetail && productDetail.location && (
                    <MapBox
                      latitude={productDetail.location.lat}
                      longitude={productDetail.location.lon}
                    />
                  )}
                </div>
              </div>
            </div>
            <div
              className={[
                'product-detail-program-sidebar',
                !!selectedPackageId ? 'active' : null,
              ].join(' ')}
            >
              <div
                className={`product-detail-program-sidebar-wrap ${
                  showMask ? 'product-detail-program-sidebar-wrap-mask' : ''
                }`}
                ref={maskRef}
              >
                <div className="product-detail-sidebar-title">{getI18NText('已选方案详情')}</div>
                {(() => {
                  if (!selectedPackageId) {
                    return null;
                  }
                  const packageDetail = productDetail.packageDetail[selectedPackageId];
                  return (
                    <div className="product-detail-sidebar-content">
                      <div className="notice-item">
                        <img className="icon" src={FlashIcon} />
                        <span className="text">{packageDetail.confirmationType}</span>
                      </div>
                      <div className="notice-item">
                        <img className="icon" src={CircleIcon} />
                        <span className="text">{packageDetail.cancellationType}</span>
                      </div>
                      <div className="notice-item">
                        <img className="icon" src={CalendarIcon} />
                        <span className="text">{getI18NText('仅限指定日期使用')}</span>
                      </div>
                      <div className="notice-item">
                        <img className="icon" src={ArchiveIcon} />
                        <span className="text">{packageDetail.voucherUsage}</span>
                      </div>
                      <div className="notice-item">
                        <img className="icon" src={ReceiptIcon} />
                        <span className="text">{getI18NText('必须提前兑换实体票券')}</span>
                      </div>
                    </div>
                  );
                })()}
                <div className="product-detail-sidebar-tab">
                  <Tabs onChange={() => setShowMask(!showMask)}>
                    <TabList>
                      <Tab>{getI18NText('方案详情')}</Tab>
                      <Tab>{getI18NText('使用条款')}</Tab>
                      <Tab>{getI18NText('如何使用')}</Tab>
                    </TabList>
                    <TabPanels>
                      <TabPanel>
                        <h2 className="product-detail-sidebar-panel-title">
                          {getI18NText('方案详情')}
                        </h2>
                        <div className="product-detail-sidebar-panel-content">
                          {(() => {
                            if (!selectedPackageId) {
                              return null;
                            }
                            const packageDetail = productDetail.packageDetail[selectedPackageId];
                            return (
                              <div className="product-detail-sidebar-panel-content-list">
                                <MarkedHtml html={packageDetail.detail} />
                              </div>
                            );
                          })()}
                        </div>
                      </TabPanel>
                      <TabPanel>
                        {(() => {
                          if (!selectedPackageId) {
                            return null;
                          }
                          const packageDetail = productDetail.packageDetail[selectedPackageId];
                          return (
                            <>
                              <MarkedHtml html={packageDetail.cancellationPolicy} />
                              <MarkedHtml html={packageDetail.openHours} />
                              <MarkedHtml html={packageDetail.redemptionProcess} />
                              {/* <p>{packageDetail.cancellationPolicy}</p>
                            <p>{packageDetail.openHours}</p>
                            <p>{packageDetail.redemptionProcess}</p> */}
                            </>
                          );
                        })()}
                      </TabPanel>
                      <TabPanel>
                        {(() => {
                          if (!selectedPackageId) {
                            return null;
                          }
                          const packageDetail = productDetail.packageDetail[selectedPackageId];
                          return (
                            <>
                              <MarkedHtml html={packageDetail.voucherValidity} />
                              <MarkedHtml html={packageDetail.voucherUsage} />
                              <MarkedHtml html={packageDetail.voucherTypeDesc} />
                              {/* <p>{packageDetail.voucherValidity}</p>
                            <p>{packageDetail.voucherUsage}</p>
                            <p>{packageDetail.voucherTypeDesc}</p> */}
                            </>
                          );
                        })()}
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </div>
              </div>
              <div className="collapsed-content" ref={btnRef} onClick={onOpen}>
                <span className="collapsed-content-text">{getI18NText('显示全部')}</span>
                <span className="collapsed-content-icon"></span>
              </div>
            </div>
            <Modal
              id="sidebar-detail-modal"
              onClose={onClose}
              finalFocusRef={btnRef}
              isOpen={isOpen}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalCloseButton />
                <ModalBody>
                  {(() => {
                    if (!selectedPackageId) {
                      return null;
                    }
                    const packageMap = keyBy(productDetail.packages, 'id');
                    const title = packageMap[selectedPackageId].title;
                    const packageDetail = productDetail.packageDetail[selectedPackageId];
                    return (
                      <>
                        <div className="sidebar-detail-modal-box">
                          <div className="sidebar-detail-modal-top">
                            <h2 className="sidebar-detail-modal-panel-title">
                              {getI18NText('已选择方案详情')}
                            </h2>
                            <div className="sidebar-detail-program-box">
                              <div className="program-title">{title}</div>
                            </div>
                            <div className="sidebar-detail-program-box-list">
                              <div className="sidebar-detail-program-box-item">
                                <img className="icon" src={FlashIcon} />
                                <span className="text">{packageDetail.confirmationType}</span>
                              </div>
                              <div className="sidebar-detail-program-box-item">
                                <img className="icon" src={CircleIcon} />
                                <span className="text">{packageDetail.cancellationType}</span>
                              </div>
                              <div className="sidebar-detail-program-box-item">
                                <img className="icon" src={CalendarIcon} />
                                <span className="text">{getI18NText('有效期内任何一天使用')}</span>
                              </div>
                            </div>
                            <div className="sidebar-detail-program-box-list">
                              <div className="sidebar-detail-program-box-item">
                                <img className="icon" src={ReceiptIcon} />
                                <span className="text">{packageDetail.voucherUsage}</span>
                              </div>
                              <div className="sidebar-detail-program-box-item">
                                <img className="icon" src={ArchiveIcon} />
                                <span className="text">{packageDetail.voucherUsage}</span>
                              </div>
                            </div>
                          </div>
                          <div className="sidebar-detail-modal-scroll-wrap">
                            <h2 className="sidebar-detail-modal-scroll-title">
                              {getI18NText('方案详情')}
                            </h2>
                            <div className="sidebar-detail-modal-scroll-content">
                              <div className="sidebar-detail-modal-scroll-content-list">
                                <MarkedHtml html={packageDetail.detail} />
                                {/* {packageDetail.detail} */}
                              </div>
                            </div>
                            <h2 className="sidebar-detail-modal-scroll-title">
                              {getI18NText('使用条款')}
                            </h2>
                            <div className="sidebar-detail-modal-scroll-content">
                              <div className="sidebar-detail-modal-scroll-content-list">
                                <MarkedHtml html={packageDetail.cancellationPolicy} />
                                {/* {packageDetail.cancellationPolicy} */}
                              </div>
                              <div className="sidebar-detail-modal-scroll-content-list">
                                <MarkedHtml html={packageDetail.openHours} />
                                {/* {packageDetail.openHours} */}
                              </div>
                              <div className="sidebar-detail-modal-scroll-content-list">
                                <MarkedHtml html={packageDetail.redemptionProcess} />
                                {/* {packageDetail.redemptionProcess} */}
                              </div>
                            </div>
                            <h2 className="sidebar-detail-modal-scroll-title">
                              {getI18NText('如何使用')}
                            </h2>
                            <div className="sidebar-detail-modal-scroll-content">
                              <div className="sidebar-detail-modal-scroll-content-list">
                                <MarkedHtml html={packageDetail.voucherUsage} />
                                {/* {packageDetail.voucherUsage} */}
                              </div>
                              {/* <div className='sidebar-detail-modal-scroll-content-list'>
                            <MarkedHtml html={packageDetail.voucherUsage} />
                          </div> */}
                              <div className="sidebar-detail-modal-scroll-content-list">
                                <MarkedHtml html={packageDetail.voucherTypeDesc} />
                                {/* {packageDetail.voucherTypeDesc} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })()}
                </ModalBody>
              </ModalContent>
            </Modal>

            <Modal id="sidebar-error-modal" onClose={onWarnClose} isOpen={warnOpen} isCentered>
              <ModalOverlay />
              <ModalContent>
                <ModalBody paddingTop="8" textAlign="center">
                  <WarningIcon textColor="#788DA5" height="9" width="9" />
                  <Text fontSize="21px" textColor="#002C5E" marginTop="2" fontWeight="700">
                    {getI18NText('预定错误')}
                  </Text>
                  <Text fontSize="14px" textColor="#333333" marginTop="4">
                    {getI18NText('请根据指定数量进行预定')}
                  </Text>
                  <Button className="warn-btn" onClick={onWarnClose}>
                    {getI18NText('重新预定')}
                  </Button>
                </ModalBody>
              </ModalContent>
            </Modal>
          </div>
          <div className="product-detail-nearby-products">
            <div className="product-detail-common-title">{getI18NText('附近产品')}</div>
            <ProductList
              title={false}
              products={nearbyProducts}
              pagination={false}
              scrollable
              hideSearch
              loadend={nearbyProductsLoadend}
            />
          </div>
        </Container>

        <Cart productCount={shoppingCartCount} />
      </div>
    </>
  );
};
export default ProductDetail;
