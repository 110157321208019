import React, { useState, useEffect } from 'react';
import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import dayjs from 'dayjs';
import { map, sumBy } from 'lodash';
import * as qs from 'qs';

import shoppingCart from '~/recoil/shoppingCart';
import { TourBaseUrl } from '~/constants';
import { get, post } from '~/utils/request';

import '../../components/checkbox/CheckBox.scss';
import Container from '../../layouts/components/Container';
import ProductList from '../../components/product/ProductList';

import exampleCartImage from './exampleCartImage.png';
import './ProductCart.scss';

import { useI18NText } from '~/i18n/i18n';
import { formatStrWithEllipsis } from '~/utils/helper';
import { Helmet } from 'react-helmet';

const ProductCart = () => {
  // i18n国际化纯文本
  const { getI18NText } = useI18NText({ prefix: 'products.cart.' });
  const navigate = useNavigate();

  const products = [
    {
      title: '香港迪士尼乐园',
      category: '景点门票 & 表演',
      price: 418,
    },
    {
      title: '香港迪士尼乐园',
      category: '景点门票 & 表演',
      price: 1418,
    },
    {
      title: '香港迪士尼乐园',
      category: '景点门票 & 表演',
      price: 418,
    },
    {
      title: '香港迪士尼乐园',
      category: '景点门票 & 表演',
      price: 418,
    },
    {
      title: '香港迪士尼乐园',
      category: '景点门票 & 表演',
      price: 418,
    },
    {
      title: '香港迪士尼乐园',
      category: '景点门票 & 表演',
      price: 418,
    },
    {
      title: '香港迪士尼乐园',
      category: '景点门票 & 表演',
      price: 418,
    },
  ];
  const emptyProducts: any[] = [];
  const [nearbyProducts, setNearbyProducts] = useState(emptyProducts);
  const [nearbyProductsLoadend, setNearbyProductsLoadend] = useState(false);

  const [price, setPrice] = useState(1304);
  const [_shoppingCart, setShoppingCart] = useRecoilState<ShoppingCart>(shoppingCart);
  const { adding } = _shoppingCart;

  console.log('adding', adding);

  const renderNumber = () => {
    return map(adding?.skus, (sku: any) => [sku?.name, sku?.count].join('x')).join('、');
  };
  const renderTotalPrice = () => {
    return sumBy(adding?.skus, (sku: any) => sku.price * sku.count);
  };
  const renderDay = () => {
    return adding?.date ? dayjs(adding?.date, 'YYYY-MM-DD').format('YYYY年MM月DD日') : '-';
  };
  const loadList = async (params: any) => {
    const list = await post(`${TourBaseUrl}/api/products`, params);
    return map(list.data.docs, (row) => ({
      title: row.title,
      id: row.id,
      price: row.displayPrice,
      category: row.categoryName,
      thumbnail: row.thumbnail?.url,
    }));
  };
  const loadNearbyList = async (location: any) => {
    const list = await loadList({
      page: 1,
      size: 10,
      sortType: ['distance'],
      distance: {
        distance: '50km',
        ...location,
        sort: 'desc',
      },
    });
    setNearbyProducts(list);
    setNearbyProductsLoadend(true);
  };
  useEffect(() => {
    if (adding && adding.product.location) {
      loadNearbyList(adding.product.location);
    }
  }, [adding]);

  return (
    <>
      <Helmet>
        <title>
          加入购物车 {formatStrWithEllipsis(adding?.product?.title || '')} 当地玩乐｜多会儿旅行
        </title>
      </Helmet>
      <div className="product-cart">
        <Container>
          <div className="product-cart-title">{getI18NText('成功添加至购物车')}</div>
          <div className="product-cart-box">
            <div className="product-cart-box-image">
              <img src={adding?.product?.thumbnail || exampleCartImage} />
            </div>
            <div className="product-cart-box-content">
              <div className="product-cart-box-content-top">
                <div className="product-cart-box-content-name">{adding?.product?.title}</div>
                <div className="product-cart-box-content-price">
                  <span className="price-unit">¥</span>
                  <span className="price-value">{renderTotalPrice().toLocaleString()}</span>
                </div>
              </div>
              <div className="product-cart-box-content-bottom">
                <div className="notice-item">
                  {getI18NText('出游日期')}： {renderDay()}
                </div>
                <div className="notice-item">
                  {getI18NText('方案类型')}： {adding?.package.title}
                </div>
                <div className="notice-item">
                  {getI18NText('数量')}： {renderNumber()}
                </div>
              </div>
            </div>
          </div>
          <div className="product-cart-actions">
            <Button className="btn warning" onClick={() => navigate('/')}>
              {getI18NText('继续购物')}
            </Button>
            <Button className="btn primary" onClick={() => navigate('/orders/cart')}>
              {getI18NText('查看购物车')}
            </Button>
          </div>

          <div className="product-cart-nearby-products">
            <div className="product-cart-common-title">{getI18NText('附近产品')}</div>
            <ProductList
              title={false}
              products={nearbyProducts}
              pagination={false}
              scrollable
              hideSearch
              loadend={nearbyProductsLoadend}
            />
          </div>
        </Container>
      </div>
    </>
  );
};
export default ProductCart;
