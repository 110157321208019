import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { map, sum, flatten, keyBy, filter } from 'lodash';
import dayjs from 'dayjs';

import { TourBaseUrl } from '~/constants';
import { get, post } from '~/utils/request';
import OrderCartItem from '../../components/order/OrderCartItem';
import WarnIcon from '../../assets/icons/WarnIcon.svg';
import Container from '../../layouts/components/Container';
import shoppingCart from '~/recoil/shoppingCart';

import './OrderCart.scss';

import { getI18NTextOnce, useI18NText } from '~/i18n/i18n';
import { hidePageLoading, showPageLoading } from '~/components/pageloading/PageLoading';
import { Helmet } from 'react-helmet';

const transformItem = (res: any, selected = false): OrderCartItemProps => ({
  id: [res.product.id, res.package.id].join('_'),
  title: res.product.title,
  thumbnail: res.product.thumbnail,
  subItems: map(res.skus, (sku: any) => ({
    name: sku.name,
    count: sku.count,
    price: sku.price,
  })),
  date: dayjs(res.date, 'YYYY-MM-DD').format(
    `YYYY${getI18NTextOnce('component.年')}MM${getI18NTextOnce('component.月')}DD${getI18NTextOnce(
      'component.日',
    )}`,
  ),
  type: res.package.title,
  totalAmount: res.total,
  selected,
  toConfirm: res.toConfirm,
  invalid: res.invalid,
  cancellationPolicy: res.cancellationPolicy,
  cancellationType: res.cancellationType,
});

export const handleTotalAmount = (items: OrderCartItemProps[]) =>
  sum(map(flatten(map(items, 'subItems')), (row) => (row?.count || 1) * (row?.price || 0)));

const OrderCart = () => {
  // i18n国际化纯文本
  const { getI18NText } = useI18NText({ prefix: 'orders.cart.' });
  const navigate = useNavigate();

  const [isOpenDelProductModle, toggleDelProductModle] = useState(false);
  const [isOpenInvalidModal, toggleInvalidModal] = useState(false);
  const [isOpenCanNotCheckOutModal, toggleCanNotCheckOutModal] = useState(false);

  const [totalCount, setTotalCount] = useState(0);
  const [selectedCount, setSelectedCount] = useState(0);
  const [selectedTotalAmount, setSelectedTotalAmount] = useState(0);
  const [_shoppingCart, setShoppingCart] = useRecoilState<ShoppingCart>(shoppingCart);

  const emptyItems: OrderCartItemProps[] = [];
  const [cartItems, setCartItems] = useState<OrderCartItemProps[]>(emptyItems);
  const [originItems, setOriginItems] = useState([]);

  const getCartItems = useCallback(() => {
    return cartItems.filter((d) => !d.toConfirm && !d.invalid);
  }, [cartItems]);

  const getToConfirmCartItems = () => {
    return cartItems.filter((d) => d.toConfirm);
  };

  const getInvalidCartItems = () => {
    return cartItems.filter((d) => d.invalid);
  };

  useEffect(() => {
    const selectedItems = filter(cartItems, 'selected');
    setSelectedTotalAmount(handleTotalAmount(selectedItems));
    setSelectedCount(selectedItems.length);
    setSelectAll(selectedItems.length > 0 && selectedItems.length === getCartItems().length);
  }, [cartItems, getCartItems]);

  useEffect(() => {
    setTotalCount(cartItems.length);
  }, [cartItems]);

  const [selectAll, setSelectAll] = useState(false);
  const onSelectAll = (checked: boolean) => {
    // setSelectAll(checked);
    const _cartItems = JSON.parse(JSON.stringify(cartItems)) as OrderCartItemProps[];
    _cartItems.forEach((d) => {
      if (d.invalid || d.toConfirm) {
        return;
      }
      d.selected = checked;
    });
    setCartItems(_cartItems);
    // const selectedItems = filter(_cartItems, 'selected');
    // setSelectedTotalAmount(handleTotalAmount(selectedItems));
    // setSelectedCount(selectedItems.length)
  };
  const onSelectAllChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const checked = ev.target.checked;
    onSelectAll(checked);
  };
  const onSelect = (id: string | number) => (selected: boolean) => {
    console.log('=====select', id);
    const _cartItems = JSON.parse(JSON.stringify(cartItems)) as OrderCartItemProps[];
    const idx = _cartItems.findIndex((d) => d.id === id);
    _cartItems[idx].selected = selected;
    setCartItems(_cartItems);
    // const selectedItems = filter(_cartItems, 'selected');
    // setSelectAll(selectedItems.length === getCartItems().length);
    // setSelectedCount(selectedItems.length);
    // setSelectedTotalAmount(handleTotalAmount(selectedItems));
  };

  const deleteItems = async (items: string[]) => {
    await post(`${TourBaseUrl}/api/removeShoppingCartItems`, {
      items,
    });
  };

  const onDeleteSelected = async () => {
    toggleDelProductModle(!isOpenDelProductModle);
    // if (!window.confirm(getI18NText('是否确认删除?'))) return;
    const selectedItems = filter(cartItems, 'selected');
    await deleteItems(map(selectedItems, 'id'));
    const _cartItems = JSON.parse(
      JSON.stringify(cartItems.filter((d) => !d.selected)),
    ) as OrderCartItemProps[];
    setCartItems(_cartItems);
    // setSelectAll(false);
    // setSelectedTotalAmount(0);
  };
  const onDeleteInvalid = async () => {
    toggleInvalidModal(!isOpenInvalidModal);
    // if (!window.confirm(getI18NText('是否确认删除?'))) return;
    const invalidItems = getInvalidCartItems();
    await deleteItems(map(invalidItems, 'id'));
    const _cartItems = JSON.parse(
      JSON.stringify(cartItems.filter((d) => !d.invalid)),
    ) as OrderCartItemProps[];
    setCartItems(_cartItems);
  };
  const onDelete = (id: string | number) => async () => {
    if (!window.confirm(getI18NText('是否确认删除?'))) return;
    await deleteItems([`${id}`]);
    const _cartItems = JSON.parse(JSON.stringify(cartItems)) as OrderCartItemProps[];
    const idx = _cartItems.findIndex((d) => d.id === id);
    _cartItems.splice(idx, 1);
    setCartItems(_cartItems);
  };
  const onSubItemsChange = (id: string | number) => (subItems: OrderCartSubItem[]) => {
    const _cartItems = JSON.parse(JSON.stringify(cartItems)) as OrderCartItemProps[];
    const idx = _cartItems.findIndex((d) => d.id === id);
    _cartItems[idx].subItems = subItems;
    setCartItems(_cartItems);
  };

  const onCheckout = () => {
    if (cartItems.filter((d) => d.selected && !d.toConfirm && !d.invalid).length === 0) {
      // window.alert(getI18NText('无法结算'));
      toggleCanNotCheckOutModal(!isOpenCanNotCheckOutModal);
      return;
    }
    const selectedItems = filter(cartItems, 'selected');
    const originItemMap = keyBy(originItems, (row: any) =>
      [row.product.id, row.package.id].join('_'),
    );
    const checking = map(selectedItems, (item: any) => {
      const originItem = originItemMap[item.id];
      const ids = item.id.split('_');
      const packageMap = keyBy(item.packages, 'id');
      return {
        product: originItem.product,
        package: originItem.package,
        date: originItem.date,
        skus: originItem.skus,
        total: originItem.total,
        cancellationPolicy: originItem.cancellationPolicy,
        cancellationType: originItem.cancellationType,
        extraInfo: originItem.extraInfo || null,
      };
    });
    setShoppingCart({
      ..._shoppingCart,
      checking,
    });
    navigate('/orders/submit');
  };

  const loadShoppingCart = async () => {
    const shoppingCartData = await get(`${TourBaseUrl}/api/shoppingCartItems`);
    setOriginItems(shoppingCartData?.data);
    setCartItems(map(shoppingCartData?.data, (res: any) => transformItem(res)));
  };

  useEffect(() => {
    showPageLoading();
    loadShoppingCart().then(() => hidePageLoading());
    return () => hidePageLoading();
  }, []);

  const getProductId = (itemId: string) => {
    return itemId.split('_')[0];
  };

  return (
    <>
      <Helmet>
        <title>购物车 当地玩乐｜多会儿旅行</title>
      </Helmet>
      <div className="order-cart">
        <Container>
          <div className="order-cart-title">
            <span className="order-cart-title-main">{getI18NText('购物车')}</span>
            <span className="order-cart-title-sub">
              - {getI18NText('共有')} {totalCount} {getI18NText('样产品放入购物车')}
            </span>
          </div>

          <div className="order-cart-filter">
            <Checkbox
              isChecked={selectAll}
              className="select-all item"
              onChange={onSelectAllChange}
            />
            <span className="label btn item" onClick={() => onSelectAll(true)}>
              {getI18NText('全选')}
            </span>
            <span
              className="label btn item"
              onClick={() => toggleDelProductModle(!isOpenDelProductModle)}
            >
              {getI18NText('删除选中产品')}
            </span>
            <span
              className="label btn item"
              onClick={() => toggleInvalidModal(!isOpenInvalidModal)}
            >
              {getI18NText('清除失效产品')}
            </span>
          </div>

          <div className="order-cart-container">
            <div className="order-cart-box-list">
              <div className="order-cart-box">
                {getCartItems().map((item, i) => (
                  <OrderCartItem
                    key={i}
                    {...item}
                    onSelect={onSelect(item.id || 0)}
                    onDelete={onDelete(item.id || 0)}
                    onSubItemsChange={onSubItemsChange(item.id || 0)}
                    onEdit={() => navigate(`/products/${getProductId(item.id)}?edit=1`)}
                  />
                ))}
              </div>

              {getToConfirmCartItems().length > 0 && (
                <div className="order-cart-box">
                  <div className="order-cart-box-title">
                    {getI18NText('以下活动需重新确认信息')}
                  </div>
                  <div className="order-cart-box">
                    {getToConfirmCartItems().map((item, i) => (
                      <OrderCartItem
                        key={i}
                        {...item}
                        onDelete={onDelete(item.id || 0)}
                        onEdit={() => navigate(`/products/${getProductId(item.id)}?edit=1`)}
                      />
                    ))}
                  </div>
                </div>
              )}

              {getInvalidCartItems().length > 0 && (
                <div className="order-cart-box">
                  <div className="order-cart-box-title">{getI18NText('已失效产品')}</div>
                  <div className="order-cart-box">
                    {getInvalidCartItems().map((item, i) => (
                      <OrderCartItem key={i} {...item} onDelete={onDelete(item.id || 0)} />
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="order-cart-sidebar">
              <div className="order-cart-select-box">
                <div className="order-cart-select-box-top">
                  <div className="order-cart-select-box-label">
                    {getI18NText('已选择')}
                    <span className="count">{selectedCount}</span>
                    {getI18NText('个产品')}
                  </div>
                  <div className="order-cart-select-box-total-amount">
                    {selectedTotalAmount.toLocaleString()}
                  </div>
                </div>
                <div className="order-cart-select-box-bottom">
                  <Button className="btn warning" onClick={onCheckout}>
                    {getI18NText('结算')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Modal
          id="order-cart-modal"
          isOpen={isOpenDelProductModle}
          onClose={() => toggleDelProductModle}
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <div
                className="title-icon"
                style={{
                  backgroundImage: `url(${WarnIcon})`,
                }}
              ></div>
              <div className="title-text">{getI18NText('删除产品')}</div>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>{getI18NText('是否确认删除产品？')}</ModalBody>
            <ModalFooter>
              <Button className="warning" onClick={() => onDeleteSelected()}>
                {getI18NText('确定删除')}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal
          id="order-cart-modal"
          isOpen={isOpenInvalidModal}
          onClose={() => toggleInvalidModal}
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <div
                className="title-icon"
                style={{
                  backgroundImage: `url(${WarnIcon})`,
                }}
              ></div>
              <div className="title-text">{getI18NText('清除失效产品')}</div>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>{getI18NText('是否确认清除失效产品？')}</ModalBody>
            <ModalFooter>
              <Button className="warning" onClick={() => onDeleteInvalid()}>
                {getI18NText('确定清除')}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal
          id="order-cart-modal"
          isOpen={isOpenCanNotCheckOutModal}
          onClose={() => toggleCanNotCheckOutModal}
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <div
                className="title-icon"
                style={{
                  backgroundImage: `url(${WarnIcon})`,
                }}
              ></div>
              <div className="title-text">{getI18NText('无法结算')}</div>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>{getI18NText('请返回购物车选择可预定的产品')}</ModalBody>
            <ModalFooter>
              <Button
                className="warning"
                onClick={() => toggleCanNotCheckOutModal(!isOpenCanNotCheckOutModal)}
              >
                {getI18NText('返回页面')}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </div>
    </>
  );
};

export default OrderCart;
