import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import './App.css';
import Router from './routes';
import PageLoading from './components/pageloading/PageLoading';
import ErrorModal from './components/errorModal';

// 路由变更时回到顶部
const RouteChange: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    window.setTimeout(() => {
      document.body.scrollTop = 0;
      window.scrollTo(0, 0);
    }, 0);
  }, [location.pathname]);

  return <></>;
};

function App() {
  return (
    <RecoilRoot>
      <RouteChange />
      <Router />
      <PageLoading />
      <ErrorModal />
    </RecoilRoot>
  );
}

export default App;
