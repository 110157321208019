import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { BasicLayout } from '../layouts/BasicLayout';
import Login from '../views/login/Login';
import Home from '../views/home/Home';
import ProductListLocation from '../views/product/ProductListLocation';
import ProductListSearch from '../views/product/ProductListSearch';
import ProductDetail from '../views/product/ProductDetail';
import ProductCart from '../views/product/ProductCart';
import OrderCart from '../views/order/OrderCart';
import OrderSubmit from '../views/order/OrderSubmit';
import OrderPay from '../views/order/OrderPay';
import OrderPaid from '../views/order/OrderPaid';
import OrderList from '../views/order/OrderList';
import OrderDetail from '../views/order/OrderDetail';

const routes: RouteItem[] = [
  // 登陆页
  {
    path: 'login',
    element: <Login />,
    needAllWidth: true,
  },
  // 首页
  {
    path: '',
    element: <Home />,
  },
  // 关键字列表页
  {
    path: 'search',
    element: <ProductListSearch />,
  },
  // 关键字列表页
  {
    path: 'search/404',
    element: <ProductListSearch />,
  },
  // 目的地列表页
  {
    path: 'location/:name',
    element: <ProductListLocation />,
  },
  // 产品详情页
  {
    path: 'products/:id',
    element: <ProductDetail />,
  },
  // 加入购物车页
  {
    path: 'products/cart',
    element: <ProductCart />,
  },
  // 购物车确认页
  {
    path: 'orders/cart',
    element: <OrderCart />,
  },
  // 预定提交页
  {
    path: 'orders/submit',
    element: <OrderSubmit />,
  },
  // 订单支付页
  {
    path: 'orders/pay',
    element: <OrderPay />,
  },
  // 订单详情页-已付款
  {
    path: 'orders/paid',
    element: <OrderPaid />,
  },
  // 订单列表页
  {
    path: 'orders',
    element: <OrderList />,
  },
  // 订单详情页
  {
    path: 'orders/:id',
    element: <OrderDetail />,
  },
];

const RoutesComponent = () => {
  return (
    <Routes>
      <Route path="/" element={<BasicLayout routes={routes} />}>
        {routes.map((route, i) => (
          <Route key={i} path={route.path} element={route.element} />
        ))}
      </Route>
    </Routes>
  );
};

export default RoutesComponent;
