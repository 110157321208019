import { Center, Text, HStack } from "@chakra-ui/react";

interface NavMenuButtonProps {
  icon?: React.ReactNode;
  text?: string;
  isActive?: boolean;
  onClick?: () => void;
}

const NavMenuButton = (props: NavMenuButtonProps) => {
  if (props.isActive) {
    return (
      <HStack cursor="pointer" color="#002C5E" onClick={props.onClick}>
        {props.icon ? (
          <Center w="8" h="8" color="white" rounded="lg" bgColor="#2D6CDF">
            {props.icon}
          </Center>
        ) : null}
        <Text fontWeight="bold">{props.text}</Text>
      </HStack>
    );
  }

  return (
    <HStack
      cursor="pointer"
      _hover={{ color: "#2D6CDF" }}
      color="#899EC5"
      role="group"
      onClick={props.onClick}
    >
      {props.icon ? (
        <Center
          w="8"
          h="8"
          rounded="lg"
          bgColor="#F2F2F2"
          _groupHover={{ bgColor: "#CEDFFE" }}
        >
          {props.icon}
        </Center>
      ) : null}
      {props.text ? <Text fontWeight="bold">{props.text}</Text> : null}
    </HStack>
  );
};

export default NavMenuButton;
