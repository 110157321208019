import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Text,
  HStack,
  VStack,
  useBoolean,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

interface INavLocation {
  area: string;
  locations: Array<{
    name: string;
  }>;
}
const NavLocationButton = (props: {
  title: string;
  data: Array<INavLocation>;
}) => {
  const [visible, setVisible] = useBoolean();
  const navigate = useNavigate();

  const handleClickLocation = (name: string) => {
    navigate(`/location/${name}`);
    setVisible.off();
  };

  return (
    <>
      <Popover
        matchWidth
        isOpen={visible}
        onOpen={setVisible.on}
        onClose={setVisible.off}
        closeOnBlur
        isLazy
        gutter={2}
        placement="bottom-start"
      >
        <PopoverTrigger>
          <Button
            size="sm"
            variant="ghost"
            color={visible ? "#002C5E" : "#899EC5"}
          >
            {props.title}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          _focus={{ boxShadow: "lg", outline: "none" }}
          border="none"
          w="full"
          rounded="3xl"
        >
          <PopoverBody px="10" py="8">
            <HStack align="start" spacing="4">
              {props.data.map((item, index) => {
                return (
                  <VStack minW="24" flex="1" key={index}>
                    <Text
                      mb="3"
                      color="#9CBFFF"
                      fontWeight="bold"
                      fontSize="lg"
                      w="full"
                    >
                      {item.area}
                    </Text>
                    <VStack align="start" w="full">
                      {item.locations.map((location, idx) => {
                        return (
                          <Button
                            w="full"
                            size="sm"
                            key={location.name + idx}
                            variant="ghost"
                            onClick={() => handleClickLocation(location.name)}
                            color="#899EC5"
                            textAlign="left"
                            pl="4"
                            justifyContent="flex-start"
                            _hover={{ backgroundColor: "white" }}
                            _active={{ boxShadow: "none" }}
                          >
                            {location.name}
                          </Button>
                        );
                      })}
                    </VStack>
                  </VStack>
                );
              })}
            </HStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default NavLocationButton;
